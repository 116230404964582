export default class Sidebar extends React.PureComponent

  render: ->
    <div className="d-none d-lg-block col-lg-2  col-xl-3">
      <div className="thumbnail">
        <img src={@props.currentUser.avatarUrl} referrerPolicy="no-referrer" />

        { @renderUpdateGravatarPicture() }
      </div>

      <div className="caption">
        <h3>{@props.currentUser.name}</h3>
      </div>
    </div>

  renderUpdateGravatarPicture: ->
    if !@props.currentUser.lastProvider.length
      <a className="update-picture" href="https://gravatar.com/" target="_blank">
        <i className="fas fa-camera-retro fa-lg"></i>
        <span className="update-picture-text">
          Update Picture
        </span>
      </a>
