export default class MemoryResult extends React.PureComponent

  constructor: (props) ->
    super(props)

  render: ->
    classes = "list-group-item memory ignore-react-onclickoutside"
    classes = classes += " applied" if @props.isApplied

    title = if @props.result.msgid then "Source: \"#{@props.result.msgid}\"" else ""

    <li className={classes}
        title={title}
        onClick={@props.insertResult}>
      {@renderScore()}
      <div className="text" dir={if @props.rightToLeft then 'rtl' else undefined}>{@props.result.msgstr}</div>
      {@renderProjectLabel()}
    </li>

  renderScore: ->
    if @props.result.service
      content = <span>{@props.result.serviceName}</span>
    else
      content = <span>{(@props.result.similarity * 100).toFixed(0)}%</span>

    <div className="score">
      {content}
    </div>

  renderProjectLabel: ->
    if @props.result.projectName && @props.result.projectId != @props.projectId
      <div className="project-label">
        { @props.result.projectName }
      </div>

