export default class ProjectConfigCode extends React.PureComponent

  # NEW_STACK - Create new render for new stack
  render: ->
    if @props.project.stack == 'laravel'
      @renderLaravel()
    else if @props.project.stack == 'lingui'
      @renderLingui()
    else if @props.project.stack == 'angular'
      @renderAngular()
    else
      @renderRails()

  renderRails: ->
    joinedTargetLanguageCodes = _.map(@props.project.targetLanguageCodes, (code) ->
      <span className="green" key={code}>'{code}'</span>
    ).reduce((prev, curr) => [prev, ', ', curr])

    <pre>
      <span className="blue">TranslationIO</span>.configure <span className="orange">do</span> |config|<br />
      <span>&nbsp;&nbsp;</span>config.api_key        <span className="orange">=</span> <span className="green">'{@props.project.apiKey}'</span><br />
      <span>&nbsp;&nbsp;</span>config.source_locale  <span className="orange">=</span> <span className="green">'{@props.project.sourceLanguageCode}'</span><br />
      <span>&nbsp;&nbsp;</span>config.target_locales <span className="orange">=</span> [{joinedTargetLanguageCodes}]<br /><br />
      <span>&nbsp;&nbsp;</span><span className="bright"># Uncomment this if you don't want to use gettext</span><br />
      <span>&nbsp;&nbsp;</span><span className="bright"># config.disable_gettext = true</span><br /><br />
      <span>&nbsp;&nbsp;</span><span className="bright"># Uncomment this if you already use gettext or fast_gettext<br />
      <span>&nbsp;&nbsp;</span># config.locales_path = File.join('path', 'to', 'gettext_locale')<br /><br />
      <span>&nbsp;&nbsp;</span># Find other useful usage information here:<br />
      <span>&nbsp;&nbsp;</span># https://github.com/translation/rails#readme</span><br />
      <span className="orange">end</span>
    </pre>

  renderLaravel: ->
    joinedTargetLanguageCodes = _.map(@props.project.targetLanguageCodes, (code) ->
      <span className="blue" key={code}>'{code}'</span>
    ).reduce((prev, curr) => [prev, ', ', curr])

    <pre>
      <span className="orange">&lt;?php</span><br />
      <br />
      <span className="orange">return</span> [<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">'key'</span> => <span className="blue">'{@props.project.apiKey}'</span>,<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">'source_locale'</span> => <span className="blue">'{@props.project.sourceLanguageCode}'</span>,<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">'target_locales'</span> => [{joinedTargetLanguageCodes}],<br/>
      <br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="bright">/* Directories to scan for Gettext strings */</span><br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">'gettext_parse_paths'</span> => [<span className="blue">'app'</span>, <span className="blue">'resources'</span>]<br/>
      ];
    </pre>

  renderLingui: ->
    allLanguageCodes = [@props.project.sourceLanguageCode].concat(@props.project.targetLanguageCodes)

    joinedTargetLanguageCodes = _.map(allLanguageCodes, (code) ->
      <span className="orange" key={code}>"{code}"</span>
    ).reduce((prev, curr) => [prev, ', ', curr])

    <pre>
      {'{'}<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"locales"</span>: [{joinedTargetLanguageCodes}],<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"sourceLocale"</span>: <span className="orange">"{@props.project.sourceLanguageCode}"</span>,<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"catalogs"</span>: [{'{'}<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">"path"</span>: <span className="orange">"src/locales/{'{'}locale{'}'}/messages"</span>,<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">"include"</span>: [<span className="orange">"src"</span>]<br/>
      <span>&nbsp;&nbsp;</span>{'}'}],<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"format"</span>: <span className="orange">"po"</span>,<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"service"</span>: {'{'}<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">"name"</span>: <span className="orange">"TranslationIO"</span>,<br/>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="blue">"apiKey"</span>: <span className="orange">"{@props.project.apiKey}"</span><br/>
      <span>&nbsp;&nbsp;</span>{'}'}<br/>
      {'}'}
    </pre>

  renderAngular: ->
    joinedTargetLanguageCodes = _.map(@props.project.targetLanguageCodes, (code) ->
      <span className="orange" key={code}>"{code}"</span>
    ).reduce((prev, curr) => [prev, ', ', curr])

    <pre>
      {'{'}<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"api_key"</span>: <span className="orange">"{@props.project.apiKey}"</span>,<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"source_locale"</span>: <span className="orange">"{@props.project.sourceLanguageCode}"</span>,<br/>
      <span>&nbsp;&nbsp;</span><span className="blue">"target_locales"</span>: [{joinedTargetLanguageCodes}]<br/>
      {'}'}
    </pre>
