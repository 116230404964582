export default class Path
  @relative: (suffix = '', extension = 'json') ->
    if _.endsWith(document.location.pathname, '/')
      if suffix == ''
        "#{document.location.pathname.slice(0, -1)}.#{extension}"
      else
        "#{document.location.pathname}#{suffix}.#{extension}"
    else
      if suffix == ''
        "#{document.location.pathname}.#{extension}"
      else
        "#{document.location.pathname}/#{suffix}.#{extension}"

  @current: ->
    @relative()

  # return object of url params (the ones like "?a=12&b=true&c[]=345")
  # true/false/integer values are casted (only these for now!)
  @searchParams: ->
    url      = new URL(window.location.href)
    iterator = url.searchParams.entries()
    params   = {}

    while (entry = iterator.next()) && !entry.done
      paramKey   = entry.value[0]
      paramValue = entry.value[1]

      # Cast values (only true/false/integers for now)
      if paramValue == 'true'
        paramValue = true
      else if paramValue == 'false'
        paramValue = false
      else if String(Math.floor(Number(paramValue))) == paramValue
        paramValue = Number(paramValue)

      # Create arrays
      if _.endsWith(paramKey, '[]')
        params[paramKey] ||= []
        params[paramKey].push(paramValue)
      else
        params[paramKey] = paramValue

    humps.camelizeKeys(params)
