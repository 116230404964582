export default class KeyTree
  constructor: (keys) ->
    @keys     = keys
    @keysTree =
      count:    keys.length
      children: {}

  generate: ->
    for key in @keys
      subKeys   = key.split('.')
      treeNodes = @keysTree.children

      for subKey in subKeys
        if !treeNodes.hasOwnProperty(subKey)
          treeNodes[subKey] =
            count:    1
            children: {}
        else
          treeNodes[subKey].count += 1

        treeNodes = treeNodes[subKey].children

    @keysTree

  ####
  # Alternative count population after tree creation
  ####
  # # Add count to each node with the number of sub-nodes (sub-keys)
  # populateCounts: (treeNode) ->
  #   if _.isEmpty(treeNode.children) # leaf
  #     treeNode.count = 1
  #   else # branch
  #     childNodes      = Object.values(treeNode.children)
  #     childNodeCounts = childNodes.map((childNode) => @populateCounts(childNode))
  #     treeNode.count  = _.sum(childNodeCounts)
