import Comment     from './history/Comment'
import PastSegment from './history/PastSegment'
import SourceEdit  from './history/SourceEdit'

export default class History extends React.PureComponent

  PAGINATION_SIZE = 12

  constructor: (props) ->
    super(props)

    @state = {
      loaded:           false
      historyItems:     []
      newComment:       ''
      itemsCountToShow: PAGINATION_SIZE
    }

    @currentHistoryHeight = 0 # out of state because not useful in render

    @historyRef = React.createRef()

    @dReloadHistory = _.debounce(@reloadHistory, 1) # Increase if scrolling quickly between segments becomes an issue (2 requests by step)
                                                    # We don't care for now, the server impact is negligible and we keep fast first-loading
                                                    # Also see Memory.coffee
                                                    # TODO: if it becomes an issue: check if up/down button is pressed to change debounce time

    @updateNewComment = @updateNewComment.bind(this)
    @saveNewComment   = @saveNewComment.bind(this)
    @showAllItems     = @showAllItems.bind(this)
    @scrollToBottom   = @scrollToBottom.bind(this)

  componentDidMount: ->
    @reloadHistory()

  componentDidUpdate: (prevProps, prevState, snapshot) ->
    otherSegmentWasSelected = @props.segment.id != prevProps.segment.id
    segmentWasUpdated       = !_.isEqual(@props.segment, prevProps.segment) # @props.segment is the one from the backend, without the current local changes

    if otherSegmentWasSelected
      @setState(loaded: false, historyItems: []) # don't keep historyItems on screen when switching segment
      @resetItemsCountToShow()
      @clearNewComment()

    if otherSegmentWasSelected || segmentWasUpdated
      @dReloadHistory()

  reloadHistory: ->
    segmentId = @props.segment.id

    http.get(path.relative("segments/#{segmentId}/history"), {}, (data) =>
      if segmentId == @props.segment.id # Check if result os still consistent with state
        @setState({ loaded: true, historyItems: data.history }, =>
          @scrollToBottom()

          @currentHistoryHeight = @historyRef.current.scrollHeight # Useful to keep position in scroll when click on "show more"
        )
    )

  scrollToBottom: ->
    @scrollTo(Number.MAX_SAFE_INTEGER)

  scrollTo: (height) ->
    $('.tools .history').scrollTop(height)

  clearNewComment: ->
    @setState(newComment: '')

  updateNewComment: (e) ->
    if e.target.value != "\n"
      @setState(newComment: e.target.value)
    else
      # Only way to ask autosize to correct the extra row, without any "jump"!
      @setState(newComment: ' ', ->
        @setState(newComment: '')
      )

  saveNewComment: (e) ->
    if e.keyCode == 13 && !e.ctrlKey && !e.metaKey && !e.altKey && !e.shiftKey
      content = @state.newComment.trim()

      if content.length
        http.post(path.relative("segments/#{@props.segment.id}/comments"), { content: content }, =>
          @reloadHistory()
          @clearNewComment()
        )

  timeAgo: (timestamp) ->
    moment(timestamp * 1000).fromNow()

  formattedDate: (timestamp) ->
    moment(timestamp * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a')

  showAllItems: ->
    @setState({ itemsCountToShow: @state.itemsCountToShow + PAGINATION_SIZE }, =>
      @scrollTo(@historyRef.current.scrollHeight - @currentHistoryHeight)

      @currentHistoryHeight = @historyRef.current.scrollHeight
    )
  
  resetItemsCountToShow: ->
    @setState(itemsCountToShow: PAGINATION_SIZE)

  itemsToShow: ->
    _.filter(@state.historyItems, 'visible')

  render: ->
    if @state.historyItems
      <div className="history" ref={@historyRef}>
        <span className="title">
          Discussion & History
        </span>
        <ul className="list-group">
          {@renderShowAll()}
          {@renderHistoryItems() }
          {@renderNewComment()   }
        </ul>
      </div>

  renderShowAll: ->
    if @itemsToShow().length > @state.itemsCountToShow
      <li className="list-group-item show-more" onClick={@showAllItems}>
        <button type="button" className="btn btn-link">
          Show more ({@itemsToShow().length - @state.itemsCountToShow})
        </button>
      </li>

  renderHistoryItems: ->
    paginatedItemsToShow = _.takeRight(@itemsToShow(), @state.itemsCountToShow)

    _.map(paginatedItemsToShow, (item) =>
      historyClasses =  'list-group-item history-item'
      historyClasses += ' comment'      if item.type == 'comment'
      historyClasses += ' past-segment' if item.type == 'past_segment'
      historyClasses += ' source-edit'  if item.type == 'source_edit'

      <li className={historyClasses} key={"#{item.type}-#{item.id}"}>
        {@renderComment(item)}
        {@renderPastSegment(item)}
        {@renderSourceEdit(item)}

        <span className="time" title={@formattedDate(item.timestamp)}>
          {@timeAgo(item.timestamp)}
        </span>
      </li>
    )

  renderComment: (item) ->
    if item.type == 'comment'
      <Comment historyItem={item} />

  renderPastSegment: (item) ->
    if item.type == 'past_segment'
      <PastSegment historyItem={item}
                   segment={@props.segment}
                   targetLanguage={@props.targetLanguage}
                   pluralIndex={@props.pluralIndex}
                   updateActiveMsgstrs={@props.updateActiveMsgstrs}
                   focusTextarea={@props.focusTextarea} />

  renderSourceEdit: (item) ->
    if item.type == 'source_edit'
      <SourceEdit historyItem={item}
                  sourceLanguage={@props.sourceLanguage} />

  renderNewComment: ->
    visibility = if @state.loaded then 1.0 else 0.0 # no "if/else" to keep focus!

    <li className="list-group-item history-item new-comment"
        style={{ opacity: visibility }}>
      <div className="media">
        <div className="float-start">
          <img className="media-object"
               src={@props.currentUser.avatarUrl}
               alt={@props.currentUser.name}
               title={@props.currentUser.name}
               referrerPolicy="no-referrer" />
        </div>
        <div className="media-body">
          <TextareaAutosize className="form-control"
                            placeholder="Write a comment..."
                            name="new-comment"
                            rows={1}
                            onChange={@updateNewComment}
                            onKeyDown={@saveNewComment}
                            onHeightChange={@scrollToBottom}
                            value={@state.newComment} />
        </div>
      </div>
    </li>
