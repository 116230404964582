export default class Date extends React.PureComponent

  timeAgo: (timestamp) ->
    moment(timestamp * 1000).fromNow()

  formattedDate: (timestamp) ->
    moment(timestamp * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a')

  render: ->
    <p className="date" title={@formattedDate(@props.item.timestamp)}>
      { @timeAgo(@props.item.timestamp) }
    </p>
