export default class SourceEdit extends React.PureComponent

  diffSource: (sourceEdit) ->
    historyUtils.diffSource(@props.historyItem)

  render: ->
    <div className="media">
      <div className="float-start">
        <img src={@props.historyItem.imageUrl}
             alt={@props.historyItem.authorName}
             title={@props.historyItem.authorName}
             referrerPolicy="no-referrer" />
      </div>
      <div className="media-body diff">
        <span className="strong">Source: </span>
        <span dangerouslySetInnerHTML={{__html: @diffSource() }}></span>
        {@renderAddedTag()}
      </div>
    </div>

  renderAddedTag: ->
    <span className="small" dangerouslySetInnerHTML={{__html: @props.historyItem.addedTagText}}>
    </span>
