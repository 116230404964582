import onClickOutside from 'react-onclickoutside'
import Colors         from '../../helpers/Colors'

class Tags extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      shown: false
    }

    @showNewTagSelection = @showNewTagSelection.bind(this)
    @addTagFromInput     = @addTagFromInput.bind(this)
    @clickOnInput        = @clickOnInput.bind(this)

  showNewTagSelection: (e) ->
    @setState(shown: !@state.shown, =>
      @focusOnInput() if @state.shown
    )

  handleClickOutside: ->
    if @state.shown
      @setState(shown: false)

  toggleTag: (tag, e) ->
    selected = _.includes(@props.segment.tagIds, tag.id)

    if selected
      @removeTag(tag.id)
    else
      @addTag(tag.name)

    @focusOnInput()

  removeTag: (tagId) ->
    params =
      segmentId: @props.segment.id

    http.delete(path.relative("tags/#{tagId}"), params, (data) =>
      @updateTags(data)
    )

  addTagFromInput: (e) ->
    if e.keyCode == 13 # enter
      @addTag(e.target.value)
      @refs.newTagInput.value = ''

  addTag: (name) ->
    if _.trim(name) != ''
      params =
        name:      name
        segmentId: @props.segment.id

      http.post(path.relative('tags'), params, (data) =>
        @updateTags(data)
      )

  updateTags: (data) ->
    # Update tags from project...
    @props.setTags(data.projectTags, =>
      # ...and update tags from segment (don't wait for websockets)
      @props.updateSegment(@props.segment.id, {
        tagIds: data.segmentTagIds
      })
    )

  focusOnInput: (e) ->
    @refs.newTagInput.focus() if @refs.newTagInput

  clickOnInput: (e) ->
    e.stopPropagation() # no need to focus since it's a click in it, just don't trigger segment selection

  render: ->
    classes = "tags"
    classes += " open" if @state.shown

    <div className={classes}>
      { @renderTags() }
      { @renderNewTag() }
    </div>

  renderTags: ->
    segmentTags = _.map(@props.segment.tagIds, (selectedTagId) =>
      _.find(@props.tags, { id: selectedTagId })
    )

    segmentTags = _.omitBy(segmentTags, _.isNil)
    segmentTags = _.sortBy(segmentTags, 'name')

    _.map(segmentTags, (tag) =>
      <span key={tag.id}
            className="label"
            title={tag.name}
            style={{ backgroundColor: tag.color }}
            onClick={ @showNewTagSelection }>
        { tag.name }
      </span>
    )

  renderNewTag: ->
    <div className="new-tag">
      <span className="label"
            title="Manage tags for this segment"
            onClick={ @showNewTagSelection }>
        +
      </span>

      { @renderTagSelection() }
    </div>

  renderTagSelection: ->
    if @state.shown
      <ul className="tag-selection">
        { @renderTagSelectionItems() }
        <li>
          <input className="form-control"
                 type="text"
                 placeholder="Create new tag"
                 ref="newTagInput"
                 onKeyDown={@addTagFromInput}
                 onClick={@clickOnInput} />
        </li>
      </ul>

  renderTagSelectionItems: ->
    _.map(@props.tags, (tag) =>
      selected     = _.includes(@props.segment.tagIds, tag.id)

      darkerColor  = if selected then Colors.shade(-0.30, tag.color) else undefined
      lighterColor = if selected then Colors.shade( 0.85, tag.color) else undefined

      classCheck   =  'fas fa-check'
      classCheck   += ' not-visible' if !selected

      classRemove  =  'fas fa-xmark'
      classRemove  += ' not-visible' if !selected

      <li key={tag.id}
          className="tag-item"
          style={{ backgroundColor: lighterColor }}
          onClick={@toggleTag.bind(this, tag)}>
        <i className={classCheck} style={{ color: darkerColor }}></i>
        <span className="color"
              style={{ backgroundColor: tag.color }}>
        </span>
        <span className="name"
              style={{ color: darkerColor }}
              title={tag.name}>
          { tag.name }
        </span>
        <i className={classRemove} style={{ color: darkerColor }}></i>
        <div style={{ clear: 'both' }}></div>
      </li>
    )

export default onClickOutside(Tags)
