#http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors

export default class Colors

  # public class method
  @shade: (p, c0, c1) ->
    n = if p < 0 then p*-1 else p
    u = Math.round
    w = parseInt

    if c0.length > 7
      f = c0.split(",")
      t = (if c1 then c1 else (if p < 0 then "rgb(0,0,0)" else "rgb(255,255,255)")).split(",")
      R = w(f[0].slice(4))
      G = w(f[1])
      B = w(f[2])

      "rgb("+(u((w(t[0].slice(4))-R)*n)+R)+","+(u((w(t[1])-G)*n)+G)+","+(u((w(t[2])-B)*n)+B)+")"
    else
      f  = w(c0.slice(1),16)
      t  = w((if c1 then c1 else (if p < 0 then "#000000" else "#FFFFFF")).slice(1),16)
      R1 = f>>16
      G1 = f>>8&0x00FF
      B1 = f&0x0000FF
      "#"+(0x1000000+(u(((t>>16)-R1)*n)+R1)*0x10000+(u(((t>>8&0x00FF)-G1)*n)+G1)*0x100+(u(((t&0x0000FF)-B1)*n)+B1)).toString(16).slice(1)
