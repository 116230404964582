export default class SelectionStats extends React.PureComponent

  constructor: (props) ->
    super(props)

  render: ->
    <div className="stats">
      <h3>
        <em>{@props.selectedSegmentIds.length}</em> selected segments
      </h3>
    </div>

