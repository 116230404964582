import PanelHelpers from './helpers/PanelHelpers'

export default class IcuPlural extends React.PureComponent

  constructor: (props) ->
    super(props)

    @sourceRef = React.createRef()
    @targetRef = React.createRef()

    @copySourceToTarget = @copySourceToTarget.bind(this)
    @insertNextTag      = @insertNextTag.bind(this)
    @updateTarget       = @updateTarget.bind(this)

  componentDidMount: ->
    @bindShortcuts()
    @bindTags()
    @bindTabFocusBlurTarget()
    @refreshTabIndicator()

  componentDidUpdate: (prevProps) ->
    @refreshTabIndicator()

  componentWillUnmount: ->
    @unbindShortcuts()

  # Generic Methods from helper!

  bindShortcuts: ->
    new PanelHelpers(@).bindShortcuts()

  unbindShortcuts: ->
    new PanelHelpers(@).unbindShortcuts()

  bindTags: ->
    new PanelHelpers(@).bindTags()

  bindTabFocusBlurTarget: ->
    new PanelHelpers(@).bindTabFocusBlurTarget()

  refreshTabIndicator: ->
    new PanelHelpers(@).refreshTabIndicator()

  insertTab: ->
    new PanelHelpers(@).insertTab()

  removeTab: ->
    new PanelHelpers(@).removeTab()

  insertNextTag: ->
    new PanelHelpers(@).insertNextTag()

  insertTargetText: (text) ->
    new PanelHelpers(@).insertTargetText(text)

  copySourceToTarget: ->
    new PanelHelpers(@).copySourceToTarget()

  updateTarget: (e) ->
    new PanelHelpers(@).updateTarget(e)

  # Custom methods for Singular

  hasEmptyTranslation: (activeMsgstr) ->
    # Look if there is any current translation (active or in node)
    hasAnyTranslation = _.some(@props.icuSegment.targetPluralCases(), (targetPluralCase, index) =>
      if index == @props.activePluralIndex
        return activeMsgstr.length
      else
        @props.icuSegment.targetPlural(targetPluralCase).length
    )

    return !hasAnyTranslation

  updateActiveMsgstr: (newMsgstr, callback = undefined) ->
    if @hasEmptyTranslation(newMsgstr)
      newIcuMsgstr = "" # nothing is translated, we don't generate the ICU
    else
      newIcuMsgstr = @props.icuSegment.injectTextAndDumpTarget(newMsgstr, @props.activePluralIndex)

    @props.updateActiveMsgstrs({ msgstr: newIcuMsgstr }, callback)

  source: ->
    @props.icuSegment.sourceByPluralIndex(@props.activePluralIndex)

  target: ->
    @props.icuSegment.targetByPluralIndex(@props.activePluralIndex)

  render: ->
    <div className="plurals-wrapper">
      <div className="plurals">
        {@renderTabs()}
      </div>
      <div className="plurals-translations">
        {@renderTranslationZone()}
      </div>
    </div>

  renderTabs: ->
    _.map([0..@props.icuSegment.nPlurals()-1], (i) =>
      @renderTab(i)
    )

  renderTab: (i) ->
    label            = @props.icuSegment.targetPluralCases()[i] # one, many, other, etc.
    regularCaseIndex = _.findIndex(@props.targetLanguage.pluralCases, (pluralCase) => pluralCase == label)
    title            = @props.targetLanguage.pluralCaseSampleSentences[regularCaseIndex] || '' # Show sample sentence if exist, or else nothing (it will not display the tooltip if empty string)

    new PanelHelpers(@).renderPluralTab(i, label, title)

  renderTranslationZone: ->
    new PanelHelpers(@).renderPluralZone()
