export default class Comment extends React.PureComponent

  render: ->
    <div className="media">
      <div className="float-start">
        <img src={@props.historyItem.imageUrl}
             alt={@props.historyItem.authorName}
             title={@props.historyItem.authorName}
             referrerPolicy="no-referrer" />
      </div>

      { @renderComment() if !@props.historyItem.action }
      { @renderAction()  if  @props.historyItem.action }
    </div>

  renderComment: ->
    <div className="media-body">
      <i className="fas fa-quote-left"></i>
        &nbsp;
        <span dangerouslySetInnerHTML={{ __html: @props.historyItem.content }}></span>
        &nbsp;
      <i className="fas fa-quote-right"></i>
    </div>

  renderAction: ->
    <div className="media-body"
         dangerouslySetInnerHTML={{ __html: @props.historyItem.content }}>
    </div>
