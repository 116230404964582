import ReactTooltip from 'react-tooltip'

export default class PanelHelpers

  constructor: (component) ->
    @component = component
    @props     = component.props
    @sourceRef = component.sourceRef
    @targetRef = component.targetRef

  bindShortcuts: ->
    ctrlModifier = if _.includes(navigator.platform, 'Mac') then 'meta' else 'ctrl'

    # Copy source to target (in document or input/textarea)
    $(document).on('keydown', 'body, textarea.target, input.filter-search', "#{ctrlModifier}+i", @component.copySourceToTarget)

    # Insert next tag (only in textarea)
    $('textarea.target').on('keydown', null, 'tab', @component.insertNextTag)

  unbindShortcuts: ->
    # Copy source to target (in document or textarea)
    $(document).off('keydown', @component.copySourceToTarget)

    # Insert next tag (only in textarea)
    $('textarea.target').off('keydown', @component.insertNextTag)

  bindTags: ->
    # Click tag to insert
    self = @component

    $(@sourceRef.current).on("click", "em:not(.translated)", ->
      content = $(this).text()
      self.insertTargetText(content)
    )

    # Add titles on hover
    $(@sourceRef.current).on("mouseenter", "em:not(.translated)", ->
      $(this).attr('title', 'Click to insert into translation')
    ).on("mouseleave", "em", ->
      $(this).removeAttr('title')
    )

  bindTabFocusBlurTarget: ->
    $(@targetRef.current).on("focus", @component.insertTab.bind(this))
    $(@targetRef.current).on("blur",  @component.removeTab.bind(this))

  refreshTabIndicator: ->
    @component.removeTab()

    if $(@targetRef.current).is(':focus')
      @component.insertTab()

  insertTab: ->
    em = $(@sourceRef.current).find('em:not(.translated)')[0]
    $('<i class="tab fas fa-arrow-up" title="Use TAB key to insert this tag"/>').insertBefore(em)

  removeTab: ->
    $(@sourceRef.current).find('.tab').remove()

  nextMissingTag: ->
    sourceTags = @sourceTags()
    targetTags = @targetTags()

    _.each(targetTags, (targetTag) =>
      index =  _.findIndex(sourceTags, (tag) => tag == targetTag)

      if index >= 0
        _.pullAt(sourceTags, index)
    )

    # Take next not-used tag and insert it at the right position
    nextTag = if sourceTags.length then sourceTags[0] else undefined

  insertNextTag: ->
    nextTag = @nextMissingTag()
    @component.insertTargetText(nextTag) if nextTag

    return false # don't really trigger tab to change input

  # Insert text at the current position (update state then focus + position cursor)
  insertTargetText: (text) ->
    target    = @component.target()
    textarea  = $(@targetRef.current)
    selection = textarea.getSelection()
    newMsgstr = target.substring(0, selection.start) + text + target.substring(selection.end) # replace selection (if any) with text

    if textarea.val() != newMsgstr
      @component.updateActiveMsgstr(newMsgstr, =>
        textarea.trigger('focus')
        textarea.setSelection(target.substring(0, selection.start).length + text.length)
      )

  copySourceToTarget: ->
    @component.updateActiveMsgstr(@component.source())
    return false

  sourceTags: ->
    highlight.extractUnescapedTags(_.escape(@component.source()), @tagTypes())

  targetTags: ->
    highlight.extractUnescapedTags(_.escape(@component.target()), @tagTypes())

  updateTarget: (e) ->
    @component.updateActiveMsgstr(e.target.value)

  formattedSource: ->
    highlight.highlight(@component.source(), "", @tagTypes(), @targetTags())

  tagTypes: ->
    if @props.ignoreStackHighlights
      ['html']
    else
      [@props.stack, 'html']

  renderPluralTab: (i, label, title) ->
    classNames  = 'plural'
    classNames += ' singular'      if i == 0
    classNames += " plural_#{i-1}" if i != 0
    classNames += ' active'        if @props.activePluralIndex == i

    title = "ex: #{title}" if title.length

    delay = if @props.environment == 'test' then 0 else 400

    <div key={i}
         className={classNames}
         onClick={@props.updateActivePluralIndex.bind(this, i)}
         data-for="plural-tooltip"
         data-tip={title}
         data-effect="solid"
         data-type="light"
         data-border="true"
         data-border-color="#cccccc"
         data-place="right"
         data-delay-show={delay}>
      {label}
      <ReactTooltip id="plural-tooltip" />
    </div>

  renderPluralZone: ->
    <div>
      <div ref={@sourceRef}
           className="form-control source highlighted"
           dangerouslySetInnerHTML={{__html: @formattedSource()}}>
      </div>

      <textarea ref={@targetRef}
                className="form-control target"
                value={@component.target()}
                onChange={@component.updateTarget}
                placeholder="Translated Text"
                lang={@props.langAttribute}
                dir={@props.direction} />
    </div>
