import onClickOutside from 'react-onclickoutside'

import Singular  from './panel/Singular'
import Plural    from './panel/Plural'
import IcuPlural from './panel/IcuPlural'

class Panel extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      direction: if @props.targetLanguage.rightToLeft then 'rtl' else 'ltr'
    }

    @toggleDirection      = @toggleDirection.bind(this)
    @simulateClickOutside = @handleClickOutside.bind(this) # to give the same click-outside behaviour to "edit source text" button

  handleClickOutside: ->
    @props.saveSegmentMsgstrs(@props.segment.id)

  toggleDirection: ->
    @setState({ direction: if @state.direction == 'ltr' then 'rtl' else 'ltr' }, @props.focusTextarea)

  langAttribute: ->
    code = @props.targetLanguage.code

    # https://www.w3schools.com/tags/ref_language_codes.asp
    if code == 'zh-Hans' || code == 'zh-Hant'
      return code
    else
      return @props.targetLanguage.code.split('-')[0]

  render: ->
    classNames =  'translation-panel'
    classNames += ' d-none' if !@props.segment

    <div className={classNames}>
      {@renderSingular()}
      {@renderPlural()}
      {@renderIcu()}
      {@renderShortcutRTL()}
      {@renderShortcutUsage()}
      {@renderShortcutHotkeys()}
      {@renderShortcutExpandFullScreen()}
      {@renderShortcutCompressFullScreen()}
      {@renderNextAndPrevious()}
    </div>

  renderSingular: ->
    if @props.segment.pluralType == 'singular'
      <Singular segmentId={@props.segment.id}
                msgid={@props.segment.msgid}
                msgstr={@props.segment.msgstr}
                kind={@props.segment.kind}
                currentUserIsManager={@props.currentUserIsManager}
                direction={@state.direction}
                stack={@props.stack}
                ignoreStackHighlights={ICU.isIcuString(@props.segment.msgid)}
                langAttribute={@langAttribute()}
                updateActiveMsgstrs={@props.updateActiveMsgstrs}
                simulateClickOutside={@simulateClickOutside} />

  renderPlural: ->
    if @props.segment.pluralType == 'gettext_plural'
      <Plural environment={@props.environment}
              segmentId={@props.segment.id}
              activePluralIndex={@props.activePluralIndex}
              msgid={@props.segment.msgid}
              msgidPlural={@props.segment.msgidPlural}
              msgstrPlural1={@props.segment.msgstrPlural1}
              msgstrPlural2={@props.segment.msgstrPlural2}
              msgstrPlural3={@props.segment.msgstrPlural3}
              msgstrPlural4={@props.segment.msgstrPlural4}
              msgstrPlural5={@props.segment.msgstrPlural5}
              msgstrPlural6={@props.segment.msgstrPlural6}
              kind={@props.segment.kind}
              targetLanguage={@props.targetLanguage}
              direction={@state.direction}
              stack={@props.stack}
              langAttribute={@langAttribute()}
              updateActiveMsgstrs={@props.updateActiveMsgstrs}
              updateActivePluralIndex={@props.updateActivePluralIndex} />

  renderIcu: ->
    if @props.segment.pluralType == 'icu_plural'
      <IcuPlural environment={@props.environment}
                 segmentId={@props.segment.id}
                 activePluralIndex={@props.activePluralIndex}
                 msgid={@props.segment.msgid}
                 msgstr={@props.segment.msgstr}
                 icuSegment={new ICU(@props.segment.msgid, @props.segment.msgstr, @props.targetLanguage.pluralCases)}
                 kind={@props.segment.kind}
                 sourceLanguage={@props.sourceLanguage}
                 targetLanguage={@props.targetLanguage}
                 direction={@state.direction}
                 stack={@props.stack}
                 langAttribute={@langAttribute()}
                 updateActiveMsgstrs={@props.updateActiveMsgstrs}
                 updateActivePluralIndex={@props.updateActivePluralIndex} />

  renderShortcutRTL: ->
    if @props.segment && @props.targetLanguage.rightToLeft
      if @state.direction == 'ltr'
        <span className="ltr-icon"
              title="Switch to Right-To-Left direction"
              onClick={@toggleDirection}>
          LTR
        </span>
      else if @state.direction == 'rtl'
        <span className="rtl-icon"
              title="Switch to Left-To-Right direction"
              onClick={@toggleDirection}>
          RTL
        </span>

  renderShortcutUsage: ->
    if @props.segment && @props.currentUserIsOwner
      <span className="shortcut-icon shortcut-icon-question"
            data-bs-toggle="modal"
            data-bs-target="#modal-usage"
            title="How does it work?">
        <i className="far fa-circle-question"></i>
      </span>

  renderShortcutHotkeys: ->
    if @props.segment
      <span className="shortcut-icon shortcut-icon-keyboard"
            data-bs-toggle="modal"
            data-bs-target="#modal-hotkeys"
            title="List of keyboard shortcuts">
         <i className="far fa-keyboard"></i>
      </span>

  renderShortcutExpandFullScreen: ->
    if @props.segment && !@props.fullScreen && @props.segment.msgid && @props.segment.msgid.length > 300
      <span className="shortcut-icon shortcut-icon-expand"
            title="Enter fullscreen mode"
            onClick={@props.toggleFullScreen}>
         <i className="fas fa-up-right-and-down-left-from-center"></i>
      </span>

  renderShortcutCompressFullScreen: ->
    if @props.segment && @props.fullScreen
      <span className="shortcut-icon shortcut-icon-compress"
            title="Exit fullscreen mode"
            onClick={@props.toggleFullScreen}>
         <i className="fas fa-down-left-and-up-right-to-center"></i>
      </span>

  renderNextAndPrevious: ->
    if @props.fullScreen
      <div className="arrows">
        <i className="fas fa-caret-up" onClick={@props.goToPrevSegment}></i>
        { @props.segmentIndex + 1 }
        <hr />
        {@props.segmentsCount}
        <i className="fas fa-caret-down" onClick={@props.goToNextSegment}></i>
      </div>

export default onClickOutside(Panel)
