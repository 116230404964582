export default class Dropdown extends React.PureComponent

  constructor: (props) ->
    super(props)

    @openStatisticsModal = @openStatisticsModal.bind(this)
    @preventDefault      = @preventDefault.bind(this)

  openStatisticsModal: (e) ->
      e.stopPropagation()
      $('#modal-statistics').data('statistics-url', @props.project.statisticsPath)
      $('#modal-statistics').modal('show')

  preventDefault: (e) ->
    e.preventDefault()

  render: ->
    <div className="dropdown float-end">
      <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-label="Settings" aria-expanded="false">
        <i className="fas fa-ellipsis" aria-hidden="true"></i>
      </button>

      <ul className="dropdown-menu dropdown-menu-left project-dropdown">
        {@renderHistoryItem()}
        {@renderStatisticsItem()}
        {@renderUsageItem()}
        {@renderSettingsItem()}
        {@renderTruncateItem()}
      </ul>
    </div>

  renderHistoryItem: ->
    <li>
      <a href="#"
         onClick={@preventDefault}
         className="dropdown-item open-modal-history"
         data-url={@props.project.historyPath}>
        History
      </a>
    </li>

  renderStatisticsItem: ->
    <li>
      <a href="#" onClick={@openStatisticsModal} className="dropdown-item">
        Statistics
      </a>
    </li>

  renderUsageItem: ->
    if @props.project.isOwner
      <li>
        <a href={@props.project.usagePath} className="dropdown-item">
          Usage
        </a>
      </li>

  renderSettingsItem: ->
    if @props.project.isOwner
      <li>
         <a href={@props.project.settingsPath} className="dropdown-item">
          Settings
        </a>
      </li>

  renderTruncateItem: ->
    if @props.project.isAdmin
      confirmMsg = "Are you sure to truncate #{@props.project.name}?"

      <>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <a href={@props.project.truncatePath}
             className="dropdown-item danger"
             data-method="put"
             data-confirm={confirmMsg}>
            Truncate
          </a>
        </li>
      </>
