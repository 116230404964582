export default class TeamMembers extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      projectUserLinks: []
    }

  componentDidMount: ->
    window.TeamMembersComponent = this
    @reloadFromBackend()

  reloadFromBackend: ->
    http.get(@props.projectUserLinksPath + '.json', {}, (projectUserLinks) =>
      @setState(projectUserLinks: projectUserLinks)
    )

  render: ->
    <a href={@props.projectUserLinksPath}
       className="btn-invite-modal nav-link"
       data-bs-toggle="modal"
       data-bs-target="#modal-invite"
       title="Manage your team">
      {@renderProjectUserLinks()}
      {@renderDots()}

      <span>
        <i className="fas fa-plus"></i>
      </span>
    </a>

  renderProjectUserLinks: ->
    if @state.projectUserLinks.length > @props.spots
      n = @props.spots - 1
    else
      n = @props.spots

    _.map(_.take(@state.projectUserLinks, n), (projectUserLink) =>
      classes = if projectUserLink.myself then 'active' else ''

      <img key={projectUserLink.id}
           width={27}
           height={27}
           className={classes}
           src={projectUserLink.avatarUrl}
           alt={projectUserLink.name}
           referrerPolicy="no-referrer" />
    )

  renderDots: ->
    if @state.projectUserLinks.length > @props.spots
      <span className="dots">&hellip;</span>
