export default class NavbarDigestButton extends React.PureComponent

  DROPDOWN_WIDTH = 268

  constructor: (props) ->
    super(props)

    @state =
      subscribed: @props.subscribed
      mounted:    false

    @triggerWatch = @triggerWatch.bind(this)
    @triggerMute  = @triggerMute.bind(this)

  componentDidMount: ->
    @setState(mounted: true);

  triggerWatch: (e) ->
    e.preventDefault()
    @backendUpdateSubscribed(true) if !@state.subscribed

  triggerMute: (e) ->
    e.preventDefault()
    @backendUpdateSubscribed(false) if @state.subscribed

  backendUpdateSubscribed: (newValue) ->
    @setState(subscribed: newValue, =>
      http.put(@props.updateProjectUserLinkPath + '.json', {
        subscribed: newValue
      })
    )

  enoughRightSpace: ->
    browserWidth    = window.outerWidth
    buttonPositionX = $(".navbar-digest-button > .dropdown").position().left

    browserWidth - buttonPositionX > DROPDOWN_WIDTH

  render: ->
    if @state.subscribed
      subscribedClass = "watched"
      subscribedText  = "Watched"
      iconClasses     = "far fa-eye"
    else
      subscribedClass = "muted"
      subscribedText  = "Muted"
      iconClasses     = "far fa-bell-slash"

    <div className={"button-container dropdown #{subscribedClass}"}>
      <a className="nav-link subscription-button" href="#" type="button" title="Enable/Disable notifications" data-bs-toggle="dropdown">
        <i className={iconClasses}></i>
        <span>{" #{subscribedText}"}</span>
      </a>

      {@renderDropdownMenu()}
    </div>

  renderDropdownMenu: ->
    if @state.mounted # avoid `window` issue with server-side rendering for this part
      classes = "dropdown-menu subscription-menu"
      classes += " dropdown-menu-end" if !@enoughRightSpace()

      <ul className={classes} style={{ width: DROPDOWN_WIDTH }}>
        <li className="watch" onClick={@triggerWatch}>
          <button>
            <div className="left-icon">
              <i className="fas fa-check"></i>
            </div>

            <div className="right-description">
              <strong>Watch</strong>
              <p>
                Receive a summary of recent changes by email, when this project evolves in your languages.
              </p>
            </div>
          </button>
        </li>

        <li className="mute" onClick={@triggerMute}>
          <button>
            <div className="left-icon">
              <i className="fas fa-check"></i>
            </div>

            <div className="right-description">
              <strong>Mute</strong>
              <p>
                Never be notified.
              </p>
            </div>
          </button>
        </li>
      </ul>
