import PanelHelpers from './helpers/PanelHelpers'

export default class Singular extends React.PureComponent

  constructor: (props) ->
    super(props)

    @sourceRef = React.createRef()
    @targetRef = React.createRef()

    @copySourceToTarget = @copySourceToTarget.bind(this)
    @insertNextTag      = @insertNextTag.bind(this)
    @updateTarget       = @updateTarget.bind(this)

  componentDidMount: ->
    @bindShortcuts()
    @bindTags()
    @bindTabFocusBlurTarget()
    @refreshTabIndicator()

  componentDidUpdate: (prevProps) ->
    @refreshTabIndicator()

  componentWillUnmount: ->
    @unbindShortcuts()

  # Generic Methods from helper!

  bindShortcuts: ->
    new PanelHelpers(@).bindShortcuts()

  unbindShortcuts: ->
    new PanelHelpers(@).unbindShortcuts()

  bindTags: ->
    new PanelHelpers(@).bindTags()

  bindTabFocusBlurTarget: ->
    new PanelHelpers(@).bindTabFocusBlurTarget()

  refreshTabIndicator: ->
    new PanelHelpers(@).refreshTabIndicator()

  insertTab: ->
    new PanelHelpers(@).insertTab()

  removeTab: ->
    new PanelHelpers(@).removeTab()

  insertNextTag: ->
    new PanelHelpers(@).insertNextTag()

  insertTargetText: (text) ->
    new PanelHelpers(@).insertTargetText(text)

  copySourceToTarget: ->
    new PanelHelpers(@).copySourceToTarget()

  updateTarget: (e) ->
    new PanelHelpers(@).updateTarget(e)

  formattedSource: ->
    new PanelHelpers(@).formattedSource()

  # Custom methods for Singular

  updateActiveMsgstr: (newMsgstr, callback = undefined) ->
    @props.updateActiveMsgstrs({
      msgstr: newMsgstr
    }, callback)

  source: ->
    @props.msgid

  target: ->
    @props.msgstr

  render: ->
    <div>
      <div ref={@sourceRef}
           className="form-control source highlighted"
           dangerouslySetInnerHTML={{__html: @formattedSource()}}>
      </div>

      {@renderShortcutSourceEdit()}

      <textarea ref={@targetRef}
                className="form-control target"
                value={@props.msgstr}
                onChange={@updateTarget}
                placeholder="Translated Text"
                lang={@props.langAttribute}
                dir={@props.direction}>
      </textarea>
    </div>

  renderShortcutSourceEdit: ->
    if (@props.kind == 'yaml' || @props.kind == 'content') && @props.currentUserIsManager
      <span className="shortcut-icon-source-edit shortcut-icon"
            data-bs-toggle="modal"
            data-bs-target="#modal-source-edit"
            title="Edit source text"
            onClick={@props.simulateClickOutside}>
        <i className="far fa-pen-to-square"></i>
      </span>
    else
      ''
