import { parse } from '@formatjs/icu-messageformat-parser'

export default class ICU
  constructor: (source, target, targetLanguagePluralCases) ->
    @targetLanguagePluralCases = targetLanguagePluralCases

    @sourceNode = ICU.parse(source)
    @targetNode = if target.length then ICU.parse(target) else undefined

  sourcePluralCases: ->
    allPluralCases = ['zero', 'one', 'two', 'few', 'many', 'other']
    nodeCases      = if @sourceNode then Object.keys(@sourceNode.options) else []
    cases          = _.difference(nodeCases, allPluralCases)              # only keep special cases for now
    cases          = _.sortBy(cases, (c) => parseInt(c.replace('=', ''))) # keep special cases sorted

    # Add the case one by one to preserve order
    for pluralCase in allPluralCases
      if _.includes(nodeCases, pluralCase)
        cases.push(pluralCase)

    cases

  targetPluralCases: ->
    specialCases  = _.difference(@sourcePluralCases(), ['zero', 'one', 'two', 'few', 'many', 'other'])
    specialCases.concat(@targetLanguagePluralCases)

  nPlurals: ->
    @targetPluralCases().length

  sourceVariable: ->
    @sourceNode.value

  targetVariable: ->
    @targetNode.value

  sourceOffset: ->
    @sourceNode.offset

  targetOffset: ->
    @targetNode.offset

  hasSourceOffset: ->
    if @sourceNode.offset then true else false

  hasTargetOffset: ->
    if @targetNode.offset then true else false

  hasSourcePlural: (pluralCase) ->
    @sourceNode && @sourceNode.options[pluralCase]

  hasTargetPlural: (pluralCase) ->
    @targetNode && @targetNode.options[pluralCase]

  sourceByPluralIndex: (pluralIndex) ->
    targetPluralCases      = @targetPluralCases()
    activeTargetPluralCase = targetPluralCases[pluralIndex]

    @referenceSourcePlural(activeTargetPluralCase)

  targetByPluralIndex: (pluralIndex) ->
    targetPluralCases      = @targetPluralCases()
    activeTargetPluralCase = targetPluralCases[pluralIndex]

    @targetPlural(activeTargetPluralCase)

  # if target is "few" case and "few" doesn't exist in source, we need to manage fallback
  referenceSourcePluralCase: (targetPluralCase) ->
    sourcePluralCases = @sourcePluralCases()

    if _.includes(sourcePluralCases, targetPluralCase)
      targetPluralCase
    else if targetPluralCase == 'zero' && _.includes(sourcePluralCases, '=0') # \
      '=0'                                                                    #  => Because Angular docs uses "=0" and "=1" instead of "zero" and "one"
    else if targetPluralCase == 'one' && _.includes(sourcePluralCases, '=1')  # /
      '=1'                                                                    #/
    else if _.includes(sourcePluralCases, 'other') # "other" should(?) be present for all languages
      'other'
    else
      sourcePluralCases[0] # if "other" is not present, take first one!

  # if target is "few" case and "few" doesn't exist in source, we need to manage fallback
  referenceSourcePlural: (targetPluralCase) ->
    @sourcePlural(@referenceSourcePluralCase(targetPluralCase))

  sourcePlural: (pluralCase) ->
    if @hasSourcePlural(pluralCase)
      ICU.pluralNodeToText(@sourceNode.options[pluralCase])
    else
      ""

  targetPlural: (pluralCase) ->
    if @hasTargetPlural(pluralCase)
      ICU.pluralNodeToText(@targetNode.options[pluralCase])
    else
      ""

  completeSource: ->
    parts = _.map(@sourcePluralCases(), (pluralCase) => @sourcePlural(pluralCase))

    if _.trim(_.join(parts, '')) == ''
      return ''
    else
      return _.join(parts, ' ≺ ')

  completeTarget: ->
    parts = _.map(@targetPluralCases(), (pluralCase) => @targetPlural(pluralCase))

    if _.trim(_.join(parts, '')) == ''
      return ''
    else
      return _.join(parts, ' ≺ ')

  # Minimal number of ≺ (without empty values)
  completeSourceForDiff: ->
    parts = _.map(@sourcePluralCases(), (pluralCase) => @sourcePlural(pluralCase))
    parts = _.reject(parts, (part) => part.length == 0 )

    if _.trim(_.join(parts, '')) == ''
      return ''
    else
      return _.join(parts, ' ≺ ')

  # Minimal number of ≺ (without empty values)
  completeTargetForDiff: ->
    parts = _.map(@targetPluralCases(), (pluralCase) => @targetPlural(pluralCase))
    parts = _.reject(parts, (part) => part.length == 0 )

    if _.trim(_.join(parts, '')) == ''
      return ''
    else
      return _.join(parts, ' ≺ ')

  # Escape text in the scope of this ICU segment
  # (Scope is important because of existing variables in source that should *not* be escaped)
  # cf. https://formatjs.io/docs/core-concepts/icu-syntax/#quoting--escaping
  #     https://sites.google.com/site/icuprojectuserguide/formatparse/messages#h.k7ocx11pfqt0
  escape: (text) ->
    variables = []

    # {0} or {name}
    regexp = /\{\w+\}/g

    # Find variables from source
    variables.push("{#{@sourceVariable()}}")
    variables = variables.concat(@completeSource().match(regexp) || [])
    variables = _.uniq(_.compact(variables))

    # Create escaped variables: {hello} becomes [[[[[hello]]]]]
    escapedVariables = _.map(variables, (variable) -> variable.replace(/\{/g, "[[[[[").replace(/\}/g, "]]]]]") )

    # Escape variables from text
    variables.forEach((variable, i) ->
      text = text.replace(RegExp(_.escapeRegExp(variable), "g"), escapedVariables[i]) # replaceAll is too modern for old browsers! (^^)
    )

    # Escape special chars from text
    text = text.replace(/'/g, "''").replace(/\{/g, "'{'").replace(/\}/g, "'}'")

    # Unescape variables from text
    escapedVariables.forEach((escapedVariable, i) ->
      text = text.replace(RegExp(_.escapeRegExp(escapedVariable), "g"), variables[i]) # replaceAll is too modern for old browsers! (^^)
    )

    text

  # Dump target node to ICU string
  dumpTarget: ->
    target = "{ #{@sourceVariable()}, plural,"
    target = "#{target} offset:#{@sourceOffset()}" if @hasSourceOffset()

    _.each(@targetPluralCases(), (pluralCase, index) =>
      if @hasTargetPlural(pluralCase)
        targetPlural        = @targetPlural(pluralCase)
        excapedTargetPlural = @escape(targetPlural) # in the scope of icu_segment to detect existing source variables
        target = "#{target} #{pluralCase} {#{excapedTargetPlural}}"
      else if pluralCase == 'other' # other is mandatory
        target = "#{target} other {}"
    )

    target = "#{target} }"
    target

  # Inject new text for specific pluralIndex and dump target node to ICU string
  injectTextAndDumpTarget: (text, pluralIndex) ->
    target = "{ #{@sourceVariable()}, plural,"
    target = "#{target} offset:#{@sourceOffset()}" if @hasSourceOffset()

    _.each(@targetPluralCases(), (pluralCase, index) =>
      if index == pluralIndex # What's currently in translation panel
        if text.length
          escapedText = @escape(text) # in the scope of icu_segment to detect existing source variables
          target = "#{target} #{pluralCase} {#{escapedText}}"
        else if pluralCase == 'other' # other is mandatory
          target = "#{target} other {}"
      else # what's on the existing node (other plural forms)
        if @hasTargetPlural(pluralCase)
          targetPlural        = @targetPlural(pluralCase)
          excapedTargetPlural = @escape(targetPlural) # in the scope of icu_segment to detect existing source variables
          target = "#{target} #{pluralCase} {#{excapedTargetPlural}}"
        else if pluralCase == 'other' # other is mandatory
          target = "#{target} other {}"
    )

    target = "#{target} }"
    target

  @pluralNodeToText: (pluralNode) ->
    text = ""

    for item in pluralNode.value
      if item.type == 0
        text = "#{text}#{item.value}"
      else if item.type == 1
        text = "#{text}{#{item.value}}"
      else if item.type == 7
        text = "#{text}#"

    text

  # ICU string of type "plural", "select" or "selectordinal" (for now)
  @isIcuString: (text) ->
    if /{.*,\s*(plural|select|selectordinal)\s*,.*}/s.test(text) # String should include ICU plural, select or selectordinal ('s' flag = consider it like single line)
      try # Will catch exception if not correctly parsed (or if missing keys on parsed result)
        node = ICU.parse(text)
        return true
      catch
        return false
    else
      return false

  @isIcuPluralString: (text) ->
    if /^\s*{[\s\S]*,\s*plural\s*,[\s\S]*}\s*$/.test(text) # Should look like plural ICU ('s' flag = consider it like single line)
      try # Will catch exception if not correctly parsed (or if missing keys on parsed result)
        node  = ICU.parse(text)
        cases = Object.keys(node.options || {})

        return node.pluralType == 'cardinal' && _.includes(cases, 'other')
      catch
        return false
    else
      return false

  @parse: (text) ->
    # If an exception is triggered here, it's usually because Ruby accepts an invalid ICU.
    # Fix it in icu_service.rb or in 'message_format' gem (DON'T RESCUE HERE!)
    parse(text,
      ignoreTag:            true  # HTML tags are not parsed as tokens
      requiresOtherClause:  false # an ICU without other is kinda stupid, but we tolerate it for the source
      shouldParseSkeletons: false # Whether to parse number/datetime skeleton as tokens
    )[0] # always only 1 item
