import PanelHelpers from './helpers/PanelHelpers'

export default class Plural extends React.PureComponent

  constructor: (props) ->
    super(props)

    @sourceRef = React.createRef()
    @targetRef = React.createRef()

    @copySourceToTarget = @copySourceToTarget.bind(this)
    @insertNextTag      = @insertNextTag.bind(this)
    @updateTarget       = @updateTarget.bind(this)

  componentDidMount: ->
    @bindShortcuts()
    @bindTags()
    @bindTabFocusBlurTarget()
    @refreshTabIndicator()

  componentDidUpdate: (prevProps) ->
    @refreshTabIndicator()

  componentWillUnmount: ->
    @unbindShortcuts()

  # Generic Methods from helper!

  bindShortcuts: ->
    new PanelHelpers(@).bindShortcuts()

  unbindShortcuts: ->
    new PanelHelpers(@).unbindShortcuts()

  bindTags: ->
    new PanelHelpers(@).bindTags()

  bindTabFocusBlurTarget: ->
    new PanelHelpers(@).bindTabFocusBlurTarget()

  refreshTabIndicator: ->
    new PanelHelpers(@).refreshTabIndicator()

  insertTab: ->
    new PanelHelpers(@).insertTab()

  removeTab: ->
    new PanelHelpers(@).removeTab()

  insertNextTag: ->
    new PanelHelpers(@).insertNextTag()

  insertTargetText: (text) ->
    new PanelHelpers(@).insertTargetText(text)

  copySourceToTarget: ->
    new PanelHelpers(@).copySourceToTarget()

  updateTarget: (e) ->
    new PanelHelpers(@).updateTarget(e)

  # Custom methods for Plural

  updateActiveMsgstr: (newMsgstr, callback = undefined) ->
    activeMsgstr = "msgstrPlural#{@props.activePluralIndex+1}"

    @props.updateActiveMsgstrs(
      "#{activeMsgstr}": newMsgstr
    , callback)

  source: ->
    activePluralCase = @props.targetLanguage.pluralCases[@props.activePluralIndex]

    if activePluralCase == 'one'
      return @props.msgid
    else # 'zero' is plural for English, and English is usually the source
      return @props.msgidPlural

  target: ->
    @props["msgstrPlural#{@props.activePluralIndex + 1}"]

  render: ->
    <div className="plurals-wrapper">
      <div className="plurals">
        {@renderTabs()}
      </div>
      <div className="plurals-translations">
        {@renderTranslationZone()}
      </div>
    </div>

  renderTabs: ->
    _.map([0..@props.targetLanguage.nPlurals-1], (i) =>
      @renderTab(i)
    )

  renderTab: (i) ->
    label = @props.targetLanguage.pluralCases[i]
    title = @props.targetLanguage.pluralCaseSampleSentences[i] || '' # Show sample sentence if exist, or else nothing (it will not display the tooltip if empty string)

    new PanelHelpers(@).renderPluralTab(i, label, title)

  renderTranslationZone: ->
    new PanelHelpers(@).renderPluralZone()
