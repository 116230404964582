import Dropdown from './project/Dropdown'
import Bars     from './project/Bars'
import Users    from './project/Users'

export default class Project extends React.PureComponent

  render: ->
    classNames  = "list-group-item project clearfix"
    classNames += " archived" if @props.project.archived

    <li className={classNames}>
      { @renderName()      }
      { @renderDropdown()  }
      { @renderBars()      }
      { @renderKeysCount() }
      { @renderMembers()   }
    </li>

  renderDropdown: ->
    <Dropdown project={@props.project} />

  renderBars: ->
    <Bars project={@props.project} />

  renderKeysCount: ->
    <div className="keys">
      <span className="badge" title="Number of source keys">
        {@props.project.keysCount}
      </span>
    </div>

  renderName: ->
    if !@props.project.isOwner
      ownerSpan = <span>{@props.project.ownerName}/</span>

    <span className="name">
      {@renderArchivedIcon()}
      <a href={@props.project.path}>
        {ownerSpan}
        <em>{@props.project.name}</em>
      </a>
      {@renderOpenSourceBadge()}
    </span>

  renderArchivedIcon: ->
    if @props.project.archived
      <i className="fas fa-box-archive"
         aria-hidden="true"
         aria-label="Frozen"
         title="This project is frozen"></i>

  renderOpenSourceBadge: ->
    if @props.project.isOwner && @props.project.openSource
      <span className="badge badge-open-source" title="Open-source project">
        open-source
      </span>

  renderMembers: ->
    <Users project={@props.project}
           spots="5" />


