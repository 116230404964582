export default class ManageLanguages extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      targetLocaleIds: []
    }

  componentDidMount: ->
    @reloadSelectedTargetLanguage()
    @bindSelect2OnChange()

  reloadSelectedTargetLanguage: ->
    @setState(targetLocaleIds: $('#project_target_language_ids2').val() || [])

  bindSelect2OnChange: ->
    $('#project_target_language_ids2').on('change', =>
      @reloadSelectedTargetLanguage()
    )

  joinedTargetLocaleCodes: ->
    list = ''

    for targetLocaleId, index in @state.targetLocaleIds
      item = "'" + @props.languageIdCodeMapping[targetLocaleId] + "'"

      if index == 0
        list += item
      else
        list += ', ' + item

    list

  render: ->
    <div>
      <p>
        To add or remove a target language, you must update the <code>{@renderConfigTargetLocale()}</code> line in your code and execute {@renderSyncCommand()}.
      </p>

      <label htmlFor="project_target_language_ids2">
        1. Add or remove languages
      </label>

      <select id="project_target_language_ids2"
              className='form-select select2-control select2'
              multiple="multiple"
              dangerouslySetInnerHTML={{__html: @props.optionsForSelect}}>
      </select>

      <p style={{ marginBottom: '0.5rem' }}>
        <strong>
          2. Adapt this line in your code
        </strong>
      </p>

      {@renderLanguagesConfigLine()}

      <p>
        <strong>
          3. Execute
        </strong>
        &nbsp;
        {@renderSyncCommand()}
      </p>

      <div style={{ clear: 'both' }}></div>

      <br/>

      <p>
        See also:
      </p>

      <ul>
        <li>The complete list of <a href="/docs/languages" target="_blank">supported languages</a>.</li>
        <li>How to use <a href={@renderCustomLanguageLink()} target="_blank">custom languages</a>.</li>
      </ul>
    </div>

  # NEW_STACK - Personnalize configuration
  renderConfigTargetLocale: ->
    if @props.stack == 'rails'
      'config.target_locales'
    else if @props.stack == 'laravel'
      'target_locales'
    else if @props.stack == 'angular'
      'target_locales'
    else # lingui
      '"locales": []'

  # NEW_STACK - Personnalize sync command
  renderSyncCommand: ->
    if @props.stack == 'rails'
      <code>
        rake translation:sync
      </code>
    else if @props.stack == 'laravel'
      <code>
        php artisan translation:sync
      </code>
    else if @props.stack == 'angular'
      <span>
        <code>npm run translation:sync</code> or <code>yarn translation:sync</code>
      </span>
    else # lingui
      <span>
        <code>npm run sync</code> or <code>yarn sync</code>
      </span>

  # NEW_STACK - Personnalize link
  renderCustomLanguageLink: ->
    if @props.stack == 'rails'
      'https://github.com/translation/rails#custom-languages'
    else if @props.stack == 'laravel'
      'https://github.com/translation/laravel#custom-languages'
    else if @props.stack == 'angular'
      'https://github.com/translation/angular#custom-languages'
    else # lingui
      'https://github.com/translation/lingui#custom-languages'

  # NEW_STACK - Personnalize configuration
  renderLanguagesConfigLine: ->
    if @props.stack == 'rails'
      <pre className="languages">config.target_locales <span className="orange">=</span> [<span className="green">{@joinedTargetLocaleCodes()}</span>]</pre>
    else if @props.stack == 'laravel'
      <pre className="languages"><span className="blue">'target_locales'</span> => [<span className="blue">{@joinedTargetLocaleCodes()}</span>]</pre>
    else if @props.stack == 'angular'
      <pre className="languages"><span className="blue">"target_locales"</span>: [<span className="orange">{"#{@joinedTargetLocaleCodes()}"}</span>],<br/></pre>
    else # lingui
      <pre className="languages"><span className="blue">"locales"</span>: [<span className="orange">{"'#{@props.sourceLanguage.code}', #{@joinedTargetLocaleCodes()}"}</span>],<br/></pre>

