import Date from './shared/Date'

export default class SourceEdit extends React.PureComponent

  goTo: (sourceEdit) ->
    if !sourceEdit.segmentWasRemoved
      window.open(sourceEdit.path, '_blank')

  warning: (sourceEdit) ->
    if sourceEdit.segmentWasRemoved
      "This segment doesn't exist anymore because it has been removed since."

  diffSource: (sourceEdit) ->
    historyUtils.diffSource(sourceEdit)

  highlightedTarget: (sourceEdit) ->
    if @props.targetLanguage
      historyUtils.highlightTarget(sourceEdit, sourceEdit.targetPluralType, @props.targetLanguage.pluralCases, @props.stack)
    else
      '' # if no selected language, msgstr should be empty

  render: ->
    classes = if @props.sourceEdit.segmentWasRemoved then 'segment-was-removed' else 'hoverable'

    <div className="row">
      <div className="col-sm-4 left-side">
        <div className="media">
          <div className="media-left">
            <img src={@props.sourceEdit.imageUrl} alt={@props.sourceEdit.authorName} referrerPolicy="no-referrer" />
          </div>

          <div className="media-body">
            <p>
              <strong>{@props.sourceEdit.authorName}</strong> edited the <strong>source text</strong>.
            </p>

            { @renderAddedTag() }

            <Date item={@props.sourceEdit} />
          </div>
        </div>
      </div>

      <div className="col-sm-8 right-side">
        <table className="table diff">
          <tbody>
            <tr className={classes} onClick={@goTo.bind(this, @props.sourceEdit.history[0])} title={@warning(@props.sourceEdit)}>
              <td dangerouslySetInnerHTML={{__html: @diffSource(@props.sourceEdit.history[0])}}>
              </td>

              <td className="highlighted" dangerouslySetInnerHTML={{__html: @highlightedTarget(@props.sourceEdit.history[0]) }}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  renderAddedTag: ->
    <span className="tag-info">
      <span className="small" dangerouslySetInnerHTML={{__html: @props.sourceEdit.addedTagText}}></span>
    </span>
