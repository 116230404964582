import Date from './shared/Date'

export default class Comment extends React.PureComponent

  goTo: (comment) ->
    if !comment.segmentWasRemoved
      window.open(comment.path, '_blank');

  warning: ->
    if @props.comment.segmentWasRemoved
      "This segment doesn't exist anymore because it has been removed since."

  highlightedSource: ->
    comment = @props.comment.history[0]
    historyUtils.highlightSource(comment, comment.pluralType, @props.stack)

  highlightedTarget: ->
    comment = @props.comment.history[0]
    historyUtils.highlightTarget(comment, comment.pluralType, @props.targetLanguage.pluralCases, @props.stack)

  render: ->
    classes = if @props.comment.segmentWasRemoved then 'segment-was-removed' else 'hoverable'

    <div className="row">
      <div className="col-sm-4 left-side">
        <div className="media">
          <div className="media-left">
            <img src={@props.comment.imageUrl} alt={@props.comment.authorName} referrerPolicy="no-referrer" />
          </div>
          <div className="media-body">
            { @renderComment() if !@props.comment.action }
            { @renderAction()  if  @props.comment.action }

            <Date item={@props.comment} />
          </div>
        </div>
      </div>

      <div className="col-sm-8 right-side">
        <table className="table diff">
          <tbody>
            <tr className={classes} onClick={@goTo.bind(this, @props.comment.history[0])} title={@warning()}>
              <td className="highlighted" dangerouslySetInnerHTML={{__html: @highlightedSource() }}>
              </td>
              <td className="highlighted" dangerouslySetInnerHTML={{__html: @highlightedTarget() }}>
              </td>
            </tr>
          </tbody>
        </table>

        { @renderChangedWarning() }
      </div>
    </div>

  renderComment: ->
    <p>
      <strong>{@props.comment.authorName}</strong> commented ({@props.targetLanguage.code}):
      <span>&nbsp;</span>
      <span className="quotation" onClick={@goTo.bind(this, @props.comment.history[0])}>
        <span className="quote-left">«</span>
        &nbsp;
        <span dangerouslySetInnerHTML={{ __html: @props.comment.history[0].content }}></span>
        &nbsp;
        <span className="quote-right">»</span>
      </span>
    </p>

  renderAction: ->
    <p>
      <strong>{@props.comment.authorName}</strong> <span dangerouslySetInnerHTML={{__html: @props.comment.history[0].tagContent}}></span> ({@props.targetLanguage.code})
    </p>

  renderChangedWarning: ->
    if @props.comment.segmentHasBeenEditedSince
      <div className="changed-warning">
        The segment has been updated since this comment.
      </div>

