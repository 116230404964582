import Comment     from './history/Comment'
import PastSegment from './history/PastSegment'
import SourceEdit  from './history/SourceEdit'

export default class History extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      selectedTargetLanguage: _.find(@props.project.targetLanguages, (language) => language.id == @props.selectedTargetLanguageId)
      selectedUser:           _.find(@props.project.users, (user) => user.id == @props.selectedUserId)
      selectedType:           @props.selectedType
      history:                []
      batchedHistory:         []
      fromTimestamp:          new Date().getTime() / 1000
      loaded:                 false
      infiniteLoaded:         true
      infiniteEnabled:        true
      infiniteScrollOffset:   1000
    }

    @dReloadHistoryFromBackend = _.debounce(@reloadHistoryFromBackend, 5)

    @setTargetLanguage = @setTargetLanguage.bind(this)
    @setSelectedType   = @setSelectedType.bind(this)
    @setSelectedUser   = @setSelectedUser.bind(this)

  componentDidMount: ->
    @dReloadHistoryFromBackend()
    @bindInfiniteScroll()

  componentWillUnmount: ->
    clearInterval(@intervalId) if @intervalId

  backendPath: ->
    if @state.selectedTargetLanguage
      "/#{@props.project.owner}/#{@props.project.name}/#{@state.selectedTargetLanguage.code}/history"
    else
      "/#{@props.project.owner}/#{@props.project.name}/history"

  backendParams: ->
    return
      fromTimestamp: @state.fromTimestamp
      type:          @state.selectedType
      authorId:      if @state.selectedUser then @state.selectedUser.id else undefined

  reloadHistoryFromBackend: ->
    path   = @backendPath()
    params = @backendParams()

    http.get(path, params, (data) =>
      if path == @backendPath() && _.isEqual(params, @backendParams()) # Check if result os still consistent with state
        history       = data.history
        mergedHistory = @state.history.concat(history)

        @setState({
          history:         mergedHistory
          batchedHistory:  @createBatchHistory(mergedHistory)
          fromTimestamp:   if mergedHistory.length then _.last(mergedHistory).timestamp else (new Date().getTime() / 1000)
          loaded:          true
          infiniteLoaded:  true
          infiniteEnabled: !(history.length < 50) # If you change the value here, change also in history_service.rb
        }, @rebindFrameNotFilledSituation)
    )

  isNewBatch: (batches, previousItem, newItem) ->
    batches                       == 0                        ||
    newItem.type                  == 'comment'                ||
    newItem.type                  == 'source_edit'            ||
    previousItem.type             != newItem.type             ||
    previousItem.sourceLanguageId != newItem.sourceLanguageId ||
    previousItem.targetLanguageId != newItem.targetLanguageId ||
    previousItem.authorName       != newItem.authorName       ||
    previousItem.timestamp - newItem.timestamp > @props.delayForBatches

  createBatchHistory: (history) ->
    history        = _.filter(history, 'visible')
    batchedHistory = []
    batches        = 0
    previousItem   = history[0] if history.length > 0

    for historyItem in history
      if @isNewBatch(batches, previousItem, historyItem)
        batchedHistory.push({ history: [historyItem] })
        batches = batches + 1
      else
        batchedHistory[batches-1].history.push(historyItem)

      previousItem = historyItem

    for batchedHistoryItem in batchedHistory
      length = batchedHistoryItem.history.length
      batchedHistoryItem['type']              = batchedHistoryItem.history[0].type
      batchedHistoryItem['authorName']        = batchedHistoryItem.history[0].authorName
      batchedHistoryItem['imageUrl']          = batchedHistoryItem.history[0].imageUrl
      batchedHistoryItem['targetLanguageId']  = batchedHistoryItem.history[0].targetLanguageId
      batchedHistoryItem['createdAt']         = batchedHistoryItem.history[0].createdAt
      batchedHistoryItem['timestamp']         = batchedHistoryItem.history[0].timestamp
      batchedHistoryItem['segmentWasRemoved'] = batchedHistoryItem.history[0].segmentWasRemoved

      if batchedHistoryItem.history[0].type == 'past_segment'
        batchedHistoryItem['lineCount'] = length
      else if batchedHistoryItem.history[0].type == 'source_edit'
        batchedHistoryItem['addedTagText'] = batchedHistoryItem.history[0].addedTagText
        batchedHistoryItem['msgstr']       = batchedHistoryItem.history[0].msgstr
      else if batchedHistoryItem.history[0].type == 'comment'
        batchedHistoryItem['action']                    = batchedHistoryItem.history[0].action
        batchedHistoryItem['segmentHasBeenEditedSince'] = batchedHistoryItem.history[0].segmentHasBeenEditedSince

        # needed to display tags correctly
        if batchedHistoryItem['action']
          trusted_text = batchedHistoryItem.history[0].content.slice(0, -1)
          batchedHistoryItem.history[0]['tagContent'] = trusted_text

    return batchedHistory

  bindInfiniteScroll: ->
    $('#modal-history .scrollable-history').on('scroll', null, =>
      if @state.infiniteLoaded && @state.infiniteEnabled && @state.loaded
        history   = $('#modal-history .scrollable-history')
        scrollTop = history.scrollTop() + history.outerHeight()
        height    = history.prop('scrollHeight')

        if scrollTop >= height - @state.infiniteScrollOffset
          @setState(infiniteLoaded: false, @dReloadHistoryFromBackend)
    )

  # Deal with the situation when the window is not scrollable but elements are missing
  # (could happen if a lot of history items are not visible in the response)
  # SetTimer will auto-destruct when enough history items are loaded
  rebindFrameNotFilledSituation: ->
    clearInterval(@intervalId) if @intervalId

    @intervalId = setInterval( =>
      if @state.infiniteLoaded && @state.infiniteEnabled && @state.loaded
        historyFrameHeight   = $('#modal-history .scrollable-history').outerHeight()
        historyContentHeight = _.sum(
          $('.scrollable-history > div').map( -> $(this).outerHeight())
        )

        if historyFrameHeight >= historyContentHeight - @state.infiniteScrollOffset*10
          @setState(infiniteLoaded: false, @dReloadHistoryFromBackend)
        else
          clearInterval(@intervalId) if @intervalId
    , 10)

  resetValues: ->
    values =
      history:         []
      batchedHistory:  []
      fromTimestamp:   new Date().getTime() / 1000
      loaded:          false
      infiniteLoaded:  true
      infiniteEnabled: true

  setTargetLanguage: (e) ->
    if e.target.value == 'all'
      newSelectedTargetLanguage = undefined
    else
      newSelectedTargetLanguage = _.find(@props.project.targetLanguages, { id: parseInt(e.target.value) })

    newValues = { selectedTargetLanguage: newSelectedTargetLanguage }
    newValues = Object.assign({}, newValues, @resetValues())

    @setState(newValues, @dReloadHistoryFromBackend)

  setSelectedType: (e) ->
    newValues = { selectedType: e.target.value }
    newValues = Object.assign({}, newValues, @resetValues())

    @setState(newValues, @dReloadHistoryFromBackend)

  setSelectedUser: (e) ->
    if e.target.value == 'all'
      newSelectedUser = undefined
    else
      newSelectedUser = _.find(@props.project.users, { id: parseInt(e.target.value) })

    newValues = { selectedUser: newSelectedUser }
    newValues = Object.assign({}, newValues, @resetValues())

    @setState(newValues, @dReloadHistoryFromBackend)

  render: ->
    <>
      {@renderHeader()}

      <div className="modal-body">
        <div className="scrollable-history">
          {@renderLoading()}
          {@renderBlankSlate()}
          {@renderBatchedHistory()}
          {@renderInfiniteLoading()}
        </div>
      </div>
    </>

  renderHeader: ->
    <div className="modal-header">
      <h5 className="modal-title" id="modal-history-label" title={ "History of #{@props.project.name}" }>
        History of {@props.project.name}
      </h5>
      { @renderTargetLanguageSelect() }
      { @renderTypeSelect() }
      { @renderUserSelect() }
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>

  renderTargetLanguageSelect: ->
    <select className="form-select"
            name="select-language"
            title="Target language"
            value={if @state.selectedTargetLanguage then @state.selectedTargetLanguage.id else 'all'}
            onChange={@setTargetLanguage}>
      <option key="all" value="all">
        All Languages
      </option>
      { @renderTargetLanguageOptions() }
    </select>

  renderTargetLanguageOptions: ->
    _.map(@props.project.targetLanguages, (targetLanguage, index) =>
      <option key={index} value={targetLanguage.id}>
        {targetLanguage.code} • {targetLanguage.regionName}
      </option>
    )

  renderTypeSelect: ->
    <select className="form-select"
            name="select-type"
            title="History type"
            value={@state.selectedType}
            onChange={@setSelectedType}>
      <option key="all" value="all">
        All Types
      </option>
      <option key="past_segment" value="past_segment">
        Target Changes
      </option>
      <option key="source_edit" value="source_edit">
        Source Editions
      </option>
      <option key="comment" value="comment">
        Comments and Tags
      </option>
    </select>

  renderUserSelect: ->
    <select className="form-select"
            name="select-user"
            title="User"
            value={if @state.selectedUser then @state.selectedUser.id else 'all'}
            onChange={@setSelectedUser}>
      <option key="all" value="all">
        All Users
      </option>
      { @renderUserOptions() }
    </select>

  renderUserOptions: ->
    _.map(@props.project.users, (user) =>
      <option key={user.id} value={user.id}>
        {user.name}
      </option>
    )

  renderLoading: ->
    if !@state.loaded
      <div className="loading">
        Loading, please wait...<br/>
        <i className="fas fa-heart"></i>
      </div>
    else
      ''

  renderInfiniteLoading: ->
    if !@state.infiniteLoaded
      <div className="infinite-loading loading">
        <i className="fas fa-heart"></i>
      </div>
    else
      ''

  renderBlankSlate: ->
    if @state.loaded && @state.history.length == 0
      <div className="empty-container">
        <div className="empty">
          No history for the project <em>{@props.project.name}</em>
        </div>
      </div>
    else
      ''

  renderBatchedHistory: ->
    length = @state.batchedHistory.length

    _.map(@state.batchedHistory, (batchedHistoryItem, index) =>
      classes = 'container-fluid batched-history'
      classes += " #{batchedHistoryItem.type}"
      classes += " last" if index + 1 == length

      <div className={classes} key={index}>
        {@renderPastSegment(batchedHistoryItem) if batchedHistoryItem.type == 'past_segment'}
        {@renderComment(batchedHistoryItem)     if batchedHistoryItem.type == 'comment'}
        {@renderSourceEdit(batchedHistoryItem)  if batchedHistoryItem.type == 'source_edit'}

        <div style={{ clear:'both' }}></div>
      </div>
    )

  renderPastSegment: (pastSegment) ->
    pastSegmentTargetLanguage = _.find(@props.project.targetLanguages, (language) => language.id == pastSegment.targetLanguageId)

    <PastSegment pastSegment={pastSegment}
                 stack={@props.project.stack}
                 targetLanguage={pastSegmentTargetLanguage}/>

  renderComment: (comment) ->
    commentTargetLanguage = _.find(@props.project.targetLanguages, (language) => language.id == comment.targetLanguageId)

    <Comment comment={comment}
             stack={@props.project.stack}
             targetLanguage={commentTargetLanguage} />

  renderSourceEdit: (sourceEdit) ->
    sourceEditTargetLanguage = @state.selectedTargetLanguage # can be nil (=> empty translation) if no selected language

    <SourceEdit sourceEdit={sourceEdit}
                stack={@props.project.stack}
                targetLanguage={sourceEditTargetLanguage} />
