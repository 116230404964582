import Search     from './projects_listing/Search'
import BlankSlate from './projects_listing/BlankSlate'
import Sidebar    from './projects_listing/Sidebar'
import Project    from './projects_listing/Project'
import Summary    from './projects_listing/Summary'

export default class ProjectsListing extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      loaded:           true # now is server-side rendering
      projects:         @props.projects
      filteredProjects: @props.projects
      query:            ''
    }

    @dUpdateFilteredProjects = _.debounce(@updateFilteredProjects, 50)

    @setFilters       = @setFilters.bind(this)
    @hasActiveFilters = @hasActiveFilters.bind(this)

  componentDidMount: ->
    window.ProjectsListingComponent = this

  reloadFromBackend: ->
    http.get(path.current(), {}, (data) =>
      @setState({
        projects: data
        loaded:   true
      }, @dUpdateFilteredProjects)
    )

  setFilters: (query) ->
    @setState(
      query: query
    , @dUpdateFilteredProjects)

  updateFilteredProjects: ->
    newFilteredProjects = _.filter(@state.projects, (project) =>
      project.searchableKey.match(RegExp(_.escapeRegExp("#{@state.query}")))
    )

    @setState(
      filteredProjects: newFilteredProjects
    )

  hasActiveFilters: ->
    @state.query != ''

  render: ->
    <div className="container">
      {@renderLoading()}
      {@renderContent()}
    </div>

  renderLoading: ->
    if !@state.loaded # not shown if server-side rendered
      <div className="loading">
        Loading, please wait...<br/>
        <i className="fas fa-heart"></i>
      </div>

  renderContent: ->
    if @state.loaded
      <div className="row">
        <div className="col-lg-10 col-xl-9">
          <div className="d-flex">
            {@renderSearch()}
            {@renderNewProjectButton()}
          </div>

          {@renderBlankSlate()}
          {@renderProjectsList()}
          {@renderSummary()}
        </div>

        {@renderSidebar()}
      </div>

  renderSearch: ->
    <Search projects={@state.filteredProjects}
            query={@state.query}
            setFilters={@setFilters} />

  renderNewProjectButton: ->
    <button className="btn btn-primary new"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#modal-new-project">
      New project
    </button>

  renderBlankSlate: ->
    if @state.filteredProjects.length == 0
      <BlankSlate query={@state.query} />

  renderSidebar: ->
    <Sidebar currentUser={@props.currentUser} />

  renderProjectsList: ->
    children = _.map(@state.filteredProjects, (project) ->
      classNames = project.archived ? 'archived' : ''

      <Project project={project}
               key={project.id}
               classNames={classNames} />
    )

    <ul className="list-group projects"
        data-total-projects-count={@state.projects.length}>
      {children}
    </ul>

  renderSummary: ->
    <Summary currentUser={@props.currentUser}
             projects={@state.filteredProjects}
             hasActiveFilters={@hasActiveFilters}
             activeSegmentPrice={@props.currentUser.activeSegmentPrice}
             archivedSegmentPrice={@props.currentUser.archivedSegmentPrice}
             discounts={@props.currentUser.discounts}
             currencySymbol={@props.currentUser.currencySymbol} />

