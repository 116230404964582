# Utils methods for displaying Segments

export default class HistoryUtils

  # SourceEdits can't be associated to plural segments
  @diffSource: (sourceEdit) ->
    # Old text (can be singular or icu_plural)
    if sourceEdit.oldSourcePluralType == 'singular'
      oldText = sourceEdit.oldText
    else if sourceEdit.oldSourcePluralType == 'icu_plural'
      # Using 2 times oldText is "normal" since we don't care for target
      icuSegment = new ICU(sourceEdit.oldText, sourceEdit.oldText, [])
      oldText    = icuSegment.completeSourceForDiff()

    # New text (can be singular or icu_plural)
    if sourceEdit.newSourcePluralType == 'singular'
      newText = sourceEdit.newText
    else if sourceEdit.newSourcePluralType == 'icu_plural'
      # Using 2 times newText is "normal" since we don't care for target
      icuSegment = new ICU(sourceEdit.newText, sourceEdit.newText, [])
      newText = icuSegment.completeSourceForDiff()

    diffString(oldText, newText).replace(/ ≺ /g, " <strong>≺</strong> ")

  @diffTarget: (pastSegment, pluralCases) ->
    if pastSegment.targetPluralType == 'gettext_plural'
      msgstrPluralParts     = @relevantPluralPartsForDiff(pastSegment, 'msgstrPlural')
      nextMsgstrPluralParts = @relevantPluralPartsForDiff(pastSegment, 'nextMsgstrPlural')

      msgstr     = _.join(msgstrPluralParts,     ' ≺ ')
      nextMsgstr = _.join(nextMsgstrPluralParts, ' ≺ ')
    else
      # Previous msgstr (can be singular or icu_plural)
      if pastSegment.targetPluralType == 'singular'
        msgstr = pastSegment.msgstr
      else if pastSegment.targetPluralType == 'icu_plural'
        # Using 2 times target is "normal" since current msgid may not be icu anymore
        icuSegment = new ICU(pastSegment.msgstr, pastSegment.msgstr, pluralCases)
        msgstr     = icuSegment.completeTargetForDiff()

      # Next msgstr (can be singular or icu_plural)
      if pastSegment.nextTargetPluralType == 'singular'
        nextMsgstr = pastSegment.nextMsgstr
      else if pastSegment.nextTargetPluralType == 'icu_plural'
        # Using 2 times target is "normal" since current msgid may not be icu anymore
        icuSegment = new ICU(pastSegment.nextMsgstr, pastSegment.nextMsgstr, pluralCases)
        nextMsgstr = icuSegment.completeTargetForDiff()

    diffString(msgstr, nextMsgstr).replace(/ ≺ /g, " <strong>≺</strong> ")

  @highlightSource: (historyItem, pluralType, stack) ->
    if pluralType == 'gettext_plural'
      source = "#{historyItem.msgid} ≺ #{historyItem.msgidPlural}"
    else if pluralType == 'icu_plural'
      # Using 2 times source is "normal", we don't care about the state of target
      icuSegment = new ICU(historyItem.msgid, historyItem.msgid, [])
      source = icuSegment.completeSource()
    else # singular
      source = historyItem.msgid

    highlight.highlight(source, '', [stack, 'html'])

  @highlightTarget: (historyItem, pluralType, pluralCases, stack) ->
    if pluralType == 'gettext_plural'
      pluralParts = @relevantPluralParts(historyItem, pluralCases.length)

      if _.trim(_.join(pluralParts, '')) == ''
        target = ''
      else
        target = _.join(pluralParts, ' ≺ ')
    else if pluralType == 'icu_plural'
      icuSegment = new ICU(historyItem.msgstr, historyItem.msgstr, pluralCases)
      target = icuSegment.completeTarget()
    else # singular
      target = historyItem.msgstr

    highlight.highlight(target, '', [stack, 'html'])

  # With extra ≺ to indicate missing plurals
  @relevantPluralParts: (historyItem, nPlurals, msgstrPrefix = "msgstrPlural") ->
    _.take([
      historyItem["#{msgstrPrefix}1"],
      historyItem["#{msgstrPrefix}2"],
      historyItem["#{msgstrPrefix}3"],
      historyItem["#{msgstrPrefix}4"],
      historyItem["#{msgstrPrefix}5"],
      historyItem["#{msgstrPrefix}6"]
    ], nPlurals)

  # No extra ≺ for cleaner diffs
  @relevantPluralPartsForDiff: (historyItem, msgstrPrefix = "msgstrPlural") ->
    _.filter([ # _.filter instead of _.take(nPlurals) to make diffs cleaner
      historyItem["#{msgstrPrefix}1"],
      historyItem["#{msgstrPrefix}2"],
      historyItem["#{msgstrPrefix}3"],
      historyItem["#{msgstrPrefix}4"],
      historyItem["#{msgstrPrefix}5"],
      historyItem["#{msgstrPrefix}6"]
    ], (value) => value.length > 0)
