import MemoryResult from './memory/MemoryResult'

export default class Memory extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      results: []
    }

    @dReloadMemory = _.debounce(@reloadMemory, 1) # Increase if scrolling quickly between segments becomes an issue (2 requests by step)
                                                  # We don't care for now, the server impact is negligible and we keep fast first-loading
                                                  # Also see History.coffee
                                                  # TODO: if it becomes an issue: check if up/down button is pressed to change debounce time

    @insertResult0 = @insertResult.bind(this, 0)
    @insertResult1 = @insertResult.bind(this, 1)
    @insertResult2 = @insertResult.bind(this, 2)
    @insertResult3 = @insertResult.bind(this, 3)
    @insertResult4 = @insertResult.bind(this, 4)

  componentDidMount: ->
    @reloadMemory()
    @bindShortcuts()

  componentWillUnmount: ->
    @unbindShortcuts()

  componentDidUpdate: (prevProps, prevState, snapshot) ->
    # reload TM only if other segment or other plural
    if @props.segment.id != prevProps.segment.id || @props.pluralIndex != prevProps.pluralIndex
      @setState(results: []) # should be here to avoid "applied" class flicking because of debounce
      @dReloadMemory()

  bindShortcuts: ->
    ctrlModifier = if _.includes(navigator.platform, 'Mac') then 'meta' else 'ctrl'
    selectors    = 'body, textarea.target, input.filter-search'

    _.each([1, 2, 3, 4, 5], (num) =>
      $(document).on('keydown', selectors,"#{ctrlModifier}+#{num}", @["insertResult#{num-1}"])
    )

  unbindShortcuts: ->
    _.each([1, 2, 3, 4, 5], (num) =>
      $(document).off('keydown', @["insertResult#{num-1}"])
    )

  insertResult: (index) ->
    result = @state.results[index]

    if result && @props.selectedSegmentCount <= 1 # block insertion when multi-selection
      if @props.segment.pluralType == 'icu_plural'
        # Create the ICU for this plural
        icuSegment   = new ICU(@props.segment.msgid, @props.segment.msgstr, @props.targetLanguage.pluralCases)
        newIcuMsgstr = icuSegment.injectTextAndDumpTarget(result.msgstr, @props.pluralIndex)

        @props.updateActiveMsgstrs({ msgstr: newIcuMsgstr }, @props.focusTextarea)
      else if @props.segment.pluralType == 'gettext_plural'
        @props.updateActiveMsgstrs({ "msgstrPlural#{@props.pluralIndex+1}": result.msgstr }, @props.focusTextarea)
      else # singular
        @props.updateActiveMsgstrs({ msgstr: result.msgstr }, @props.focusTextarea)

    false

  reloadMemory: ->
    query = @queryForSegment()

    http.post(path.relative("segments/#{@props.segment.id}/translation_memories"), { query: query }, (data) =>
      if query == @queryForSegment() # Check if result os still consistent with state
        @setState(results: data.results)
    )

  queryForSegment: ->
    if @props.segment.pluralType == 'icu_plural'
      icuSegment = new ICU(@props.segment.msgid, @props.segment.msgstr, @props.targetLanguage.pluralCases)
      icuSegment.sourceByPluralIndex(@props.pluralIndex)
    else if @props.segment.pluralType == 'gettext_plural'
      if @props.targetLanguage.pluralCases[@props.pluralIndex] == 'one'
        @props.segment.msgid
      else
        @props.segment.msgidPlural
    else # singular
      @props.segment.msgid

  isApplied: (memoryResult) ->
    memoryResult.msgstr == @activeMsgstr()

  activeMsgstr: ->
    if @props.segment.pluralType == 'icu_plural'
      icuSegment = new ICU(@props.segment.msgid, @props.segment.msgstr, @props.targetLanguage.pluralCases)
      icuSegment.targetByPluralIndex(@props.pluralIndex)
    else if @props.segment.pluralType == 'gettext_plural'
      @props.segment["msgstrPlural#{@props.pluralIndex+1}"]
    else # singular
      @props.segment.msgstr

  render: ->
    <div>
      {@renderContext()}
      <span className="title suggestions">Suggestions</span>
      <ul className="list-group">
        {@renderResults()}
      </ul>
    </div>

  renderContext: ->
    if @props.segment.msgctxt.length
      <div>
        <span className="title">Context</span>
        <ul className="list-group">
          <li className="list-group-item context">
            <em className="content" title={@props.segment.msgctxt}>
              {@props.segment.msgctxt}
            </em>
          </li>
        </ul>
      </div>

  renderResults: ->
    uniqueKey = "#{Math.random().toString(36).substring(8)}" # like "nd9re"

    _.map(@state.results, (result, index) =>
      ref       = "result_#{index}"
      isApplied = @isApplied(result)

      <MemoryResult key={"#{uniqueKey}#{index}"}
                    ref={ref}
                    result={result}
                    isApplied={isApplied}
                    rightToLeft={@props.targetLanguage.rightToLeft}
                    projectId={@props.project.id}
                    insertResult={@["insertResult#{index}"]}
                    focusTextarea={@props.focusTextarea} />
    )
