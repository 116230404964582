export default class Highlight

  @TAGS = [
    'div', 'span', 'a', 'em', 'i', 'strong', 'b', 'big', 'blockquote',
    'center', 'del', 'font', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'ins',
    'p', 'pre', 's', 'small', 'sub', 'sup', 'u', 'ul', 'li', 'ol', 'br'
  ].concat([0..20].map((i) => i.toString())) # <0> to <20>

  # * "text"           => text to highlight
  # * "query"          => active query (in search input)
  # * "tagTypes"       => is an array that can contain the languages to highlight
  # * "translatedTags" => special array with tags that are already present in translation (special class will be added)
  @highlight: (text, query, tagTypes = [], translatedTags = []) ->
    escapedTranslatedTags = _.map(translatedTags, (translatedTag) => _.escape(translatedTag))

    if text && text.length
      highlightText = _.escape(text) # escape html entities
      highlightText = @highlightQuery(highlightText, query)
      highlightText = @highlightTags(highlightText, tagTypes, escapedTranslatedTags)
      highlightText = @highlightPlurals(highlightText)
      return highlightText
    else
      return '&nbsp;' # Non-breaking space (fix for Firefox design)

  @highlightQuery: (escapedText, query) ->
    if query && query.length
      escapedQuery = _.escapeRegExp(_.escape(query))
      escapedQuery = "(?=#{escapedQuery}(?!39))#{escapedQuery}" if query == '#' # Ignore # in &#39 (') => https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L384

      regexp = new RegExp("(#{escapedQuery})", "ig")
      escapedText.replace(regexp, "<i>$1</i>")
    else
      escapedText

  @highlightTags: (escapedText, tagTypes = [], escapedTranslatedTags = []) ->
    escapedTags = @extractEscapedTags(escapedText, tagTypes)

    _.each(escapedTags, (escapedTag) =>
      if _.includes(tagTypes, 'html') && escapedTag.match(@htmlRegexp())
        [escapedText, escapedTranslatedTags] = @highlightTagWithTranslated(escapedText, escapedTag, escapedTranslatedTags, "html", "html translated")
      else
        [escapedText, escapedTranslatedTags] = @highlightTagWithTranslated(escapedText, escapedTag, escapedTranslatedTags, "", "translated")
    )

    escapedText

  @highlightPlurals: (escapedText) ->
    escapedText.replace(/ ≺ /g, " <strong>≺</strong> ")

  @highlightTagWithTranslated: (escapedText, tag, escapedTranslatedTags, highlightKlass, highlightTranslatedKlass) ->
    if _.includes(escapedTranslatedTags, tag) # special class if tag already translated
      index = _.findIndex(escapedTranslatedTags, (escapedTranslatedTag) => escapedTranslatedTag == tag)
      _.pullAt(escapedTranslatedTags, index) # if many similar tasks, don't add class for all of them

      [
        @highlightTag(escapedText, tag, highlightTranslatedKlass),
        escapedTranslatedTags
      ]
    else
      [
        @highlightTag(escapedText, tag, highlightKlass),
        escapedTranslatedTags
      ]

  @highlightTag: (escapedText, tag, klass = "") ->
    escapedTag      = _.escapeRegExp(tag)
    lookAheadRegexp = "(?=#{escapedTag}(?!<\/em>))#{escapedTag}"                # Ignore already replaced tags (tag followed by </em>) => LookAhead
    lookAheadRegexp = "(?=#{escapedTag}(?!39))#{lookAheadRegexp}" if tag == '#' # Ignore # in &#39 (') => https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L384

    regexp          = new RegExp("(#{lookAheadRegexp})", "") # no need for 'g' flag because only one tag replacement
    highlightedTag  = if klass.length then "<em class=\"#{klass}\">#{tag}</em>" else "<em>#{tag}</em>"

    escapedText.replace(regexp, highlightedTag)

  # NEW_STACK - Add regexp for new stack

  @railsRegexp: ->
    a = String.raw'%\{([a-zA-Z0-9_]|\s)*\}'            # %{}
    b = String.raw'%&lt;([a-zA-Z0-9_]|\s)*&gt;[suidg]' # %<>s             => string             \
                                                       # %<>u, %<>i, %<>d => decimal (synonyms) | => https://apidock.com/ruby/Kernel/format
                                                       # %<>g             => floating           /
    RegExp("#{a}|#{b}", 'g')

  @laravelRegexp: ->
    /:[a-zA-Z_][a-zA-Z0-9_]*/g

  @linguiRegexp: ->
    a = String.raw'\{\w+\}' # {0} or {name}
    b = String.raw'#(?!39)' # '#' => we don't want # in &#39 html code (') to be highlighted
                            # => https://github.com/lodash/lodash/blob/4.17.15/lodash.js#L384

    RegExp("#{a}|#{b}", 'g')

  @angularRegexp: ->
    @linguiRegexp()

  @htmlRegexp: ->
    # ig = case insensitive
    # .*? is lazy: takes the minimal amount of chars and stop at the first following ">"
    tags            = @TAGS.join('|')
    startTagsRegexp = String.raw'(&lt;('       + tags + String.raw')(|\s.*?)\/?&gt;)' # catch <span>, <input/> and <a href=""> but not "<spandex>"
    endTagsRegexp   = String.raw'(&lt;\/\s*?(' + tags + String.raw')&gt;)'            # catch </span>
    RegExp("#{startTagsRegexp}|#{endTagsRegexp}", "ig")

  @extractEscapedTags: (escapedText, tagTypes = []) ->
    escapedTags = []

    if escapedText && escapedText.length && tagTypes.length
      parts = []

      # NEW_STACK - Add condition for new stack
      parts.push(@railsRegexp().source)   if _.includes(tagTypes, 'rails')
      parts.push(@laravelRegexp().source) if _.includes(tagTypes, 'laravel')
      parts.push(@linguiRegexp().source)  if _.includes(tagTypes, 'lingui')
      parts.push(@angularRegexp().source) if _.includes(tagTypes, 'angular')
      parts.push(@htmlRegexp().source)    if _.includes(tagTypes, 'html')

      regexp = new RegExp(parts.join('|'), 'ig')

      escapedTags = escapedText.match(regexp) || []

    escapedTags

  @extractUnescapedTags: (escapedText, tagTypes = []) ->
    _.map(@extractEscapedTags(escapedText, tagTypes), (tag) => _.unescape(tag))

  @hasWarning: (sourceText, targetText, tagTypes) ->
    sourceTags = @extractUnescapedTags(_.escape(sourceText), tagTypes)
    targetTags = @extractUnescapedTags(_.escape(targetText), tagTypes)

    @hasMissingTags(sourceTags, targetTags)

  # Can't use _.difference because _.difference([1, 1, 2], [1]) == [2] and we want [1, 2]
  @hasMissingTags: (sourceTags, targetTags) ->
    missing = false

    # Try to find a missing target tag
    for sourceTag, i in sourceTags
      index = _.findIndex(targetTags, (targetTag) => targetTag == sourceTag)

      if index >= 0
        targetTags[index] = null # | Pair found! Remove it from lists
        sourceTags[i]     = null # /
      else
        missing = true
        break

    #  |
    #  |    Uncomment to also check missing source tags
    # \ /   (is it a problem if target has an extra tag?)
    #  v

    # return missing if missing # for optimization

    # # Will remove all already verified pairs
    # targetTags = _.compact(targetTags)
    # sourceTags = _.compact(sourceTags)

    # # If not missing tag found, try to find a missing source tag (we don't want extra target tag)
    # for targetTag, i in targetTags
    #   index = _.findIndex(sourceTags, (sourceTag) => sourceTag == targetTag)

    #   if index >= 0
    #     sourceTags[index] = null # | Pair found! Remove it from lists
    #     targetTags[i]     = null # /
    #   else
    #     missing = true
    #     break

    #  ^
    # / \
    #  |
    #  |

    return missing
