import onClickOutside from 'react-onclickoutside'

import Colors from './helpers/Colors'

class Search extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      query:               @props.filters.query
      filtersDropdownOpen: false
      keysDropdownOpen:    false
      hoveredKeyPrefix:    ''
    }

    @dSetURL         = _.debounce(@props.setURL, 200)
    @dHoverKeyPrefix = _.debounce(@hoverKeyPrefix, 40)

    @toggleSelectAll        = @toggleSelectAll.bind(this)
    @toggleFiltersDropdown  = @toggleFiltersDropdown.bind(this)
    @closeDropdowns         = @closeDropdowns.bind(this)
    @updateQuery            = @updateQuery.bind(this)
    @toggleKeysDropdown     = @toggleKeysDropdown.bind(this)
    @updateOnlyUntranslated = @updateOnlyUntranslated.bind(this)
    @updateOnlyGettext      = @updateOnlyGettext.bind(this)
    @updateOnlyYaml         = @updateOnlyYaml.bind(this)
    @updateOnlyWarning      = @updateOnlyWarning.bind(this)
    @clearQuery             = @clearQuery.bind(this)

  componentDidMount: ->
    @refs.searchInput.focus()

  componentDidUpdate: (prevProps, prevState, snapshot) ->
    if @props.filters.query != prevProps.filters.query # if search changes in URL (ex: back button)
      @setState(query: @props.filters.query)

  searchButtonClasses: ->
    classes = 'btn actions actions-search'
    filters = @props.filters

    if filters.onlyUntranslated || filters.onlyGettext || filters.onlyYaml || filters.tagIds.length
      classes += ' selected'

    classes

  # Move the last column at the right place (next to the hovered key prefix)
  placeLastKeyColumn: ->
    lastHoveredKey = $('.keys-tree .keys-column li.hover:last')[0]

    if lastHoveredKey
      lastColumnIndex = @state.hoveredKeyPrefix.split('.').length - 1
      lastColumnDiv   = @refs["keysColumn#{lastColumnIndex}"]
      lastColumnUl    = @refs["keysColumnUl#{lastColumnIndex}"]

      # Move last column vertical position to last hovered key
      keyTreeY                = @refs.keysTree.getBoundingClientRect().top # position of tree             (like 124px)
      lastHoveredKeyY         = lastHoveredKey.getBoundingClientRect().top # position of last hovered key (lower, like 176px)
      lastColumnDiv.style.top = "#{lastHoveredKeyY - keyTreeY - 1}px"

      # Define max height to not be lower that the first/previous column
      firstColumnHeight            = 0.7 * window.innerHeight # Also need to update show.scss (70vh) (don't use size of first column since it can be smaller than maxHeight)
      delta                        = 50.0 * lastColumnIndex   # each column can go a little bit lower
      maxHeight                    = firstColumnHeight - (lastHoveredKeyY - keyTreeY) + 1 + delta
      lastColumnUl.style.maxHeight = "#{maxHeight}px"

      @updateScrollColumn(lastColumnIndex)

  updateQuery: (e) ->
    @setState(
      query:               e.target.value
      filtersDropdownOpen: false # dropdown may be open when focus in on input
      keysDropdownOpen:    false # dropdown may be open when focus in on input
    )

    newFilters = Object.assign({}, @props.filters, {
      query: e.target.value
    })

    @dSetURL(newFilters, undefined)
    return

  updateQueryWithKey: (key, e) ->
    e.preventDefault()

    @setState(query: key)

    newFilters = Object.assign({}, @props.filters, {
      query: key
    })

    @refs.searchInput.focus()

    @dSetURL(newFilters, undefined)
    return

  hoverKeyPrefix: (key) ->
    @setState(hoveredKeyPrefix: key, ->
      if _.endsWith(@state.hoveredKeyPrefix, '.') # Only if new hovered key has sub keys, and just opened a new column
        @placeLastKeyColumn()
    )

  updateScrollColumn: (level) ->
    columnDiv = @refs["keysColumn#{level}"]
    columnUl  = @refs["keysColumnUl#{level}"]

    if columnUl.scrollHeight > columnUl.clientHeight # Column is scrollable
      if $(columnUl).scrollTop() == 0 # -> Scroll is at top
        $(columnDiv).removeClass('shadow-top')
        $(columnDiv).addClass('shadow-bottom')
      else if Math.abs(columnUl.scrollHeight - columnUl.scrollTop - columnUl.clientHeight) <= 1.0 # -> Scroll is at bottom (https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom-not-just-the-window-but-any-element#comment92747215_34550171)
        $(columnDiv).removeClass('shadow-bottom')
        $(columnDiv).addClass('shadow-top')
      else                            # -> Scroll is in the middle
        $(columnDiv).addClass('shadow-top')
        $(columnDiv).addClass('shadow-bottom')
    else
      $(columnDiv).removeClass('shadow-top')
      $(columnDiv).removeClass('shadow-bottom')

  clearQuery: () ->
    @setState(query: '')

    newFilters = Object.assign({}, @props.filters, {
      query: ''
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  updateOnlyUntranslated: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyUntranslated

    newFilters = Object.assign({}, @props.filters, {
      onlyUntranslated: newValue
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  updateOnlyGettext: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyGettext

    newFilters = Object.assign({}, @props.filters, {
      onlyGettext: newValue
      onlyYaml:    false
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  updateOnlyYaml: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyYaml

    newFilters = Object.assign({}, @props.filters, {
      onlyYaml:    newValue
      onlyGettext: false
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  updateOnlyWarning: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyWarning

    newFilters = Object.assign({}, @props.filters, {
      onlyWarning: newValue
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  updateURLTagIds: (tag, e) ->
    e.preventDefault()

    tagIds = @props.filters.tagIds

    if @isTagChecked(tag)
      index = _.findIndex(tagIds, (tagId) => tagId == tag.id)
      newTagIds = update(tagIds, { $splice: [[ index, 1 ]] })
    else
      newTagIds = update(tagIds, { $push: [tag.id] })

    newFilters = Object.assign({}, @props.filters, {
      tagIds: newTagIds
    })

    @refs.searchInput.focus()

    @props.setURL(newFilters, undefined)
    return

  isTagChecked: (tag) ->
    _.includes(@props.filters.tagIds, tag.id)

  iconClassesOfSelectAll: ->
    if @props.selectedSegmentsCount == @props.segmentsCount
      'far fa-square-check'
    else if @props.selectedSegmentsCount == 1 # active segment is always selected, so it means no selection!
      'far fa-square'
    else
      'far fa-square-minus'

  toggleSelectAll: ->
    @closeDropdowns()

    if @props.selectedSegmentsCount == 1 # active segment is always selected, so it means no selection!
      @props.selectAllSegments()
    else
      @props.unselectAllSegments()

  toggleFiltersDropdown: ->
    @setState(
      filtersDropdownOpen: !@state.filtersDropdownOpen
      keysDropdownOpen:    false
    )

  toggleKeysDropdown: ->
    @setState({
        keysDropdownOpen:    !@state.keysDropdownOpen
        filtersDropdownOpen: false
        hoveredKeyPrefix:    ''
    }, =>
      if @state.keysDropdownOpen
        @updateScrollColumn(0) # correctly display scroll shadows on first column
    )

  closeDropdowns: ->
    if @state.filtersDropdownOpen || @state.keysDropdownOpen
      @setState(
        filtersDropdownOpen: false
        keysDropdownOpen:    false
        hoveredKeyPrefix:    ''
      )

  handleClickOutside: ->
    @closeDropdowns()

  activeTags: ->
    _.filter(@props.tags, (tag) -> tag.active)

  render: ->
    checkboxClasses = "select-all"
    checkboxClasses += " d-none" if @props.segmentsCount == 0

    <div className="search-menu">
      <div className={checkboxClasses} onClick={@toggleSelectAll}>
        <i className={@iconClassesOfSelectAll()}></i>
      </div>
      <div className="input-group">
        <div>
          <button type="button" className={@searchButtonClasses()} onClick={@toggleFiltersDropdown}>
            <i className="fas fa-caret-down"></i>
            Filters
            {@renderCountBadge()}
          </button>

          {@renderKeysSearch()}
          {@renderFiltersDropdown()}
        </div>

        <i className="fas fa-magnifying-glass"></i>
        {@renderSearchSpinner()}
        {@renderClearIcon()}
        <input ref="searchInput"
               type="search"
               className="form-control filter-search"
               placeholder="Search (key, source or target)"
               dir={if @props.targetLanguage.rightToLeft then 'auto' else undefined}
               value={@state.query}
               onChange={@updateQuery}
               onClick={@closeDropdowns} />
      </div>
    </div>

  renderKeysSearch: ->
    classes = "btn btn-default actions actions-keys"
    classes += " selected" if @state.keysDropdownOpen

    if @state.query == '' && @props.keysTree.count
      <div className={classes} onClick={@toggleKeysDropdown}>
        <i className="fas fa-key" title="YAML key prefix selector"></i>

        {@renderKeysTree() if @state.keysDropdownOpen}
      </div>

  renderFiltersDropdown: ->
    if @state.filtersDropdownOpen
      onlyUntranslatedEnabled =  onlyGettextEnabled = onlyYamlEnabled = onlyWarningEnabled = 'far fa-square-check'
      onlyUntranslatedEnabled += ' d-none' if !@props.filters.onlyUntranslated
      onlyGettextEnabled      += ' d-none' if !@props.filters.onlyGettext
      onlyYamlEnabled         += ' d-none' if !@props.filters.onlyYaml
      onlyWarningEnabled      += ' d-none' if !@props.filters.onlyWarning

      onlyUntranslatedDisabled =  onlyGettextDisabled = onlyYamlDisabled = onlyWarningDisabled = 'far fa-square'
      onlyUntranslatedDisabled += ' d-none' if @props.filters.onlyUntranslated
      onlyGettextDisabled      += ' d-none' if @props.filters.onlyGettext
      onlyYamlDisabled         += ' d-none' if @props.filters.onlyYaml
      onlyWarningDisabled      += ' d-none' if @props.filters.onlyWarning

      # No kind filter if only one kind!
      keySourceFiltersClasses = ''
      keySourceFiltersClasses += ' d-none' if @props.hideSegmentKindFilter

      warningFiltersClasses = ''
      warningFiltersClasses += ' d-none' if !@props.showWarningFilter

      # NEW_STACK - Personnalize text for keys/strings
      # Don't forget to adapt renderEmpty() in Segments
      if @props.stack == 'rails'
        onlyKeyText    = 'YAML keys'
        onlySourceText = 'GetText strings'
      else if @props.stack == 'laravel'
        onlyKeyText    = 'PHP keys'
        onlySourceText = 'JSON/GetText strings'
      else # lingui/angular (not visible since only source for now!)
        onlyKeyText    = 'Id strings'
        onlySourceText = 'Source strings'

      <ul className="dropdown-menu">
        <li>
          <a href="#" onClick={@updateOnlyUntranslated} className="dropdown-item">
            <label>
              <i className={onlyUntranslatedEnabled}></i>
              <i className={onlyUntranslatedDisabled}></i>
               Untranslated
            </label>
          </a>
        </li>

        <li className={keySourceFiltersClasses}><hr className="dropdown-divider"/></li>

        <li className={keySourceFiltersClasses}>
          <a href="#" onClick={@updateOnlyYaml} className="dropdown-item">
            <label>
              <i className={onlyYamlEnabled}></i>
              <i className={onlyYamlDisabled}></i>
              { onlyKeyText }
            </label>
          </a>
        </li>

        <li className={keySourceFiltersClasses}>
          <a href="#" onClick={@updateOnlyGettext} className="dropdown-item">
            <label>
              <i className={onlyGettextEnabled}></i>
              <i className={onlyGettextDisabled}></i>
              { onlySourceText }
            </label>
          </a>
        </li>

        <li className={warningFiltersClasses}><hr className="dropdown-divider"/></li>

        <li className={warningFiltersClasses}>
          <a href="#" onClick={@updateOnlyWarning} className="dropdown-item">
            <label>
              <i className={onlyWarningEnabled}></i>
              <i className={onlyWarningDisabled}></i>
              Warnings
            </label>
          </a>
        </li>

        {@renderTagsDivider()}
        {@renderTags()}
      </ul>
    else
      ''

  renderTagsDivider: ->
    if @activeTags().length
      <li><hr className="dropdown-divider"/></li>

  renderTags: ->
    _.map(@activeTags(), (tag, index) =>
      selected = @isTagChecked(tag)

      checkClass =  'fas fa-square'
      checkClass += '-check' if selected

      darkerColor  = if selected then Colors.shade(-0.30, tag.color) else undefined
      lighterColor = if selected then Colors.shade( 0.85, tag.color) else undefined

      <li key={tag.id} style={{ backgroundColor: lighterColor }}>
        <a href="#" className="dropdown-item tag" onClick={ @updateURLTagIds.bind(this, tag) }>
          <label style={{ color: darkerColor }}>
            <i className={checkClass} style={{ color: tag.color }}></i>
            { tag.name }
          </label>
        </a>
      </li>
    )

  renderCountBadge: ->
    <span className="badge rounded-pill">
      {@renderCount()}
      {@renderEsScrollSpinner()}
    </span>

  renderCount: ->
    # don't show count when loading first batch of segments AND scrollingLoading is visible (= not for websockets)
    if @props.searchLoading && @props.esScrollLoading
      ''
    else
      <span>{@props.segmentsCount}</span>

  renderEsScrollSpinner: ->
    if @props.esScrollLoading # show spinner until the last batch of ES segments is loaded
      <i className="fas fa-spinner fa-spin"></i>

  renderSearchSpinner: ->
    if @props.searchLoading
      <i className="fas fa-spinner fa-spin fa-lg"></i>

  renderClearIcon: ->
    if _.trim(@state.query).length && !@props.searchLoading
      <i className="fas fa-xmark"
         onClick={@clearQuery}
         title="Clear search">
      </i>

  renderKeysTree: ->
    keyParts = @state.hoveredKeyPrefix.split('.') # 'validation.between.' has 3 dropdown columns and "validation" and "between" are opened

    currentKeyPrefix  = ''
    currentKeySubTree = @props.keysTree

    <div className="keys-tree" ref="keysTree">
      {
        _.map(keyParts, (part, index) =>
          column = @renderKeysTreeColumn(index, currentKeyPrefix, currentKeySubTree.children)

          if index < keyParts.length
            currentKeyPrefix = "#{currentKeyPrefix}#{part}."
            currentKeySubTree   = currentKeySubTree.children[part]

          column
        )
      }
    </div>

  renderKeysTreeColumn: (level, prefix, keysSubTree) ->
    <div className="keys-column keys-column-#{level}"
         key={level}
         ref="keysColumn#{level}"
         style={{ left: -1 + level*140 }}>
      <ul ref="keysColumnUl#{level}"
          onScroll={@updateScrollColumn.bind(this, level)}>
        {
          _.map(Object.keys(keysSubTree), (key, index) =>
            hasSubKeys     = Object.keys(keysSubTree[key].children).length
            searchKey      = "#{prefix}#{key}#{if hasSubKeys then '.' else ''}"
            displaySubKeys = _.isEqual(_.take(searchKey.split('.'), level+1), _.take(@state.hoveredKeyPrefix.split('.'), level+1))

            liClass = if hasSubKeys then "parent " else ""
            liClass += 'hover' if displaySubKeys

            count = if keysSubTree[key].count > 1 then " (#{keysSubTree[key].count})" else ''
            name  = "#{key}#{count}"

            <li key={searchKey} className={liClass}>
              <a href="#" title={name}
                          onClick={@updateQueryWithKey.bind(this, searchKey)}
                          onMouseOver={@dHoverKeyPrefix.bind(this, searchKey)}>
                {name}
              </a>
              <i className="fas fa-caret-right"></i>
            </li>
          )
        }
      </ul>
    </div>

export default onClickOutside(Search)
