import Colors from './helpers/Colors'

class FacetedSearch extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {}

    @toggleUntranslated = @toggleUntranslated.bind(this)
    @toggleTranslated   = @toggleTranslated.bind(this)
    @toggleValidated    = @toggleValidated.bind(this)
    @toggleKey          = @toggleKey.bind(this)
    @toggleSource       = @toggleSource.bind(this)
    @toggleWarning      = @toggleWarning.bind(this)
    @togglePlural       = @togglePlural.bind(this)

  toggleUntranslated: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyUntranslated

    newFilters = Object.assign({}, @props.filters, {
      onlyUntranslated: newValue
      onlyTranslated:   false    # mutually exclusive
      onlyValidated:    false    # mutually exclusive
    })

    @props.setURL(newFilters)
    return

  toggleTranslated: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyTranslated

    newFilters = Object.assign({}, @props.filters, {
      onlyUntranslated: false    # mutually exclusive
      onlyTranslated:   newValue
      onlyValidated:    false    # mutually exclusive
    })

    @props.setURL(newFilters)
    return

  toggleValidated: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyValidated

    newFilters = Object.assign({}, @props.filters, {
      onlyUntranslated: false    # mutually exclusive
      onlyTranslated:   false    # mutually exclusive
      onlyValidated:    newValue
    })

    @props.setURL(newFilters)
    return

  toggleKey: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyYaml

    newFilters = Object.assign({}, @props.filters, {
      onlyYaml:    newValue
      onlyGettext: false # mutually exclusive
    })

    @props.setURL(newFilters)
    return

  toggleSource: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyGettext

    newFilters = Object.assign({}, @props.filters, {
      onlyGettext: newValue
      onlyYaml:    false # mutually exclusive
    })

    @props.setURL(newFilters)
    return

  toggleWarning: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyWarning

    newFilters = Object.assign({}, @props.filters, {
      onlyWarning: newValue
    })

    @props.setURL(newFilters)
    return

  togglePlural: (e) ->
    e.preventDefault()

    newValue = !@props.filters.onlyPlural

    newFilters = Object.assign({}, @props.filters, {
      onlyPlural: newValue
    })

    @props.setURL(newFilters)
    return

  updateTagIds: (tag, e) ->
    e.preventDefault()

    tagIds = @props.filters.tagIds

    if @isTagChecked(tag)
      index = _.findIndex(tagIds, (tagId) => tagId == tag.id)
      newTagIds = update(tagIds, { $splice: [[ index, 1 ]] })
    else
      newTagIds = update(tagIds, { $push: [tag.id] })

    newFilters = Object.assign({}, @props.filters, {
      tagIds: newTagIds
    })

    @props.setURL(newFilters)
    return

  activeTags: (tag) ->
    _.filter(@props.tags, (tag) -> tag.active)

  isTagChecked: (tag) ->
    _.includes(@props.filters.tagIds, tag.id)

  computeFacetCounts: ->
    # Initialize count
    facetCounts =
      untranslated: 0
      translated:   0
      validated:    0
      key:          0
      source:       0
      warning:      0
      plural:       0
      tags:         {} # { id: count, ... }

    @props.tags.forEach((tag) ->
      facetCounts.tags[tag.id] ||= 0
    )

    # Loop only 1 time
    @props.segments.forEach((segment) ->
      facetCounts['untranslated'] += 1 if !segment.translated
      facetCounts['translated']   += 1 if segment.translated && !segment.validated
      facetCounts['validated']    += 1 if segment.validated
      facetCounts['key']          += 1 if segment.kind == 'yaml'
      facetCounts['source']       += 1 if segment.kind == 'gettext'
      facetCounts['warning']      += 1 if segment.warning && !segment.ignoredWarning
      facetCounts['plural']       += 1 if segment.pluralType != 'singular'

      segment.tagIds.forEach((tagId) ->
        facetCounts.tags[tagId] ||= 0 # just in case (websockets inconsistencies etc.)
        facetCounts.tags[tagId] += 1
      )
    )

    facetCounts

  render: ->
    # Avoid heavy recomputing when loading start, it will change anyway when new segments are loaded
    if !@props.searchLoading
      @facetCounts = @computeFacetCounts()

    # Classes to show/hide facets
    untranslatedFacetClasses = if @facetCounts?.untranslated then 'facet' else 'facet facet-disabled'
    translatedFacetClasses   = if @facetCounts?.translated   then 'facet' else 'facet facet-disabled'
    validatedFacetClasses    = if @facetCounts?.validated    then 'facet' else 'facet facet-disabled'
    keyFacetClasses          = if @facetCounts?.key          then 'facet' else 'facet facet-disabled'
    sourceFacetClasses       = if @facetCounts?.source       then 'facet' else 'facet facet-disabled'
    warningFacetClasses      = if @facetCounts?.warning      then 'facet' else 'facet facet-disabled'
    pluralFacetClasses       = if @facetCounts?.plural       then 'facet' else 'facet facet-disabled'

    # Classes to check/uncheck boxes
    untranslatedCheckClasses = if @props.filters.onlyUntranslated then 'far fa-square-check' else 'far fa-square'
    translatedCheckClasses   = if @props.filters.onlyTranslated   then 'far fa-square-check' else 'far fa-square'
    validatedCheckClasses    = if @props.filters.onlyValidated    then 'far fa-square-check' else 'far fa-square'
    keyCheckClasses          = if @props.filters.onlyYaml         then 'far fa-square-check' else 'far fa-square'
    sourceCheckClasses       = if @props.filters.onlyGettext      then 'far fa-square-check' else 'far fa-square'
    warningCheckClasses      = if @props.filters.onlyWarning      then 'far fa-square-check' else 'far fa-square'
    pluralCheckClasses       = if @props.filters.onlyPlural       then 'far fa-square-check' else 'far fa-square'

    # NEW_STACK - Personnalize text for keys/strings
    # Don't forget to adapt renderEmpty() in Segments
    if @props.stack == 'rails'
      onlyKeyText    = 'YAML keys'
      onlySourceText = 'GetText strings'
    else if @props.stack == 'laravel'
      onlyKeyText    = 'PHP keys'
      onlySourceText = 'JSON/GetText strings'
    else # lingui/angular (not visible since only source for now!)
      onlyKeyText    = 'Id strings'
      onlySourceText = 'Source strings'

    <div className="faceted-search">
      <a href="#" onClick={@toggleUntranslated.bind(this)} className={untranslatedFacetClasses}>
        <label>
          <i className={untranslatedCheckClasses}></i>
          Untranslated <span className="badge badge-info">{@facetCounts?.untranslated}</span>
        </label>
      </a>
      <a href="#" onClick={@toggleTranslated.bind(this)} className={translatedFacetClasses}>
        <label>
          <i className={translatedCheckClasses}></i>
          Translated <span className="badge badge-info">{@facetCounts?.translated}</span>
        </label>
      </a>
      <a href="#" onClick={@toggleValidated.bind(this)} className={validatedFacetClasses}>
        <label>
          <i className={validatedCheckClasses}></i>
          Validated <span className="badge badge-info">{@facetCounts?.validated}</span>
        </label>
      </a>
      <hr/>
      <a href="#" onClick={@toggleKey.bind(this)} className={keyFacetClasses}>
        <label>
          <i className={keyCheckClasses}></i>
          { onlyKeyText } <span className="badge badge-info">{@facetCounts?.key}</span>
        </label>
      </a>
      <a href="#" onClick={@toggleSource.bind(this)} className={sourceFacetClasses}>
        <label>
          <i className={sourceCheckClasses}></i>
          { onlySourceText } <span className="badge badge-info">{@facetCounts?.source}</span>
        </label>
      </a>
      <hr/>
      <a href="#" onClick={@toggleWarning.bind(this)} className={warningFacetClasses}>
        <label>
          <i className={warningCheckClasses}></i>
          Warning <span className="badge badge-info">{@facetCounts?.warning}</span>
        </label>
      </a>
      <a href="#" onClick={@togglePlural.bind(this)} className={pluralFacetClasses}>
        <label>
          <i className={pluralCheckClasses}></i>
          Plural <span className="badge badge-info">{@facetCounts?.plural}</span>
        </label>
      </a>
      <hr/>
      { @renderTags() }
    </div>

  renderTags: ->
    _.map(@activeTags(), (tag, index) =>
      selected = @isTagChecked(tag)

      facetClasses = 'facet'
      facetClasses += ' facet-disabled' if !@facetCounts?.tags?[tag.id]

      checkClasses =  'fas fa-check'
      checkClasses += ' d-none' if !selected

      darkerColor  = if selected then Colors.shade(-0.30, tag.color) else undefined
      lighterColor = if selected then Colors.shade( 0.85, tag.color) else undefined

      <a href="#" key={tag.id} onClick={@updateTagIds.bind(this, tag)} className={facetClasses}>
        <label className="tag"
               style={{ color: darkerColor }}>
          <span className="color"
                style={{ backgroundColor: tag.color }}>
            <i className={checkClasses} style={{ color: darkerColor }}></i>
          </span>

          { tag.name } <span className="badge badge-info">{@facetCounts?.tags?[tag.id]}</span>
        </label>
      </a>
    )

export default FacetedSearch
