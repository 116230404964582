import { Virtuoso } from 'react-virtuoso'

import Colors  from './helpers/Colors'
import Segment from './segments/Segment'

export default class Segments extends React.PureComponent

  constructor: (props) ->
    super(props)

    @checkConnection                    = @checkConnection.bind(this)
    @addToSelectedSegments              = @addToSelectedSegments.bind(this)
    @removeFromSelectedSegments         = @removeFromSelectedSegments.bind(this)
    @addRangeToSelectedSegments         = @addRangeToSelectedSegments.bind(this)
    @isAdjacentToActiveSegmentSelection = @isAdjacentToActiveSegmentSelection.bind(this)
    @moveSelectionRangeDown             = @moveSelectionRangeDown.bind(this)
    @moveSelectionRangeUp               = @moveSelectionRangeUp.bind(this)
    @selectAllSegmentIfPossible         = @selectAllSegmentIfPossible.bind(this)

  componentDidMount: ->
    setInterval(@checkConnection, 10000)

    @bindShortcuts()

  componentWillUnmount: ->
    @unbindShortcuts()

  bindShortcuts: ->
    # cmd-a on MacOS and ctrl-a on Win/Linux are the default behaviors
    ctrlModifier      = if _.includes(navigator.platform, 'Mac') then       'meta' else 'ctrl'
    ctrlShiftModifier = if _.includes(navigator.platform, 'Mac') then 'shift+meta' else 'ctrl+shift'

    # Select all segments
    $(document).on('keydown', null, "#{ctrlModifier}+a", @selectAllSegmentIfPossible)

    # Increase the range up and down (when not in a textarea)
    $(document).on('keydown', null, "shift+down", @moveSelectionRangeDown)
    $(document).on('keydown', null, "shift+up",   @moveSelectionRangeUp)

    # Increase the range up and down (with extra key for when in a textarea to not disturb text selection)
    selectors = 'body, textarea.target, input.filter-search'
    $(document).on('keydown', selectors, "#{ctrlShiftModifier}+down", @moveSelectionRangeDown)
    $(document).on('keydown', selectors, "#{ctrlShiftModifier}+up",   @moveSelectionRangeUp)

  unbindShortcuts: ->
    # Select all segments
    $(document).off('keydown', @selectAllSegmentIfPossible)

    # Increase the range up and down (when not in a textarea)
    $(document).off('keydown', @moveSelectionRangeDown)
    $(document).off('keydown', @moveSelectionRangeUp)

    # Increase the range up and down (with extra key for when in a textarea to not disturb text selection)
    $(document).off('keydown', @moveSelectionRangeDown)
    $(document).off('keydown', @moveSelectionRangeUp)

  activeSegment: ->
    @props.segments[@props.activeSegmentIndex]

  checkConnection: ->
    dirtyCount = _.keys(@props.updatedMsgstrs).length
    $('#modal-offline').modal('show') if dirtyCount >= 5

  addToSelectedSegments: (segmentId) ->
    if !_.includes(@props.selectedSegmentIds, segmentId)
      newSelectedSegmentIds = _.clone(@props.selectedSegmentIds)
      positionToInsert      = _.sortedIndex(@props.selectedSegmentIds, segmentId)

      newSelectedSegmentIds.splice(positionToInsert, 0, segmentId)

      @props.replaceSelectedSegments(newSelectedSegmentIds)

  removeFromSelectedSegments: (segmentId) ->
    activeSegmentIsTheOnlySelected = @props.selectedSegmentIds.length == 1

    if _.includes(@props.selectedSegmentIds, segmentId) && !activeSegmentIsTheOnlySelected
      newSelectedSegmentIds = _.clone(@props.selectedSegmentIds)
      positionToRemove      = _.sortedIndex(@props.selectedSegmentIds, segmentId)

      newSelectedSegmentIds.splice(positionToRemove, 1)

      @props.replaceSelectedSegments(newSelectedSegmentIds)

      # We *never* want an active segment to not be part of the selection.
      # So we make the last segment from the selection active!
      if @activeSegment().id == segmentId
        segmentIdToActivate  = _.last(newSelectedSegmentIds)
        segmentIndexToActive = @props.segmentsIndexPerId[segmentIdToActivate]
        segmentToActivate    = @props.segments[segmentIndexToActive]
        @props.setURLWithSameFilters(segmentToActivate)

  # Add range to selection but also unselect previous range if any (like MacOS Finder)
  # => See `selectedSegmentIdsWithoutPreviousRange` for more info
  addRangeToSelectedSegments: (targetSegmentIndex) ->
    rangeSegmentIds = []

    for index in [@props.activeSegmentIndex..targetSegmentIndex]
      rangeSegmentIds.push(@props.segments[index].id) if @props.segments[index]

    @props.replaceSelectedSegments(
      _.union(@selectedSegmentIdsWithoutPreviousRange(), rangeSegmentIds)
    )

  # selectedSegmentIds without previous range if any (same behaviour than MacOS Finder)
  # (previous range is between active segment and any adjacent selected segments in both directions)
  selectedSegmentIdsWithoutPreviousRange: ->
    segmentIdsToUnselect = []

    # Unselect continuous selected segments (direction to bottom)
    currentIndex = @props.activeSegmentIndex + 1
    while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
      segmentIdsToUnselect.push(@props.segments[currentIndex].id)
      currentIndex += 1

    # Unselect continuous selected segments (direction to top)
    currentIndex = @props.activeSegmentIndex - 1
    while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
      segmentIdsToUnselect.push(@props.segments[currentIndex].id)
      currentIndex -= 1

    # Returns new array
    _.difference(@props.selectedSegmentIds, segmentIdsToUnselect)

  isAdjacentToActiveSegmentSelection: (segmentId) ->
    adjacentIds = []

    # Find adjacent bottom segment id
    currentIndex = @props.activeSegmentIndex + 1
    currentIndex += 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
    adjacentIds.push(@props.segments[currentIndex]?.id)

    # Find adjacent top segment id
    currentIndex = @props.activeSegmentIndex - 1
    currentIndex -= 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
    adjacentIds.push(@props.segments[currentIndex]?.id)

    _.includes(adjacentIds, segmentId)

  moveSelectionRangeDown: (event) ->
    if !@props.fullScreen
      event.preventDefault()

      # Reducing top selection (if segment above active is already selected)
      previousIndex = @props.activeSegmentIndex - 1
      if _.includes(@props.selectedSegmentIds, @props.segments[previousIndex]?.id)
        currentIndex = previousIndex
        currentIndex -= 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
        currentIndex = currentIndex + 2
      else # Increasing bottom selection (default)
        currentIndex = @props.activeSegmentIndex + 1
        currentIndex += 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)

      @addRangeToSelectedSegments(currentIndex)
      @props.scrollToIndex(currentIndex)

  moveSelectionRangeUp: (event) ->
    if !@props.fullScreen
      event.preventDefault()

      # Reducing bottom selection (if segment below active is already selected)
      nextIndex = @props.activeSegmentIndex + 1
      if _.includes(@props.selectedSegmentIds, @props.segments[nextIndex]?.id)
        currentIndex = nextIndex
        currentIndex += 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)
        currentIndex = currentIndex - 2
      else # Increasing top selection
        currentIndex = @props.activeSegmentIndex - 1
        currentIndex -= 1 while _.includes(@props.selectedSegmentIds, @props.segments[currentIndex]?.id)

      @addRangeToSelectedSegments(currentIndex)
      @props.scrollToIndex(currentIndex)

  selectAllSegmentIfPossible: (event) ->
    if !$('.modal-backdrop').length && !@props.fullScreen
      event.preventDefault()
      @props.selectAllSegments()

  render: ->
    containerClasses =  "segments-container"
    containerClasses += " segments-container-loading" if @props.disabled

    if @props.segments.length
      <div className={containerClasses}>
        { @renderList() }
        { @renderLoadingOverlay() }
      </div>
    else
      <div className={containerClasses}>
        { @renderEmpty() }
        { @renderLoadingOverlay() }
      </div>

  renderEmpty: ->
    queryClasses        = if @props.filters.query != ''      then '' else 'd-none'
    untranslatedClasses = if @props.filters.onlyUntranslated then '' else 'd-none'
    translatedClasses   = if @props.filters.onlyTranslated   then '' else 'd-none'
    validatedClasses    = if @props.filters.onlyValidated    then '' else 'd-none'
    yamlClasses         = if @props.filters.onlyYaml         then '' else 'd-none'
    gettextClasses      = if @props.filters.onlyGettext      then '' else 'd-none'
    warningClasses      = if @props.filters.onlyWarning      then '' else 'd-none'
    pluralClasses       = if @props.filters.onlyPlural       then '' else 'd-none'
    tagsClasses         = if @props.filters.tagIds.length    then '' else 'd-none'

    tagsArray = @props.tags.filter((tag) => @props.filters.tagIds.includes(tag.id))
                           .map((tag) =>
      lighterColor = Colors.shade( 0.85, tag.color)
      darkerColor  = Colors.shade( -0.3, tag.color)

      <span key={tag.id}>
        {' '}
        <em style={{ backgroundColor: lighterColor, borderColor: darkerColor }}>
          {tag.name}
        </em>
      </span>
    )

    tagText = if tagsArray.length > 1 then 'tags' else 'tag'

    # NEW_STACK - Personnalize text for keys/strings
    # Don't forget to adapt renderFiltersDropdown() in Search.coffee
    if @props.stack == 'rails'
      keyText    = 'YAML'
      sourceText = 'GetText'
    else if @props.stack == 'laravel'
      keyText    = 'PHP'
      sourceText = 'JSON/GetText'
    else # lingui/angular (not visible since only source for now!)
      keyText    = 'Id'
      sourceText = 'Source'

    <div className="segments">
      <div className="empty">
        No
        <span className={untranslatedClasses}> <em>untranslated</em></span>
        <span className={translatedClasses}> <em>translated</em></span>
        <span className={validatedClasses}> <em>validated</em></span>
        <span className={pluralClasses}> <em>plural</em></span>
        <span className={yamlClasses}> <em>{keyText}</em></span>
        <span className={gettextClasses}> <em>{sourceText}</em></span>
        <span> segments</span>
        <span className={warningClasses}> with a <em>warning</em></span>
        <span> found</span>
        <span className={queryClasses}> <span>for the filter</span> <em className="query">“{@props.filters.query}”</em></span>
        <span className={tagsClasses}> with the {tagText}{tagsArray}</span>.
      </div>
    </div>

  renderList: ->
    divClasses =  'segments '
    divClasses += if @props.selectedSegmentIds.length > 1 then 'active-selection ' else ''

    # While fixedItemHeight is not needed, it slightly improves perfs!
    <Virtuoso className={divClasses}
              fixedItemHeight={@props.rowHeight}
              totalCount={@props.segments.length}
              itemContent={@renderSegment.bind(this)} />

  renderLoadingOverlay: ->
    <div className="loading-overlay">
    </div>

  renderSegment: (index) ->
    isDirty    = false
    segment    = @props.segments[index]
    newMsgstrs = @props.updatedMsgstrs[segment.id]

    # Reflect current changes on this segment if any
    if newMsgstrs
      oldMsgstrs = _.pick(segment, _.keys(newMsgstrs))

      if !_.isEqual(oldMsgstrs, newMsgstrs)
        isDirty = true
        segment = Object.assign({}, segment, newMsgstrs)

    isActive    = index == @props.activeSegmentIndex
    isSelected  = _.includes(@props.selectedSegmentIds, segment.id)
    last        = index == @props.segments.length - 1
    pluralType  = segment.pluralType
    icuSegment  = if pluralType == 'icu_plural' then (new ICU(segment.msgid, segment.msgstr, @props.targetLanguage.pluralCases)) else undefined

    ignoreStackHighlights = pluralType == 'singular' && ICU.isIcuString(segment.msgid)

    <Segment key={segment.id}
             segment={segment}
             index={index}
             dirty={isDirty}
             active={isActive}
             selected={isSelected}
             last={last}
             pluralType={pluralType}
             ignoreStackHighlights={ignoreStackHighlights}
             icuSegment={icuSegment}
             selectedSegmentsCount={@props.selectedSegmentIds.length}
             nPlurals={@props.targetLanguage.nPlurals}
             rightToLeft={@props.targetLanguage.rightToLeft}
             stack={@props.stack}
             query={@props.filters.query}
             tags={@props.tags}
             setURLWithSameFilters={@props.setURLWithSameFilters}
             updateSegment={@props.updateSegment}
             replaceSelectedSegments={@props.replaceSelectedSegments}
             addToSelectedSegments={@addToSelectedSegments}
             removeFromSelectedSegments={@removeFromSelectedSegments}
             addRangeToSelectedSegments={@addRangeToSelectedSegments}
             isAdjacentToActiveSegmentSelection={@isAdjacentToActiveSegmentSelection}
             setTags={@props.setTags}
             focusTextarea={@props.focusTextarea} />

