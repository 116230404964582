export default class Search extends React.PureComponent

  constructor: (props) ->
    super(props)

    @handleQueryChange = @handleQueryChange.bind(this)
    @handleClearSearch = @handleClearSearch.bind(this)

  componentDidMount: ->
    @refs.searchInput.focus()

  handleQueryChange: (e) ->
    @props.setFilters(e.target.value)
    e.stopPropagation()

  handleClearSearch: (e) ->
    @props.setFilters('')
    e.stopPropagation()

  render: ->
    <div className="search-menu">
      <i className="fas fa-magnifying-glass"></i>
      {@renderClearIcon()}
      <input ref="searchInput"
             type="search"
             className="form-control"
             placeholder="Search"
             value={@props.query}
             onChange={@handleQueryChange} />
    </div>

  renderClearIcon: ->
    if @props.query != ''
      <i className="fas fa-xmark"
         onClick={@handleClearSearch}
         title="Clear search"
      ></i>
    else
      ''
