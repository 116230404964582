export default class AutoTranslationSettings extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state =
      saving:               false
      languageProjectLinks: @props.languageProjectLinks

    @dSetSavingFalse = _.debounce(@setSavingFalse, 400)

  componentDidMount: ->
    @rebindPopovers()
    @bindPusher()

  rebindPopovers: ->
    $('table .unbound-popover').each( ->
      # Destroy any existing popover
      popoverElement = this
      popover        = Bootstrap.Popover.getInstance(popoverElement)

      popover.dispose() if popover

      # Rebind all popovers (timeout for https://github.com/twbs/bootstrap/issues/37474#issuecomment-1658773983)
      setTimeout( =>
        new Bootstrap.Popover(popoverElement)
      , 200)
    )

  bindPusher: ->
    testEnvSuffix = if @props.environment == 'test' then "-test#{@props.testEnvNumber}" else ''
    channel       = Pusher.instance.subscribe("private-project-#{@props.projectId}#{testEnvSuffix}-auto-translate")

    channel.bind('project-reload', =>
      @reloadLanguageProjectLinksFromBackend()
    )

  clickOnTranslateNow: (link, event) ->
    event.preventDefault()

    if confirm("Are you sure to translate #{link.untranslatedSegmentsCount} segments into #{link.regionName} now?")
      http.post("#{link.autoTranslationPath}.json", {}, (response) =>
        @setState({ languageProjectLinks: response.languageProjectLinks })
      )

  toggleLinkAutoPerfectMatch: (link) ->
    @updateLinkParams(link, autoPerfectMatch: !link.autoPerfectMatch)

  toggleLinkAutoTranslate: (link) ->
    @updateLinkParams(link, autoTranslate: !link.autoTranslate)

  updateLinkService: (link, event) ->
    @updateLinkParams(link, service: event.target.value)

  updateLinkTone: (link, event) ->
    @updateLinkParams(link, tone: event.target.value)

  updateLinkParams: (link, newParams) ->
    # Update visually then on backend (then again visually with the backend response!)
    @stateUpdateLanguageProjectLink(link, newParams, =>
      @backendUpdateLanguageProjectLink(link, newParams)
      @rebindPopovers()
    )

  stateUpdateLanguageProjectLink: (link, newParams, callback) ->
    index = _.findIndex(@state.languageProjectLinks, (languageProjectLink) => languageProjectLink.id == link.id)

    if index != undefined
      newLanguageProjectLink  = Object.assign({}, @state.languageProjectLinks[index], newParams)
      newLanguageProjectLinks = update(@state.languageProjectLinks, { [index]: { $set: newLanguageProjectLink } })
      @setState(languageProjectLinks: newLanguageProjectLinks, callback)
    else
      callback()

  backendUpdateLanguageProjectLink: (link, newParams) ->
    @setState(saving: true)

    http.put("#{link.path}.json", { languageProjectLink: newParams }, (response) =>
      @setState({ languageProjectLinks: response.languageProjectLinks }, @dSetSavingFalse) # wait a bit longer to remove spinner
    )

  reloadLanguageProjectLinksFromBackend: ->
    http.get("#{@props.languageProjectLinksPath}", {}, (response) =>
      @setState({ languageProjectLinks: response }, @rebindPopovers)
    )

  setSavingFalse: ->
    @setState(saving: false)

  servicesText: (link) ->
    services = []
    services.push("<strong>100% Match</strong>")       if link.autoPerfectMatch
    services.push("<strong>Google Translate</strong>") if link.autoTranslate && link.service == 'google_translate'
    services.push("<strong>DeepL</strong>")            if link.autoTranslate && link.service == 'deepl'
    services.push("<strong>OpenAI</strong>")           if link.autoTranslate && link.service == 'open_ai'

    services.join(' and ')

  hasAutoTranslationAvailable: (link) ->
    link.googleTranslatable || link.deeplTranslatable || link.openAiTranslatable

  render: ->
    submitButtonClasses = 'btn'
    submitButtonClasses += ' btn-primary' if !@state.saving
    submitButtonClasses += ' btn-warning' if @state.saving

    <div className="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
      <div className="panel">
        <div className="panel-header">
          <h1>Auto-Translation</h1>
        </div>

        <div className="panel-body">
          { @renderExplanation() }
          { @renderWarning() }
          { @renderTable() }
          { @renderTagNote() }
        </div>

        <div className="panel-footer">
          <a className="btn btn-cancel" href={@props.settingsPath}>Back to Settings</a>
          <a className={submitButtonClasses} type="submit" name="commit">
            { @renderSavingButton() }
          </a>
        </div>
      </div>
    </div>

  renderExplanation: ->
    <div className="explanation">
      <p>
        Automatically translate new segments that have been added during a synchronization.
      </p>

      <ul>
        <li>
          <strong>100% Match</strong> will perform a lookup in your Translation Memory to find a translated segment with the exact same source.
          If found, its translation will be used.
        </li>
        <li>
          <strong>Auto-Translate</strong> will request a translation
          from <a target="_blank" href="https://translate.google.com">Google Translate</a>,
          {' '}
          <a target="_blank" href="https://deepl.com">DeepL</a>
          {' '}or{' '}
          <a target="_blank" href="https://openai.com">OpenAI</a>.
          It will not be perfect but it will certainly be good enough until someone proofread it.
        </li>
      </ul>
    </div>

  renderTable: ->
    <table className="table align-middle">
      <thead>
        <tr>
          <th className="a">
            Target Language
          </th>
          <th className="b">
            100% Match
          </th>
          <th className="c">
            Auto-Translate
          </th>
          <th className="d">
            Translation Service&nbsp;
            <span className="unbound-popover"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  data-bs-html="true"
                  data-bs-content={"This translation service will also be used to provide suggestions during the translation process."}>
              <i className="fas fa-circle-info"></i>
            </span>
          </th>
          <th className="e">
            Actions
          </th>
        </tr>
      </thead>

      <tbody>
        { @renderLanguageProjectLinks() }
      </tbody>
    </table>

  renderWarning: ->
    if !@props.canBeAutoTranslated
      <div className="alert alert-danger alert-billing" role="alert">
        <strong>Warning:</strong>
        Please <a href="/settings/billing">update your billing information</a> to
        continue using our auto-translation services.
      </div>

  renderTagNote: ->
    if @props.canBeAutoTranslated
      <div className="alert alert-warning" role="alert">
        <strong>Note:</strong>

        <em className="tag">{@props.perfectMatchTagName}</em> or <em className="tag">{@props.autoTranslatedTagName}</em> tags
        will be added to the corresponding segments for your translators to be able to proofread and validate them.
      </div>

  renderLanguageProjectLinks: ->
    @state.languageProjectLinks.map((link) =>
      dClass = 'd'
      dClass += ' d-with-select' if @hasAutoTranslationAvailable(link)

      <tr className={"language-#{link.code}"} key={link.id}>
        <td className="a">
          { link.regionName } • { link.code }
        </td>
        <td className="b">
          { @renderPerfectMatchCol(link) }
        </td>
        <td className="c">
          { @renderAutoTranslateCol(link) }
        </td>
        <td className={dClass}>
          { @renderTranslationServiceCol(link) }
        </td>
        <td className="e">
          { @renderAutoTranslateButtonCol(link) }
        </td>
      </tr>
    )

  renderPerfectMatchCol: (link) ->
    @renderPerfectMatchCheckbox(link)

  renderAutoTranslateCol: (link) ->
    if @hasAutoTranslationAvailable(link)
      @renderAutoTranslateCheckbox(link)
    else
      <span>
        <em style={color: '#999999'}>Not Available</em>
      </span>

  renderTranslationServiceCol: (link) ->
    if @hasAutoTranslationAvailable(link)
      selectClasses  = 'form-select service-selector'
      selectClasses += ' disabled' if !link.autoTranslate

      openAiWarningClasses = 'fas fa-warning unbound-popover'
      openAiWarningClasses += ' d-none' if link.service != 'open_ai'

      <>
        <select className={selectClasses}
                id={"select-service-#{link.code}"}
                name={"select-service-#{link.code}"}
                value={link.service}
                onChange={@updateLinkService.bind(this, link)}>
          { @renderServiceOptions(link) }
        </select>

        { @renderToneSelector(link) }

        <i className={openAiWarningClasses}
           data-bs-toggle="popover"
           data-bs-container="body"
           data-bs-trigger="hover"
           data-bs-placement="right"
           data-bs-html="true"
           data-bs-content="<p><strong>OpenAI (ChatGPT) as a Translation Service is still experimental.</strong></p>While most of the suggested translations are quite good, it's currently slower than other services. It may also return unpredictable results."
        />
      </>
    else
      <em style={color: '#999999'}>None</em>

  renderServiceOptions: (link) ->
    # Only keep options for current language
    LanguageOptions = @props.serviceOptions.filter((option) =>
      return true if option[1] == 'google_translate' && link.googleTranslatable
      return true if option[1] == 'deepl'            && link.deeplTranslatable
      return true if option[1] == 'open_ai'          && link.openAiTranslatable
      return false
    )

    _.map(LanguageOptions, (option, index) =>
      <option key={index} value={option[1]}>
        {option[0]}
      </option>
    )

  renderToneSelector: (link) ->
    if link.serviceSupportsTone
      selectClasses  = 'form-select tone-selector'
      selectClasses += ' disabled' if !link.autoTranslate

      popoverContent = "DeepL allows you to choose the level of <strong>formality</strong> (or politeness) of its translations for this language."

      toneOptions = _.map(@props.toneOptions, (option, index) =>
        <option key={index} value={option[1]}>
          {option[0]}
        </option>
      )

      <div className="tone-selector-wrapper">
        <i className="fas fa-gauge unbound-popover"
           data-bs-toggle="popover"
           data-bs-container="body"
           data-bs-trigger="hover"
           data-bs-placement="left"
           data-bs-html="true"
           data-bs-content={popoverContent} />
        <select className={selectClasses}
                id={"select-tone-#{link.code}"}
                name={"select-tone-#{link.code}"}
                value={link.tone}
                onChange={@updateLinkTone.bind(this, link)}>
          {toneOptions}
        </select>
      </div>

  renderAutoTranslateButtonCol: (link) ->
    if link.untranslatedSegmentsCount == 0
      <a className="btn btn-default btn-sm disabled disabled-green">
        Already translated
      </a>
    else if link.autoTranslationInProgress
      <a className="btn btn-default btn-sm disabled">
        In progress&nbsp;
        <i className="fas fa-spinner fa-spin"></i>
      </a>
    else if !@props.canBeAutoTranslated
      <a className="btn btn-default btn-sm disabled">
        No actions available
      </a>
    else
      if link.autoTranslate || link.autoPerfectMatch
        popoverText = "Translate <span class='badge'>#{link.untranslatedSegmentsCount}</span> untranslated segments using #{@servicesText(link)}."
        classes     = "btn btn-primary btn-sm unbound-popover"
        styles      = {}
        action      = @clickOnTranslateNow.bind(this, link)
      else
        popoverText = "Select a service to translate <span class='badge'>#{link.untranslatedSegmentsCount}</span> untranslated segments."
        classes     = "btn btn-primary btn-sm unbound-popover disabled"
        styles      = { pointerEvents: 'auto' } # to be able to show popover even with disabled button
        action      = (e) -> e.preventDefault();

      <a className={classes}
         style={styles}
         onClick={action}
         href="#"
         data-bs-toggle="popover"
         data-bs-container="body"
         data-bs-trigger="hover"
         data-bs-placement="right"
         data-bs-html="true"
         data-bs-content={popoverText}>
        Translate now
      </a>

  renderPerfectMatchCheckbox: (link) ->
    <input className="form-check-input"
           type="checkbox"
           name={"checkbox-perfect-match-#{link.code}"}
           checked={link.autoPerfectMatch}
           onChange={@toggleLinkAutoPerfectMatch.bind(this, link)} />

  renderAutoTranslateCheckbox: (link) ->
    <input className="form-check-input"
           type="checkbox"
           name={"checkbox-auto-translate-#{link.code}"}
           checked={link.autoTranslate}
           onChange={@toggleLinkAutoTranslate.bind(this, link)} />

  renderSavingButton: ->
    if @state.saving
      <span>
        Saving
        <i className="fas fa-spinner fa-spin fa-lg"></i>
      </span>
    else
      <span>
        Saved
      </span>
