export default class Bar extends React.PureComponent

  floorProgressionText: ->
    "#{Math.floor(@props.progression)}%"

  progressionText: ->
    "#{@props.progression}%"

  progressionSpan: ->
    if @props.progression >= 33 || @props.progression == 0
      progressionSpan = <span>{@floorProgressionText()}</span>

  progressBarStyle: ->
    {
      width: "#{@props.progression}%"
    }

  render: ->
    divClasses = "progress-bar"
    divClasses += " empty" if @props.progression == 0

    <div className={divClasses}
         role="progressbar"
         aria-valuenow={@props.progression}
         aria-valuemin="0"
         aria-valuemax="100"
         style={@progressBarStyle()}
         title={@progressionText()}>

      {@progressionSpan()}
    </div>
