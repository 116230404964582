export default class BlankSlate extends React.PureComponent

  render: ->
    if @props.query != ''
      noProjectsSpan = <span>No projects matching <em className="searched">{@props.query}</em>.</span>
    else
      noProjectsSpan = <span>No projects yet.</span>

    <div className="empty-projects list-group">
      {noProjectsSpan}
    </div>

