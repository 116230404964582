import Bar from './bars/Bar'

export default class Bars extends React.PureComponent

  constructor: (props) ->
    super(props)

    @state = {
      expanded: false
    }

    @toggleExpanded = @toggleExpanded.bind(this)

  toggleExpanded: ->
    @setState(expanded: !@state.expanded)

  render: ->
    <div className="bars">
      { @renderWithSingleLanguage()   }
      { @renderWithMultipleLanguage() }
    </div>

  renderWithSingleLanguage: ->
    if @props.project.targetLanguages.length == 1
      targetLanguage = @props.project.targetLanguages[0]

      <div>
        <a className="prefix"
           href={targetLanguage.path}
           title={targetLanguage.regionName}>
          {targetLanguage.code}
        </a>

        <a className="progress" href={targetLanguage.path}>
          <Bar progression={@props.project.progression} />
        </a>
      </div>

  renderWithMultipleLanguage: ->
    if @props.project.targetLanguages.length > 1
      if @state.expanded
        caret = <i className="fas fa-caret-down"></i>
      else
        caret = <i className="fas fa-caret-right"></i>

      <div>
        <div className="prefix language-count" onClick={@toggleExpanded}>
          {@props.project.targetLanguages.length }&nbsp;lan.{caret}
        </div>

        <div className="progress" onClick={@toggleExpanded}>
          <Bar progression={@props.project.progression} />
        </div>

        {@renderLanguageDetails()}
      </div>

  renderLanguageDetails: ->
    if @state.expanded
      lines = _.map(@props.project.targetLanguages, (language) =>
        <div className="expanded-progress-line" key={language.code}>
          <div className="prefix prefix-list">
            <a href={language.path} title={language.regionName}>{language.code}</a>
          </div>

          <a href={language.path} className="progress">
            <Bar progression={language.progression} />
          </a>
        </div>
      )

      <div className="expanded-progress">
        {lines}
      </div>
