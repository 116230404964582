import Tags from './segment/Tags'

export default class Segment extends React.PureComponent

  constructor: (props) ->
    super(props)

    @selectSegment      = @selectSegment.bind(this)
    @toggleSegmentCheck = @toggleSegmentCheck.bind(this)

  selectSegment: (e) ->
    # cmd-click on MacOS and ctrl-click on Win/Linux are the default behaviors
    ctrlModifier = if _.includes(navigator.platform, 'Mac') then 'meta' else 'ctrl'

    if e["#{ctrlModifier}Key"]
      @toggleSegmentCheck(e) # Toggle using CTRL-click
    else if e.shiftKey
      @props.addRangeToSelectedSegments(@props.index) # Manage ranges using SHIFT-click
    else
      @props.replaceSelectedSegments([@props.segment.id]) # Simple click
      @makeThisSegmentActive()

  toggleSegmentCheck: (event) ->
    event.stopPropagation()

    if @props.selected
      @props.removeFromSelectedSegments(@props.segment.id) # This method deals with unselection of active segment
    else                                                   # (it will active last segment from remaining selection!)
      @props.addToSelectedSegments(@props.segment.id)

      if !@props.isAdjacentToActiveSegmentSelection(@props.segment.id) # If added segment is just next to current selection range, consider it...
        @makeThisSegmentActive()                                       # ...like a range extension and don't update the active segment

  makeThisSegmentActive: ->
    @props.setURLWithSameFilters(@props.segment)
    @props.focusTextarea()

  formattedSource: ->
    highlight.highlight(@source(), @props.query, @tagTypes())

  formattedTarget: ->
    highlight.highlight(@target(), @props.query, @tagTypes())

  formattedContext: ->
    highlight.highlight(@props.segment.msgctxt, @props.query, [])

  source: ->
    segment = @props.segment

    if @props.pluralType == 'gettext_plural'
      return "#{segment.msgid} ≺ #{segment.msgidPlural}"
    else if @props.pluralType == 'icu_plural'
      @props.icuSegment.completeSource()
    else # singular
      return segment.msgid

  target: ->
    segment = @props.segment

    if @props.pluralType == 'gettext_plural'
      pluralParts = @relevantPluralParts(segment)

      if _.trim(_.join(pluralParts, '')) == ''
        return ''
      else
        return _.join(pluralParts, ' ≺ ')
    else if @props.pluralType == 'icu_plural'
      @props.icuSegment.completeTarget()
    else # singular
      return segment.msgstr

  # Same code in Backend: Segment.rb
  hasWarning: ->
    segment = @props.segment

    if @props.pluralType == 'gettext_plural'
      pluralParts = @relevantPluralParts(segment)

      # Trigger warning if one of the plural is translated with missing tag (ignore untranslated plurals for warning)
      return _.some(pluralParts, (pluralPart, index) =>
        target = _.trim(pluralPart)
        source = if index == 0 then segment.msgid else segment.msgidPlural
        target.length && highlight.hasWarning(source, target, @tagTypes())
      )
    else if @props.pluralType == 'icu_plural'
      # Trigger warning if one of the plural is translated with missing tag (ignore untranslated plurals for warning)
      return _.some(@props.icuSegment.targetPluralCases(), (targetPluralCase) =>
        sourcePluralCase = @props.icuSegment.referenceSourcePluralCase(targetPluralCase) # corresponding source plural case
        source =        @props.icuSegment.sourcePlural(sourcePluralCase)
        target = _.trim(@props.icuSegment.targetPlural(targetPluralCase))
        target.length && highlight.hasWarning(source, target, @tagTypes())
      )
    else # singular
      target = _.trim(segment.msgstr)

      return target.length && highlight.hasWarning(segment.msgid, target, @tagTypes())

    return false

  relevantPluralParts: (segment) ->
    _.take([
      segment.msgstrPlural1,
      segment.msgstrPlural2,
      segment.msgstrPlural3,
      segment.msgstrPlural4,
      segment.msgstrPlural5,
      segment.msgstrPlural6
    ], @props.nPlurals)

  tagTypes: ->
    if @props.ignoreStackHighlights
      ['html'] # no stack highlights if unmanaged ICU syntax (select or selectordinal)
    else
      [@props.stack, 'html']

  render: ->
    divId    = "segment_#{@props.segment.id}"
    classes  = "segment"
    classes += ' last'     if @props.last
    classes += ' active'   if @props.active
    classes += ' selected' if @props.selected && @props.selectedSegmentsCount > 1 # Only display as selected if part of many selected segments
    classes += ' warning'  if @hasWarning()

    checkboxClasses = if @props.selected then 'far fa-square-check' else 'far fa-square'

    <div className={classes} id={divId} onClick={@selectSegment}>
      <div className="check" onClick={@toggleSegmentCheck} style={{ display: 'none' }}>
        <i className={checkboxClasses}></i>
      </div>
      <span className="source">
        <span className="inner-source highlighted"
              dangerouslySetInnerHTML={{__html: @formattedSource()}}>
        </span>
      </span>
      <span className="target">
        <span className="inner-target highlighted"
              dir={if @props.rightToLeft then 'rtl' else undefined}
              dangerouslySetInnerHTML={{__html: @formattedTarget() }}>
        </span>
        {@renderSpinner()}
        {@renderWarning()}
      </span>
      {@renderContext()}
      <Tags tags={@props.tags}
            segment={@props.segment}
            updateSegment={@props.updateSegment}
            setTags={@props.setTags} />
    </div>

  renderContext: ->
    classes =  'context '

    # display context only if it exists and...
    if @props.segment.msgctxt == ''
      classes += 'd-none '
    # ...make it visible only if query is in context (or hover but in css)
    else
      queryInContext = @props.query && @props.segment.msgctxt.toLowerCase().search(_.escapeRegExp(@props.query).toLowerCase()) != -1
      classes += 'visible' if queryInContext #|| @props.active

    <div className={classes} title={@props.segment.msgctxt}>
      <span className="label"
            dangerouslySetInnerHTML={{__html: @formattedContext()}}>
      </span>
    </div>

  renderSpinner: ->
    if @props.segment.saving
      <i className="fas fa-spinner fa-spin" />
    else if @props.dirty
      <i className="far fa-circle" title="This segment is not saved yet." />

  renderWarning: ->
    if @hasWarning()
      <i className="fas fa-warning" title="Some HTML tags or variables are missing, please check the translation." />
