export default class PastSegment extends React.PureComponent

  constructor: (props) ->
    super(props)

    @handleRevert = @handleRevert.bind(this)

  diffTarget: ->
    historyUtils.diffTarget(@props.historyItem, @props.targetLanguage.pluralCases)

  historyRevertText: ->
    "Revert to: \"#{@props.historyItem.msgstr}\""

  handleRevert: (e) ->
    if @props.historyItem.targetPluralType == 'singular'
      @props.updateActiveMsgstrs({
        msgstr: @props.historyItem.msgstr
      }, @props.focusTextarea)
    else if @props.historyItem.targetPluralType == 'gettext_plural'
      # Backend is smart enough to ignore not-relevant plurals (cf. SegmentUpdateService)
      @props.updateActiveMsgstrs({
        msgstrPlural1: @props.historyItem.msgstrPlural1,
        msgstrPlural2: @props.historyItem.msgstrPlural2,
        msgstrPlural3: @props.historyItem.msgstrPlural3,
        msgstrPlural4: @props.historyItem.msgstrPlural4,
        msgstrPlural5: @props.historyItem.msgstrPlural5,
        msgstrPlural6: @props.historyItem.msgstrPlural6
      }, @props.focusTextarea)
    else if @props.historyItem.targetPluralType == 'icu_plural'
      @props.updateActiveMsgstrs({
        msgstr: @props.historyItem.msgstr
      }, @props.focusTextarea)

  render: ->
    <div className="media">
      <div className="float-start">
        <img src={@props.historyItem.imageUrl}
             alt={@props.historyItem.authorName}
             title={@props.historyItem.authorName}
             referrerPolicy="no-referrer" />
      </div>

      <div className="media-body diff">
        <div dangerouslySetInnerHTML={{__html: @diffTarget() }}
             dir={if @props.targetLanguage.rightToLeft then 'rtl' else undefined}>
        </div>

        { @renderYamlExplanation() }
      </div>

      <span className="revert ignore-react-onclickoutside"
            title={@historyRevertText()}
            onClick={@handleRevert}>
        <i className="fas fa-arrow-rotate-left"></i>
      </span>
    </div>

  renderYamlExplanation: ->
    if false && @props.historyItem.bornFromYamlSegment # disabled because we have false-positives (keys that are not YamlSegments) and we don't know why
      <span className="yaml-explanation small">
        <br/>
        Comes from <a href="/blog/pretranslation-of-popular-ruby-gems" target="_blank">pre-translated popular gems</a>
      </span>
