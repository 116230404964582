export default class CostSimulator extends React.PureComponent

  WEB_KEYS_STEP       = 1
  WEB_LANGUAGES_COUNT = 2

  APP_KEYS_STEP       = 4
  APP_LANGUAGES_COUNT = 4

  BIZ_KEYS_STEP       = 5
  BIZ_LANGUAGES_COUNT = 8

  constructor: (props) ->
    super(props)

    @state =
      isFrozenProject: false
      keysStep:        APP_KEYS_STEP
      languagesCount:  APP_LANGUAGES_COUNT

    @keysRangeMapping =
      1: 100
      2: 200
      3: 500
      4: 1000
      5: 2500
      6: 5000

    @toggleFrozenProject  = @toggleFrozenProject.bind(this)
    @updateKeysStep       = @updateKeysStep.bind(this)
    @updateLanguagesCount = @updateLanguagesCount.bind(this)
    @selectWebsite        = @updateValuesFromExample.bind(this, WEB_KEYS_STEP, WEB_LANGUAGES_COUNT)
    @selectApplication    = @updateValuesFromExample.bind(this, APP_KEYS_STEP, APP_LANGUAGES_COUNT)
    @selectBusiness       = @updateValuesFromExample.bind(this, BIZ_KEYS_STEP, BIZ_LANGUAGES_COUNT)

  currentPricePerKey: ->
    if @state.isFrozenProject
      @props.archivedSegmentPrice
    else
      @props.activeSegmentPrice

  computedKeysTotal: ->
    @keysRangeMapping[@state.keysStep] * @state.languagesCount

  computedCostTotal: ->
    @currentPricePerKey() * @computedKeysTotal()

  isUnfrozenTotalInRange: (minimum, maximum) ->
    unfrozenTotal = @props.activeSegmentPrice * @computedKeysTotal()
    (minimum <= unfrozenTotal) && (maximum >= unfrozenTotal)

  toggleFrozenProject: (e) ->
    @setState({ isFrozenProject: e.target.checked })

  updateKeysStep: (e) ->
    @updateKeysStepValue(parseInt(e.target.value))

  updateKeysStepValue: (value) ->
    @setState({ keysStep: value })

  updateLanguagesCount: (e) ->
    @updateLanguagesCountValue(parseInt(e.target.value))

  updateLanguagesCountValue: (value) ->
    @setState({ languagesCount: value })

  updateValuesFromExample: (keysStep, languagesCount) ->
    @setState({
      keysStep:       keysStep,
      languagesCount: languagesCount
    })

  render: ->
    classes = 'row mt-4 gy-lg-0 gy-4 align-items-stretch'
    classes += ' is-frozen' if @state.isFrozenProject

    <div id="simulator-wrapper" className={ classes }>
      <div className="col-12 col-lg-3 d-lg-flex">
        <div className="row gy-4">
          <div className="col-12 col-lg-12 col-md-4">
            { @renderPriceCard() }
          </div>
          <div className="col-12 col-lg-12 col-md-8">
            { @renderUnlimitedCard() }
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-9">
        <div className="card h-100 p-0 d-flex flex-row flex-wrap">
          { @renderForm() }
          { @renderTotalPanel() }
        </div>
      </div>
    </div>

  renderPriceCard: ->
    <div className="card price-card h-100 flex-wrap flex-row flex-md-column align-items-center align-items-md-start">
      <div className="flex-grow-1">
        <h2 className="h3">
          Price per key
        </h2>
        <div className="form-check form-switch frozen-project-wrapper d-flex align-items-center mb-3">
          <input className="form-check-input"
                 type="checkbox"
                 id="frozen"
                 checked={ @state.isFrozenProject }
                 onChange={ @toggleFrozenProject } />
          <label className="form-check-label" htmlFor="frozen">
            frozen project
          </label>
          <i className="fas fa-circle-info basic-tooltip"
             data-bs-toggle="tooltip"
             data-bs-placement="top"
             title="Control your costs by freezing a project when you stop working on it for a long period (see FAQ below)."></i>
        </div>
      </div>
      <p className="flex-grow-1 mb-0">
        <span className="h2 fw-bold price-wrapper">
          { @props.currency }
          <span id="price-per-key">
            { @currentPricePerKey() }
          </span>
        </span>
        <span>/month</span>
      </p>
    </div>

  renderUnlimitedCard: ->
    listItems = ['Projects', 'Languages', 'Teammates', 'DeepL / OpenAI / Google', 'Translation Memory', 'API Calls']

    <div className="card unlimited h-100">
      <h2>
        <i className="fas fa-infinity"></i>
        Unlimited
      </h2>
      <p>
        You're charged only for translated keys.
        The rest is unlimited.
      </p>
      <ul className="list-unstyled d-flex flex-column flex-wrap m-0">
        {
          listItems.map((item) ->
            <li key={item}>
              <i className="fas fa-check"></i>
              { item }
            </li>
          )
        }
      </ul>
    </div>

  renderForm: ->
    <form className="card d-flex justify-content-between">
      <h2 className="h3 mb-0">
        Simulate your cost
      </h2>

      <fieldset name="keys" className="d-none d-md-block mt-4 mb-2">
        <legend>Drag the sliders</legend>

        <label className="h4 mb-1" htmlFor="keys-count-range">
          <output id="keys-count-output">{ @keysRangeMapping[@state.keysStep] }</output>
          <span>keys to translate</span>
        </label>
        <input type="range"
               className="form-range"
               id="keys-count-range"
               min="1"
               max="6"
               step="1"
               value={ @state.keysStep }
               onChange={ @updateKeysStep } />
        <ul className="ticks list-unstyled d-flex flex-row justify-content-between">
          {
            Object.keys(@keysRangeMapping).map((key) =>
              <li onClick={@updateKeysStepValue.bind(this, key)} key={key}>
                {@keysRangeMapping[key]}
              </li>
            )
          }
        </ul>
      </fieldset>

      <fieldset name="languages" className="d-none d-md-block mt-3 mb-2">
        <label className="h4 mb-1" htmlFor="languages-count-range">
          <output id="languages-count-output">{ @state.languagesCount }</output>
          <span>
          { if @state.languagesCount == 1 then "target language" else "target languages" }
          </span>
        </label>

        <input type="range"
               className="form-range"
               id="languages-count-range"
               min="1"
               max="10"
               step="1"
               value={ @state.languagesCount }
               onChange={ @updateLanguagesCount } />
        <ul className="ticks list-unstyled d-flex flex-row justify-content-between">
          {
            [1..10].map((i) =>
              <li onClick={@updateLanguagesCountValue.bind(this, i)} key={i}>
                {i}
              </li>
            )
          }
        </ul>
      </fieldset>

      <fieldset name="examples" className="mt-3">
        <legend className="d-none d-md-block">Or just pick an example</legend>
        <legend className="d-xs-block d-md-none">By selecting an example</legend>
        <div className="row mt-1 g-2">
          <div className="col-12 col-md-4">
            <input type="radio"
                   autoComplete="off"
                   name="example"
                   id="example-1"
                   className="btn-check"
                   onChange={ @selectWebsite }
                   onClick={ @selectWebsite }
                   checked={ @isUnfrozenTotalInRange(0, 19) } />
            <label className={if @isUnfrozenTotalInRange(0, 19) then 'selected' else ''} htmlFor="example-1">
              <p className="h5 fw-bold">
                Website
              </p>
              <p className="mt-2 mb-0 lh-base">
                For showcase sites translated into a couple of languages
                <span className="mobile-description">
                  <strong>Example:</strong> <em>{@keysRangeMapping[WEB_KEYS_STEP]} keys</em> into <em>{WEB_LANGUAGES_COUNT} target languages</em>
                </span>
              </p>
            </label>
          </div>
          <div className="col-12 col-md-4">
            <input type="radio"
                   autoComplete="off"
                   name="example"
                   id="example-2"
                   className="btn-check"
                   onChange={ @selectApplication }
                   onClick={ @selectApplication }
                   checked={ @isUnfrozenTotalInRange(20, 99) } />
            <label className={if @isUnfrozenTotalInRange(20, 99) then 'selected' else ''} htmlFor="example-2">
              <p className="h5 fw-bold">
                Application
              </p>
              <p className="m-0 lh-base">
                For small apps translated into a few languages
                <span className="mobile-description">
                  <strong>Example:</strong> <em>{@keysRangeMapping[APP_KEYS_STEP]} keys</em> into <em>{APP_LANGUAGES_COUNT} target languages</em>
                </span>
              </p>
            </label>
          </div>
          <div className="col-12 col-md-4">
            <input type="radio"
                   autoComplete="off"
                   name="example"
                   id="example-3"
                   className="btn-check"
                   onChange={ @selectBusiness }
                   onClick={ @selectBusiness }
                   checked={ @isUnfrozenTotalInRange(100, 1000) } />
            <label className={if @isUnfrozenTotalInRange(100, 1000) then 'selected' else ''} htmlFor="example-3">
              <p className="h5 fw-bold">
                Business
              </p>
              <p className="m-0 lh-base">
                For team-driven apps translated into
                many languages
                <span className="mobile-description">
                  <strong>Example:</strong> <em>{@keysRangeMapping[BIZ_KEYS_STEP]} keys</em> into <em>{BIZ_LANGUAGES_COUNT} target languages</em>
                </span>
              </p>
            </label>
          </div>
        </div>
      </fieldset>
    </form>

  renderTotalPanel: ->
    <div className="total-panel d-flex flex-column justify-content-center align-items-center p-0 pt-4">
      <p className="h2 fw-bold">Total cost</p>
      <div className="total-wrapper text-center my-3 mx-auto py-0 px-2">
        <p className="total-container">
          <span className="h2 fw-bold">{ @props.currency }</span>
          <span id="computed-total" className="fw-bold">
            { @renderComputedCostTotal() }
          </span>
          <abbr title="per month">/mo</abbr>
        </p>
        <p>billed monthly</p>
      </div>
    </div>

  renderComputedCostTotal: ->
    total = @computedCostTotal()

    if parseInt(total) == total
      total
    else
      parts = total.toFixed(2).toString().split('.')
      <span>
        {parts[0]}<small>.{parts[1]}</small>
      </span>
