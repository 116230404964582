import KeyTree from '../libraries/KeyTree'

import Search         from './translation_interface/Search'
import FacetedSearch  from './translation_interface/FacetedSearch'
import Segments       from './translation_interface/Segments'
import Memory         from './translation_interface/Memory'
import History        from './translation_interface/History'
import Panel          from './translation_interface/Panel'
import SelectionTags  from './translation_interface/SelectionTags'
import SelectionStats from './translation_interface/SelectionStats'
import BlankSlate     from './translation_interface/BlankSlate'

export default class TranslationInterface extends React.PureComponent

  ROW_HEIGHT = 101

  constructor: (props) ->
    super(props)

    @state = {
      booting:          true  # Big loading page on first loading
      searchLoading:    true  # Loading when new search is triggered (spinner on filters). Ends when first batch of segments arrived!
      esScrollLoading:  true  # Loading when more requests are needed to get all segments but first batch is already on screen (ElasticSearch scrolls API)
      segmentsDisabled: false # Prevent the manipulations of segments (during new search, but not during websocket refresh!)
      emptyProject:     false # Project not initialized yet (no segment in any language)
      fullScreen:       false

      segments:       [] # List of saved segments
      updatedMsgstrs: {} # Segments that are currently updated and not saved { id: { msgstr: }, id: ... }

      activeSegmentIndex: 0
      activePluralIndex:  0
      selectedSegmentIds: []
      keysTree:           {}

      segmentsIndexPerId:               {} # Hash table for optimization { id               => index of segment in array }
      segmentsIndexPerSourceIdentifier: {} # Hash table for optimization { sourceIdentifier => index of segment in array }

      tags: []

      filters:
        query:            @props.filters.query
        onlyUntranslated: @props.filters.onlyUntranslated
        onlyTranslated:   @props.filters.onlyTranslated
        onlyValidated:    @props.filters.onlyValidated
        onlyYaml:         @props.filters.onlyYaml
        onlyGettext:      @props.filters.onlyGettext
        onlyWarning:      @props.filters.onlyWarning
        onlyPlural:       @props.filters.onlyPlural
        tagIds:           @props.filters.tagIds
        noTagIds:         @props.filters.noTagIds
        assigneeId:       @props.filters.assigneeId

      showWarningFilter: @props.filters.onlyWarning
    }

    @dReloadSegmentsFromBackend = _.debounce(@reloadSegmentsFromBackend, 1)

    @toggleFullScreen                           = @toggleFullScreen.bind(this)
    @goToPrevSegment                            = @goToPrevSegment.bind(this)
    @goToNextSegment                            = @goToNextSegment.bind(this)
    @setURL                                     = @setURL.bind(this)
    @setURLWithSameFilters                      = @setURLWithSameFilters.bind(this)
    @focusTextarea                              = @focusTextarea.bind(this)
    @updateActiveMsgstrs                        = @updateActiveMsgstrs.bind(this)
    @updateActivePluralIndex                    = @updateActivePluralIndex.bind(this)
    @updateSegment                              = @updateSegment.bind(this)
    @updateSegments                             = @updateSegments.bind(this)
    @replaceSelectedSegments                    = @replaceSelectedSegments.bind(this)
    @selectAllSegments                          = @selectAllSegments.bind(this)
    @unselectAllSegments                        = @unselectAllSegments.bind(this)
    @unselectAllSegmentsIfClickOutsideContainer = @unselectAllSegmentsIfClickOutsideContainer.bind(this)
    @unselectAllSegmentsOrToggleFullScreen      = @unselectAllSegmentsOrToggleFullScreen.bind(this)
    @setTags                                    = @setTags.bind(this)
    @saveSegmentMsgstrs                         = @saveSegmentMsgstrs.bind(this)
    @scrollToIndex                              = @scrollToIndex.bind(this)

  componentDidMount: ->
    window.TranslationInterface = this

    @reloadTags( =>
      @applyUrlQuery( =>
        @selectActiveSegment()
        @scrollToActiveSegment({ initPosition: true })
        @focusTextarea()
      )
    )

    @bindPusher()
    @bindURLHistory()
    @bindUnload()
    @bindShortcuts()

  componentWillUnmount: ->
    @unbindShortcuts()

  # Active segment from the list of segments (only updated by backend, reflects what's in DB)
  activeSegment: ->
    @state.segments[@state.activeSegmentIndex]

  # Active segment with the potential current changes from the translators' panel
  updatedActiveSegment: ->
    activeSegment = @activeSegment()

    # If active segment has current pending changes, merge them
    if activeSegment && @state.updatedMsgstrs[activeSegment.id]
      activeSegment = Object.assign({}, activeSegment, @state.updatedMsgstrs[activeSegment.id])

    activeSegment

  selectActiveSegment: ->
    if @activeSegment()
      @replaceSelectedSegments([@activeSegment().id])

  replaceSelectedSegments: (segmentIds) ->
    @setState(
      selectedSegmentIds: _.sortBy(segmentIds)
    )

  createSegmentsIndexPerSourceIdentifier: (segments) ->
    hashTable = {}

    segments.forEach((segment, index) => hashTable[segment.sourceIdentifier] = index)

    hashTable

  createSegmentsIndexPerId: (segments) ->
    hashTable = {}

    segments.forEach((segment, index) => hashTable[segment.id] = index)

    hashTable

  # scrollOptions may be:
  # * { frontendScroll: true }  => All segments with multiple frontend calls to paginate if more than SegmentsFilteringService::MAX_SIZE results (scrollId as a second parameter for recursive next calls)
  # * { backendScroll:  true }  => All segments at once with backend ElasticSearch scroll pagination
  reloadSegmentsFromBackend: (scrollOptions, callback) ->
    oldFilters = @state.filters
    params     = { ...@state.filters, ...scrollOptions }

    http.get(path.relative('segments'), params, (data) =>
      if oldFilters == @state.filters # Check if result is still consistent with current state
        if params.frontendScroll && params.scrollId
          newSegments = @state.segments.concat(data.segments)
        else
          newSegments = data.segments

        query = @getURLQuery()

        newSegmentsIndexPerSourceIdentifier = @createSegmentsIndexPerSourceIdentifier(newSegments)
        hasSegmentWarnings                  = newSegments.some((segment) => segment.warning)

        newState = # Values to be updated at each step of the scroll
          booting:                          false
          searchLoading:                    false
          segmentsDisabled:                 false
          emptyProject:                     data.emptyProject
          segments:                         newSegments
          keysTree:                         @createKeysTree(newSegments)
          segmentsIndexPerId:               @createSegmentsIndexPerId(newSegments)
          segmentsIndexPerSourceIdentifier: newSegmentsIndexPerSourceIdentifier
          showWarningFilter:                @state.showWarningFilter || hasSegmentWarnings # "||" to never hide the filter if it existed at any point during translation

        if data.scrollId && data.segments.length == @props.elasticsearchMaxSize # Keep scrolling from ElasticSearch and add segments to the state
          @setState(newState, =>
            @reloadSegmentsFromBackend({ frontendScroll: true, scrollId: data.scrollId }, callback)
          )
        else # Segments loading is finished (less segments than MAX_SIZE or last step of scrolling)
          newUpdatedMsgstrs = _.pick(@state.updatedMsgstrs, _.map(newSegments, 'id')) # Keep active changes from segments that still exist

          newActiveSegmentIndex = newSegmentsIndexPerSourceIdentifier[query.segment] || 0 # Segment from URL or first one if the one from URL doesn't exist anymore
          newActiveSegmentId    = newSegments[newActiveSegmentIndex]?.id

          newSelectedSegmentIds = _.sortBy(_.compact(_.concat(@state.selectedSegmentIds, newActiveSegmentId))) # Be sure to add new activeSegment in selection
          newSelectedSegmentIds = _.intersection(newSelectedSegmentIds, _.map(newSegments, 'id'))              # Remove ids that don't exist anymore after reload

          newState = { # Values to be updated after all segments are loaded
            ...newState,
            esScrollLoading:    false
            updatedMsgstrs:     newUpdatedMsgstrs
            activeSegmentIndex: newActiveSegmentIndex
            selectedSegmentIds: newSelectedSegmentIds
          }

          @setState(newState, callback)
    )

  applyUrlQuery: (callback) ->
    urlQuery = @getURLQuery()

    newFilters =
      query:            urlQuery.query || ''
      onlyUntranslated: urlQuery.onlyUntranslated == true
      onlyTranslated:   urlQuery.onlyTranslated   == true
      onlyValidated:    urlQuery.onlyValidated    == true
      onlyYaml:         urlQuery.onlyYaml         == true
      onlyGettext:      urlQuery.onlyGettext      == true
      onlyWarning:      urlQuery.onlyWarning      == true
      onlyPlural:       urlQuery.onlyPlural       == true
      tagIds:           urlQuery['tagIds[]']   || []
      noTagIds:         urlQuery['noTagIds[]'] || []
      assigneeId:       urlQuery.assigneeId

    if !_.isEqual(newFilters, @state.filters) || @state.booting || @state.emptyProject
      @setState({ filters: newFilters, searchLoading: true, esScrollLoading: true, segmentsDisabled: true }, =>
        @dReloadSegmentsFromBackend({ frontendScroll: true }, callback)
      )
    else
      @activeSegmentFromURL(callback, urlQuery)

  reloadTags: (callback) ->
    http.get(path.relative('tags'), {}, (data) =>
      @setState(tags: data, callback)
    )

  hideSegmentKindFilter: ->
    @props.project.segmentKinds.length <= 1

  # bindResize: ->
  #   oldOnResize = window.onresize
  #
  #   window.onresize = ->
  #     oldOnResize() if oldOnResize
  #     # CUSTOM BEHAVIOUR HERE
  #
  #   window.onresize()

  bindUnload: ->
    window.onbeforeunload = =>
      leave = true

      for segmentId, updatedMsgstrs of @state.updatedMsgstrs
        @saveSegmentMsgstrs(segmentId)
        leave = false

      if !leave
        return "Some segments are not saved (are you still online?). Are you sure you want to quit now?"
      else
        return

  focusTextarea: ->
    if !$('textarea.target:visible').is(':focus')
      $('textarea.target:visible').trigger('focus')

      # Move cursor to the end
      element = $('textarea.target:visible')[0]
      element.setSelectionRange(element.value.length, element.value.length) if element

  createKeysTree: (segments) ->
    keys = segments.filter((segment) -> segment.kind == 'yaml')
                   .map((segment) -> segment.msgctxt)

    new KeyTree(keys).generate()

  findSegment: (segmentId) ->
    segmentIndex = @state.segmentsIndexPerId[segmentId]
    @state.segments[segmentIndex]

  updateActivePluralIndex: (index) ->
    @setState({ activePluralIndex: index }, =>
      @focusTextarea()
    )

  # Update @state.updatedMsgstrs, not saved in DB yet!
  updateActiveMsgstrs: (newMsgstrs, callback = undefined) ->
    activeSegment = @activeSegment()

    if activeSegment
      @setState({
        updatedMsgstrs: {
          ...@state.updatedMsgstrs,
          [activeSegment.id]: Object.assign({}, @state.updatedMsgstrs[activeSegment.id], newMsgstrs)
        }
      }, callback)
    else
      callback() if callback

  # Update @state.segments (usually when saved in DB)
  updateSegment: (segmentId, newParams, callback = undefined) ->
    index = @state.segmentsIndexPerId[segmentId]

    if index != undefined
      @updateSegments({ "#{segmentId}": newParams }, callback)

  # Update @state.segments (usually when saved in DB)
  updateSegments: (newParamsPerSegmentId, callback = undefined) ->
    newSegments = @state.segments

    for segmentId, newParams of newParamsPerSegmentId
      index = @state.segmentsIndexPerId[segmentId]

      if index != undefined
        newSegment  = Object.assign({}, newSegments[index], newParams)
        newSegments = update(newSegments, { [index]: { $set: newSegment } })

    @setState(segments: newSegments, callback)

  selectAllSegments: ->
    @setState(
      selectedSegmentIds: _.sortBy(@state.segments.map((segment) -> segment.id))
    )

  unselectAllSegments: ->
    @setState(
      selectedSegmentIds: _.compact([@activeSegment()?.id]) # always keep active segment selected if any!
    )

  unselectAllSegmentsIfClickOutsideContainer: (e) ->
    if e.target.classList.contains('project-container') # only if click on large container (fluid), not inside it
      @unselectAllSegments()

  unselectAllSegmentsOrToggleFullScreen: ->
    if !$('.modal-backdrop').length
      if @state.selectedSegmentIds.length > 1
        @unselectAllSegments()
      else
        @toggleFullScreen()

  setTags: (newTags, callback = undefined) ->
    @setState(tags: newTags, ->
      # Refresh URL if selected tag doesn't exist anymore in the project (or the user will not be able to unselect it)
      projectTagIds = _.map(@state.tags, (tag) -> tag.id)

      if _.difference(@state.filters.tagIds, projectTagIds).length
        @setURLWithSameFilters(undefined) # undefined = current segment

      callback() if callback
    )

  # Save specific msgid and update list
  saveSegmentMsgid: (id, msgid) ->
    http.put(path.relative("segments/#{id}"), { segment: { msgid: msgid } }, =>
      @updateSegment(id, { msgid: msgid })
    )

  # Save segment with current active changes on msgstr(s)
  saveSegmentMsgstrs: (id, successCallback = undefined, failCallback = undefined) ->
    segment        = @findSegment(id)
    updatedMsgstrs = @state.updatedMsgstrs[id]

    if segment && !segment.saving && updatedMsgstrs
      oldMsgstrs = _.pick(segment, _.keys(updatedMsgstrs)) # new updated msgstrs with old ones

      if _.isEqual(oldMsgstrs, updatedMsgstrs) # if nothing is changed, we simply removed it from the current changes
        @setState(updatedMsgstrs: _.omit(@state.updatedMsgstrs, id), successCallback)
      else # of we update it on the backend and then on the state
        @updateSegment(id, { saving: true })

        http.put(path.relative("segments/#{id}"), { segment: updatedMsgstrs }, =>
          # Success (apply current changes to the list and then remove them from updatedMsgstrs)
          @updateSegment(id, Object.assign({}, updatedMsgstrs, { saving: false }), ->
            @setState(updatedMsgstrs: _.omit(@state.updatedMsgstrs, id), successCallback)
          )
        , =>
          # Fail! (Remove saving flag)
          @updateSegment(id, { saving: false }, failCallback)
        )

  setURL: (filters, segment = undefined) ->
    targetSegment = if segment then segment else @activeSegment()

    params                  = {}
    params.segment          = targetSegment.sourceIdentifier if targetSegment
    params.query            = filters.query                  if _.trim(filters.query) != ''
    params.onlyUntranslated = 'true'                         if filters.onlyUntranslated
    params.onlyTranslated   = 'true'                         if filters.onlyTranslated
    params.onlyValidated    = 'true'                         if filters.onlyValidated
    params.onlyYaml         = 'true'                         if filters.onlyYaml
    params.onlyGettext      = 'true'                         if filters.onlyGettext
    params.onlyWarning      = 'true'                         if filters.onlyWarning
    params.onlyPlural       = 'true'                         if filters.onlyPlural
    params['tagIds[]']      = filters.tagIds                 if filters.tagIds.length
    params['noTagIds[]']    = filters.noTagIds               if filters.noTagIds.length
    params.assigneeId       = filters.assigneeId             if filters.assigneeId

    # * jQuery "param" instead of browser URLSearchParams because the former uses a[]=1&a[]=2 and the latter uses a[]=1,2
    # The former is most commonly used everywhere and is parsed automatically by the backend.
    # * "split" for humps v1 compatibility
    query = $.param(
      humps.decamelizeKeys(params)
    )

    history.pushState({}, $('title').text().trim(), "?#{query}")

  setURLWithSameFilters: (segment) ->
    @setURL(@state.filters, segment)
    return

  #############
  # NAVIGATION CODE
  #############

  bindURLHistory: ->
    # Rewrite pushState to create onpushstate event
    pushState = history.pushState
    history.pushState = (data = null, title = null, url = null) =>
      pushState.apply(history, [data, title, url])

      if (typeof history.onpushstate == "function")
        history.onpushstate(data, title, url)

    # Create new history event!
    history.onpushstate = (data, title, url) =>
      @saveSegmentMsgstrs(@activeSegment().id) if @activeSegment()

      @applyUrlQuery( =>
        @scrollToActiveSegment()
      )

    # For back/next button
    window.onpopstate = (event) =>
      @saveSegmentMsgstrs(@activeSegment().id) if @activeSegment()

      @applyUrlQuery( =>
        @selectActiveSegment()
        @scrollToActiveSegment()
      )

  updateLanguagesLinks: ->
    $('.language-to .dropdown-menu a.language').each( ->
      url    = $(this).attr('href').split('?')[0]
      params = document.location.href.split('?')[1]
      href   = if params then "#{url}?#{params}" else "#{url}"
      
      $(this).attr('href', href)
    )

  getURLQuery: ->
    # To keep filters if we change the language
    @updateLanguagesLinks()

    # return object of url params
    query = path.searchParams()

    # Remove tag ids that are not relevant (anymore) with the list of tags
    active_tags = _.filter(@state.tags, (tag) -> tag.active)
    query['tagIds[]'] = _.intersection(query['tagIds[]'], _.map(active_tags, (tag) -> tag.id ))

    query

  activeSegmentFromURL: (callback, urlQuery = undefined) ->
    query = urlQuery || @getURLQuery() # optimization to avoid 2 query when select a new segment

    if query.segment
      newActiveSegmentIndex = @state.segmentsIndexPerSourceIdentifier[query.segment] || 0
    else
      newActiveSegmentIndex = 0

    @setState({
      activeSegmentIndex: newActiveSegmentIndex
      activePluralIndex:  0
    }, callback)

  goToPrevSegment: ->
    if @activeSegment()
      pluralType = @activeSegment().pluralType

      if _.includes(['gettext_plural', 'icu_plural'], pluralType) && @state.activePluralIndex > 0
        @updateActivePluralIndex(@state.activePluralIndex - 1)
      else
        index      = @state.activeSegmentIndex
        newIndex   = if index == 0 then  @state.segments.length - 1 else index - 1
        newSegment = @state.segments[newIndex]

        @replaceSelectedSegments([newSegment.id])

        @setURLWithSameFilters(newSegment)
        @focusTextarea()

    return false

  goToNextSegment: ->
    if @activeSegment()
      activeSegment = @updatedActiveSegment()
      pluralType    = activeSegment.pluralType

      if pluralType == 'icu_plural'
        icuSegment = new ICU(activeSegment.msgid, activeSegment.msgstr, @props.targetLanguage.pluralCases)
        nPlurals   = icuSegment.nPlurals()
      else
        nPlurals = @props.targetLanguage.nPlurals

      if _.includes(['gettext_plural', 'icu_plural'], pluralType) && @state.activePluralIndex < nPlurals - 1
        @updateActivePluralIndex(@state.activePluralIndex + 1)
      else
        index      = @state.activeSegmentIndex
        newIndex   = if index == @state.segments.length - 1 then 0 else index + 1
        newSegment = @state.segments[newIndex]

        @replaceSelectedSegments([newSegment.id])

        @setURLWithSameFilters(newSegment)
        @focusTextarea()

    return false

  scrollToActiveSegment: (options = {}) ->
    if @activeSegment()
      @scrollToIndex(@state.activeSegmentIndex, options)

  scrollToIndex: (index, options = {}) ->
    initPosition = options.initPosition || false

    segmentsDiv    = $('.segments') # ':visible' because other div with same name for search not found
    segmentsHeight = segmentsDiv.height()

    currentScrollTop   = segmentsDiv.scrollTop()
    newTopScrollTop    =  index      * ROW_HEIGHT                  # Position to have the active segment at the top
    newBottomScrollTop = (index + 1) * ROW_HEIGHT - segmentsHeight # Position to have the active segment at the bottom

    if initPosition && currentScrollTop == 0 && newTopScrollTop > segmentsHeight # Initialize position on first loading of page, if active segment is below the initial scroll
      # setTimeout and loop to delegate scroll after first render of Virtuoso (infinite scroll)
      i = 0
      scrollToHalfPreviousSegment = =>
        if $('.segments').length && $('.segment').length
          segmentsDiv.scrollTop(newTopScrollTop - ROW_HEIGHT / 2) # ...scroll to put the segment on a "nice" position (a bit below the top)
        else
          i++
          setTimeout(scrollToHalfPreviousSegment, i) if i < 100
      setTimeout(scrollToHalfPreviousSegment, 1)
    else if newTopScrollTop < currentScrollTop    # If active segment is above the scroll limit
      segmentsDiv.scrollTop(newTopScrollTop)      # ...scroll to have active segment on top of div
    else if newBottomScrollTop > currentScrollTop # If active segment is below the scroll limit
      segmentsDiv.scrollTop(newBottomScrollTop)   # ...scroll to have active segment on bottom of div

  toggleFullScreen: ->
    if !$('.modal-backdrop').length
      if @state.fullScreen
        @setState(fullScreen: false, @scrollToActiveSegment)
      else if @state.selectedSegmentIds.length == 1 # don't go fullscreen if no, or many, selected segments
        @setState(fullScreen: true)

  hideInitErrorModal: ->
    $('#modal-init-error').modal('hide')

  showInitErrorModal: (errors) ->
    errorMessage = errors.map((error) => "<li>#{error}</li>").join('')
    $('#modal-init-error').find('.error-message').html(errorMessage)
    $('#modal-init-error').modal('show')

  focusSearchField: (event) ->
    if !$('.modal-backdrop').length
      event.preventDefault() # prevent navigator to open search menu
      $('input.filter-search').trigger('focus')

  # See for syntax: https://github.com/jeresig/jquery.hotkeys
  bindShortcuts: ->
    ctrlModifier = if _.includes(navigator.platform, 'Mac') then 'meta' else 'ctrl'

    # Escape
    $(document).on(     'keydown', null,              'esc', @unselectAllSegmentsOrToggleFullScreen)
    $('.right-part').on('keydown', 'textarea.target', 'esc', @toggleFullScreen)

    # Next/Prev on complete page
    selectors = 'body, textarea.target, input.filter-search'
    $(document).on('keydown', selectors, "#{ctrlModifier}+up",     @goToPrevSegment)
    $(document).on('keydown', selectors, "#{ctrlModifier}+down",   @goToNextSegment)
    $(document).on('keydown', selectors, "#{ctrlModifier}+return", @goToNextSegment)

    # Go to the search field
    $(document).on('keydown', 'body, input, textarea', "#{ctrlModifier}+f", @focusSearchField)

  # See for syntax: https://github.com/jeresig/jquery.hotkeys
  unbindShortcuts: ->
    # Escape
    $(document).off(     'keydown', @unselectAllSegmentsOrToggleFullScreen)
    $('.right-part').off('keydown', @toggleFullScreen)

    # Next/Prev on complete page
    $(document).off('keydown', @goToPrevSegment)
    $(document).off('keydown', @goToNextSegment)
    $(document).off('keydown', @goToNextSegment)

    # Search/find segments
    $(document).off('keydown', @focusSearchField)

  #############
  # PUSHER CODE
  #############

  bindPusher: ->
    testEnvSuffix = if @props.environment == 'test' then "-test#{@props.testEnvNumber}" else ''

    channel = Pusher.instance.subscribe("presence-project-#{@props.project.id}#{testEnvSuffix}")

    @bindPusherNewTranslation(channel)
    @bindPusherRefresh(channel)
    @bindPusherInitError(channel)

  bindPusherNewTranslation: (channel) ->
    channel.bind('segment-refresh', (newSegment) =>
      newSegment = humps.camelizeKeys(newSegment)
      segment    = @findSegment(newSegment.id)

      if segment
        sortedSegmentTagIds    = _.sortBy(_.clone(segment.tagIds))
        sortedNewSegmentTagIds = _.sortBy(_.clone(newSegment.tagIds))
        hasSegmentUpdatedTags  = !_.isEqual(sortedSegmentTagIds, sortedNewSegmentTagIds)

        if hasSegmentUpdatedTags
          @reloadTags( =>
            @updateSegment(newSegment.id, newSegment)
          )
        else
          @updateSegment(newSegment.id, newSegment)

        if newSegment.warning && !@state.showWarningFilter
          @setState(showWarningFilter: true)
    )

  # Use in console: Project.order(:updated_at => :desc).first.trigger_pusher_reload('sync')
  bindPusherRefresh: (channel) ->
    channel.bind('project-reload', (data) =>
      @hideInitErrorModal()

      @setState({ searchLoading: true }, =>
        @reloadTags( =>
          @reloadSegmentsFromBackend({ backendScroll: true }, =>
            if data.init && @state.segments.length == 0
              @showInitErrorModal(["No sources were sent to Translation.io during the init.<br/><br/>It usually happens when there is a mismatch between the source language in your configuration file and the one used in your project (ex: \"en\" and \"en-US\")."])
          )
        )
      )
    )

  bindPusherInitError: (channel) ->
    channel.bind('project-init-error', (data) =>
      @showInitErrorModal(data.errors)
    )

  render: ->
    <div className="container-fluid project-container" onClick={@unselectAllSegmentsIfClickOutsideContainer}>
     <div className="container">
       {@renderInterface()}
       {@renderBlankSlate()}
       {@renderBooting()}
     </div>
    </div>

  renderInterface: ->
    classNames  = 'row'
    classNames += ' d-none'     if @state.booting || @state.emptyProject
    classNames += ' fullscreen' if @state.fullScreen
    classNames += ' regular'    if !@state.fullScreen

    <div className={classNames}>
      {### @renderFacetedSearch() ###}

      <div className="col left-part">
        {@renderSearch()}
        {@renderSegments()}
      </div>

      {@renderActiveSegmentRightPart()}

      {@renderSelectedSegmentsRightPart()}
    </div>

  # renderFacetedSearch: ->
  #   <FacetedSearch segments={@state.segments}
  #                  filters={@state.filters}
  #                  stack={@props.project.stack}
  #                  tags={@state.tags}
  #                  searchLoading={@state.searchLoading}
  #                  setURL={@setURL} />

  renderSearch: ->
    # If only untranslated filter, adjust the real number because the count
    # is not updated using a backend reload
    # TODO: change mechanism (recompute count after websocket?) because it doesn't
    # update the count if a tag is removed, the same way as it doesn't update the
    # count if a segment is translated (if this code wasn't there).
    if @state.filters.onlyUntranslated
      segmentsCount = _.sumBy(@state.segments, (segment) => if segment.translated then 0 else 1)
    else
      segmentsCount = @state.segments.length

    <Search segmentsCount={segmentsCount}
            selectedSegmentsCount={@state.selectedSegmentIds.length}
            hideSegmentKindFilter={@hideSegmentKindFilter()}
            showWarningFilter={@state.showWarningFilter}
            searchLoading={@state.searchLoading}
            esScrollLoading={@state.esScrollLoading}
            filters={@state.filters}
            targetLanguage={@props.targetLanguage}
            stack={@props.project.stack}
            keysTree={@state.keysTree}
            tags={_.filter(@state.tags, (tag) -> tag.active)}
            setURL={@setURL}
            selectAllSegments={@selectAllSegments}
            unselectAllSegments={@unselectAllSegments} />

  renderSegments: ->
    <Segments segments={@state.segments}
              updatedMsgstrs={@state.updatedMsgstrs}
              segmentsIndexPerId={@state.segmentsIndexPerId}
              selectedSegmentIds={@state.selectedSegmentIds}
              activeSegmentIndex={@state.activeSegmentIndex}
              targetLanguage={@props.targetLanguage}
              stack={@props.project.stack}
              filters={@state.filters}
              tags={@state.tags}
              fullScreen={@state.fullScreen}
              disabled={@state.segmentsDisabled}
              rowHeight={ROW_HEIGHT}
              scrollToIndex={@scrollToIndex}
              selectAllSegments={@selectAllSegments}
              replaceSelectedSegments={@replaceSelectedSegments}
              updateSegment={@updateSegment}
              setURLWithSameFilters={@setURLWithSameFilters}
              setTags={@setTags}
              focusTextarea={@focusTextarea} />

  renderActiveSegmentRightPart: ->
    classNames  = "col right-part right-part-active-segment"
    classNames += ' d-none' if !@activeSegment() || @state.selectedSegmentIds.length > 1

    <div className={classNames}>
      <div className="container">
        <div className="row tools clearfix">
          <div className="col memories">
            {@renderMemory()}
          </div>

          <div className="col others">
            {@renderHistory()}
          </div>
        </div>
      </div>

      {@renderPanel()}
    </div>

  renderSelectedSegmentsRightPart: ->
    if @state.selectedSegmentIds.length > 1
      classNames = "col right-part right-part-selection"

      <div className={classNames}>
        <SelectionStats segments={@state.segments}
                        selectedSegmentIds={@state.selectedSegmentIds}
                        tags={@state.tags} />

        <SelectionTags segments={@state.segments}
                       selectedSegmentIds={@state.selectedSegmentIds}
                       segmentsIndexPerId={@state.segmentsIndexPerId}
                       tags={@state.tags}
                       setTags={@setTags}
                       updateSegments={@updateSegments} />
      </div>

  renderMemory: ->
    # We want the active changes while the translators write them
    activeSegment = @updatedActiveSegment()

    if activeSegment
      <Memory segment={activeSegment}
              pluralIndex={@state.activePluralIndex}
              selectedSegmentCount={@state.selectedSegmentIds.length}
              project={@props.project}
              targetLanguage={@props.targetLanguage}
              updateActiveMsgstrs={@updateActiveMsgstrs}
              focusTextarea={@focusTextarea} />

  renderHistory: ->
    # We don't want the active change to only refresh the history when the one in DB changes
    activeSegment = @activeSegment()

    if activeSegment
      <History ref="history"
               currentUser={@props.currentUser}
               sourceLanguage={@props.sourceLanguage}
               targetLanguage={@props.targetLanguage}
               segment={activeSegment}
               pluralIndex={@state.activePluralIndex}
               updateActiveMsgstrs={@updateActiveMsgstrs}
               focusTextarea={@focusTextarea} />

  renderPanel: ->
    # We want the active changes while the translators write them
    activeSegment = @updatedActiveSegment()

    if activeSegment
      <Panel environment={@props.environment}
             currentUserIsOwner={@props.currentUser.isOwner}
             currentUserIsManager={@props.currentUser.isManager}
             segment={activeSegment}
             segmentIndex={@state.activeSegmentIndex}
             segmentsCount={@state.segments.length}
             activePluralIndex={@state.activePluralIndex}
             sourceLanguage={@props.sourceLanguage}
             targetLanguage={@props.targetLanguage}
             stack={@props.project.stack}
             fullScreen={@state.fullScreen}
             saveSegmentMsgstrs={@saveSegmentMsgstrs}
             updateActiveMsgstrs={@updateActiveMsgstrs}
             updateActivePluralIndex={@updateActivePluralIndex}
             goToNextSegment={@goToNextSegment}
             goToPrevSegment={@goToPrevSegment}
             toggleFullScreen={@toggleFullScreen}
             focusTextarea={@focusTextarea} />

  renderBlankSlate: ->
    if !@state.searchLoading && @state.emptyProject
      <BlankSlate project={@props.project} />

  renderBooting: ->
    classes  = 'row'
    classes += ' d-none' if !@state.booting

    <div className={classes}>
      <div className="loading">
        Loading, please wait...<br/>
        <i className="fas fa-heart"></i>
      </div>
    </div>
