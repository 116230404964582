import Colors from './helpers/Colors'

export default class SelectionTags extends React.PureComponent

  constructor: (props) ->
    super(props)

    @addTagFromInput = @addTagFromInput.bind(this)

  selectedSegments: ->
    _.compact(
      @props.selectedSegmentIds.map((id) =>
        @props.segments[@props.segmentsIndexPerId[id]]
      )
    )

  selectedSegmentsWithTagId: (tagId) ->
    _.filter(@selectedSegments(), (selectedSegment) =>
      _.includes(selectedSegment.tagIds, tagId)
    )

  # returns { :tagId => number of tags in selection }
  tagOccurence: ->
    hash = {}

    # Initialize hash
    @props.tags.forEach((tag) => hash[tag.id] = 0)

    # Fill it
    @selectedSegments().forEach((segment) ->
      segment.tagIds.forEach((tagId) ->
        hash[tagId] += 1
      )
    )

    hash

  toggleTag: (tag, occurence, e) ->
    if occurence == 0
      @addTags(@props.selectedSegmentIds, tag.name)
    else
      # Only remove tag from selectedSegmentIds that have the tag, and ignore the others
      selectedSegmentIdsWithTagId = _.map(@selectedSegmentsWithTagId(tag.id), 'id')
      @removeTags(selectedSegmentIdsWithTagId, tag.name)

    @focusOnInput()

  addTagFromInput: (e) ->
    if e.keyCode == 13 # enter
      @addTags(@props.selectedSegmentIds, e.target.value)
      @refs.newTagInput.value = ''

  addTags: (segmentIds, name) ->
    if _.trim(name) != ''
      params =
        name:       name
        segmentIds: segmentIds

      http.post(path.relative('tags/bulk'), params, (data) =>
        @updateTags(data)
      )

  removeTags: (segmentIds, name) ->
    # Preferably use same value as in Api::V1::TagsBulkBaseService.trigger_after_bulk
    # (confirmation dialog prevent flickering of tags if whole reload on huge projects)
    if segmentIds.length <= 10 || confirm("Are you sure to remove these #{segmentIds.length} \"#{name}\" tags?")
      params =
        name:       name
        segmentIds: segmentIds

      http.delete(path.relative("tags/bulk"), params, (data) =>
        @updateTags(data)
      )

  updateTags: (data) ->
    # Update tags from project...
    @props.setTags(data.projectTags, =>
      # Update segments with new tags.
      @props.updateSegments(data.newParamsPerSegmentId) # { segmentId: newParams, segmentId2: newParams2, ... }
                                                        # where newParams is like { tagIds: [] }
    )

  focusOnInput: (e) ->
    @refs.newTagInput.focus() if @refs.newTagInput

  renderTags: ->
    <ul className="tag-selection">
      { @renderTagItems() }
      <li>
        <input className="form-control"
               type="text"
               placeholder="Create new tag"
               ref="newTagInput"
               onKeyDown={@addTagFromInput} />
      </li>
    </ul>

  renderTagItems: ->
    tagOccurence = @tagOccurence()

    _.map(@props.tags, (tag) =>
      occurence = tagOccurence[tag.id]

      selected = occurence == @props.selectedSegmentIds.length                 # All selected segment have this tag
      partial  = occurence > 0 && occurence < @props.selectedSegmentIds.length # Only some selected segments have this tag

      darkerColor  = Colors.shade(-0.30, tag.color)
      lighterColor = Colors.shade( 0.85, tag.color)

      if selected
        classCheck = 'fas fa-check'
        liStyle = { backgroundColor: lighterColor }
        title = "Remove this tag from the selected segments"
      else if partial
        classCheck = 'fas fa-minus'
        liStyle =
          backgroundImage: "linear-gradient(45deg, #{lighterColor} 25%, #ffffff 25%, #ffffff 50%, #{lighterColor} 50%, #{lighterColor} 75%, #ffffff 75%, #ffffff 100%)"
          backgroundSize:  '8px 8px'
        title = "Remove this tag from #{occurence} selected #{if occurence == 1 then 'segment' else 'segments'}"
      else
        classCheck = 'fas fa-check not-visible'
        liStyle = {}
        title = "Add this tag to the selected segments"

      classOccurence =  'badge occurence'
      classOccurence += ' not-visible' if !partial

      classRemove =  'fas fa-xmark'
      classRemove += ' not-visible' if !selected && !partial

      <li key={tag.id}
          className="tag-item"
          style={liStyle}
          title={title}
          onClick={@toggleTag.bind(this, tag, occurence)}>
        <i className={classCheck} style={{ color: darkerColor }}></i>
        <span className="color"
              style={{ backgroundColor: tag.color }}>
        </span>
        <span className="name"
              style={{ color: if selected || partial then darkerColor else undefined }}>
          { tag.name }
        </span>
        <span className={classOccurence} style={{ backgroundColor: tag.color }}>
          {occurence}
        </span>
        <i className={classRemove} style={{ color: darkerColor }}></i>
        <div style={{ clear: 'both' }}></div>
      </li>
    )

  render: ->
    <div>
      <h4>Add or Remove Tags</h4>

      { @renderTags() }
    </div>

