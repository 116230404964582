import Date from './shared/Date'

export default class PastSegment extends React.PureComponent

  goTo: (pastSegment) ->
    if !pastSegment.segmentWasRemoved
      window.open(pastSegment.path, '_blank');

  warning: (pastSegment) ->
    if pastSegment.segmentWasRemovedNow
      "This segment was removed."
    else if pastSegment.segmentWasRemoved
      "This segment doesn't exist anymore because it has been removed since."

  highlightedSource: (pastSegment) ->
    historyUtils.highlightSource(pastSegment, pastSegment.sourcePluralType, @props.stack)

  diffTarget: (historyItem) ->
    historyUtils.diffTarget(historyItem, @props.targetLanguage.pluralCases)

  render: ->
    <div className="row">
      <div className="col-sm-4 left-side">
        <div className="media">
          <div className="media-left">
            <img src={@props.pastSegment.imageUrl} alt={@props.pastSegment.authorName} referrerPolicy="no-referrer" />
          </div>

          <div className="media-body">
            {@renderSummary()}
            <Date item={@props.pastSegment} />
          </div>
        </div>
      </div>

      <div className="col-sm-8 right-side">
        <table className="table diff">
          <tbody>
            {@renderPastSegments()}
          </tbody>
        </table>
      </div>
    </div>

  renderSummary: ->
    lineCountText = if @props.pastSegment.lineCount == 1 then "line" else "lines"

    <p>
      <strong>{@props.pastSegment.authorName}</strong> updated <strong>{@props.pastSegment.lineCount}&nbsp;{lineCountText}</strong> ({@props.targetLanguage.code}).
    </p>

  renderPastSegments: ->
    dir = if @props.targetLanguage.rightToLeft then 'rtl' else undefined

    _.map(@props.pastSegment.history, (historyItem) =>
      if historyItem.segmentWasRemoved && historyItem.segmentWasRemovedNow
        classes = "segment-was-removed segment-was-removed-now"
      else if historyItem.segmentWasRemoved
        classes = "segment-was-removed"
      else if historyItem.segmentWasRemovedNow
        classes = "segment-was-removed-now"
      else
        classes = "hoverable" # only hoverable if not removed (has active link)

      <tr key={historyItem.id} className={classes} onClick={@goTo.bind(this, historyItem)} title={@warning(historyItem)}>
        <td className="highlighted" dangerouslySetInnerHTML={{ __html: @highlightedSource(historyItem) }}>
        </td>

        <td dir={dir}>
          <span dangerouslySetInnerHTML={{__html: @diffTarget(historyItem) }}>
          </span>

          { @renderYamlExplanation(historyItem) }
        </td>
      </tr>
    )

  renderYamlExplanation: (historyItem) ->
    if false && historyItem.bornFromYamlSegment # disabled because we have false-positives (keys that are not YamlSegments) and we don't know why
      <em className="yaml-explanation" dir="ltr">
        Comes from <a href="/blog/pretranslation-of-popular-ruby-gems" target="_blank">pre-translated popular gems</a>
      </em>
