export default class Users extends React.PureComponent

  render: ->
    <span className="members">
      <a href={@props.project.invitePath}
         className="btn-invite-modal"
         data-bs-toggle="modal"
         data-bs-target="#modal-invite">

        {@renderUserImgs()}
        {@renderDots()}
      </a>
    </span>

  renderUserImgs: ->
    if @props.project.users.length > @props.spots
      n = @props.spots - 1
    else
      n = @props.spots

    _.map(_.take(@props.project.users, n), (user) ->
      <img key={user.id}
           src={user.picture}
           title={user.name}
           alt={user.name}
           referrerPolicy="no-referrer" />
    )

  renderDots: ->
    classes = 'dots'
    classes += ' d-none' if @props.project.users.length <= @props.spots

    <span className={classes}>&hellip;</span>
